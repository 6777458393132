import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import BasicPage from 'components/layout/BasicPage';
import BodyText from 'components/misc/BodyText';

function AboutRkdResearch() {
  // Use correct localized urls.
  let websiteRkdHref = "https://www.rkd.nl/en";
  let excerptRkdHref = "https://www.rkd.nl/en/collection/digital-collection/rkdexcerpts";
  if (i18n.locale === 'nl') {
    websiteRkdHref = "https://www.rkd.nl";
    excerptRkdHref= "https://www.rkd.nl/collectie/digitale-collectie/rkdexcerpts";
  }

  return (
    <>
      <Helmet>
        <title>{t`About RKD Research`}</title>
      </Helmet>
      <BasicPage>
        <BodyText>
          <h1>{t`About RKD Research`}</h1>
          <p>
            <Trans>
              RKD Research is the online research platform of the <a href={websiteRkdHref} target="_blank" rel="noopener noreferrer">RKD – Netherlands Institute for Art History</a> in which an infinite amount of data and sources on works of art and artists are accessible. Researchers from all over the world can start asking and answering complex research questions and visually compare images on the platform.
            </Trans>
          </p>
          <p>{t`The main advantages of RKD Research:`}</p>
          <ul>
            <li>
              <Trans>
                All RKD databases are converted to Linked Open Data and publicly available at <a href="https://rkd.triply.cc/" target="_blank" rel="noopener noreferrer">https://rkd.triply.cc/</a>.
              </Trans>
            </li>
            <li>{t`Visual search allows subscribers to enter an image and search the databases for similar images.`}</li>
            <li>{t`In my workspace, subscribers can view, compare and edit images.`}</li>
          </ul>
          <h2>{t`Databases`}</h2>
          <p>{t`Via RKD Research, you simultaneously search eight online databases containing millions of RKD records, namely: RKDartists, RKDimages, RKDimages Lite, RKDtechnical, RKDlibrary, RKDarchives, RKDcollections and RKDexcerpts. These databases are an important source for art historical research on Dutch art in an international context and are continuously supplemented with new data.`}</p>
          <h2>{t`RKDartists`}</h2>
          <p>{t`In RKDartists, data are maintained on more than 350,000 Dutch and Flemish artists, art dealers, collectors and other art-related figures. Biographical data such as birth and death dates, family relationships and workplaces of artists are extracted from RKDartists.`}</p>
          <h2>{t`RKDimages`}</h2>
          <p>{t`Images of over 250,000 mainly early modern Dutch paintings, drawings, prints and original photographs can be found in RKDimages and include validated information on provenance, exhibitions and literature. By experts from inside and outside the RKD, the attributions of works of art in RKDimages are kept up-to-date.`}</p>
          <h2>{t`RKDimages Lite`}</h2>
          <p>{t`Via RKDimages Lite, the entire image documentation of the RKD is accessible online; over 5 million photographs and reproductions of works of art from 1400 to the present have been digitised for this purpose. The image documentation has been collected from the establishment of the RKD in 1932 and annotated by employees of the RKD.`}</p>
          <h2>{t`RKDtechnical`}</h2>
          <p>{t`RKDtechnical brings together various forms of material-technical research on works of art, such as results of paint sample analyses dendrochronological reports and infrared reflectograms (IRR). Some technical research data are kept physically at the RKD, but often external curators and researchers share their results only digitally.`}</p>
          <h2>{t`RKDlibrary`}</h2>
          <p>{t`The extensive collection of the RKD library covers Western art from the late Middle Ages to the present, with an emphasis on Dutch and Flemish art. More than 500,000 books, from more general literature on art history, lexicons and periodicals to specialist publications, auction catalogues and monographs on Dutch artists, are included in RKDlibrary, where the books can also be requested.`}</p>
          <h2>{t`RKDarchives`}</h2>
          <p>{t`The RKD manages over 2.5 kilometres of archival material in the field of art of the Netherlands. In total, it comprises over 900 archives of artists, artists' associations, art historians and other art-related persons and institutions. RKDarchives contains archival descriptions, inventories and digitised archives.`}</p>
          <h2>{t`RKDcollections`}</h2>
          <p>{t`RKDcollections describes the sub-collections and archives of the RKD. In addition to the archive creator and size of the collection, this database provides information on the collection's accessibility.`}</p>
          <h2>{t`RKDexcerpts`}</h2>
          <p><Trans>RKDexcerpts primarily discloses source material from the archives of the RKD, but also material kept at <a href={excerptRkdHref}>other archival institutions</a>. RKDexcerpts comprises various domains with source material from the Middle Ages up to and including the 20th century.</Trans></p>
        </BodyText>
      </BasicPage>
    </>
  );
}

export default AboutRkdResearch;
