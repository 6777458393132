const zIndex = {
  fullClick: 3,
  aboveFullClick: 4,
  aboveFullClickPlus: 5,
  asideNav: 100,
  snackBar: 101,
  modal: 102,
};

export default zIndex;
