import styled from 'styled-components';
import size from 'styles/base/variables/size';
import { mq, bp } from 'styles/base/variables/mediaQueries';
import SpanGridCols from 'components/layout/SpanGridCols';

const GridContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${({ full }) =>
    full
      ? `
      ${mq(0, bp.medium)} {
        width: calc(100% - ${size.pagePadding} - ${size.pagePadding});
      }
      ${mq(bp.medium)} {
        width: calc(100% - ${size.gridFullPagePadding} - ${
          size.gridFullPagePadding
        });
      }
      `
      : `
      width: calc(100% - ${size.pagePadding} - ${size.pagePadding});
      max-width: ${size.gridMax};
    `}

  .indent--1-col {
    ${mq(bp.extraLarge)} {
      margin-left: calc(${SpanGridCols(1)} + ${size.gridGutter});
      margin-right: calc(${SpanGridCols(1)} + ${size.gridGutter});
    }
  }
`;
export default GridContainer;
