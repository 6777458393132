import { memo, useEffect, useState } from 'react';
import useUserContext from 'hooks/useUserContext';

const TripleIfImage = ({
  baseUrl,
  alt,
  width = 0,
  height = 0,
  rotation = 0,
  quality = 'default',
  format = 'jpg',
}) => {
  const tripleUrl = generateUrlFromBase(
    baseUrl,
    width,
    height,
    rotation,
    quality,
    format
  );

  const user = useUserContext();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Load image as a blob.
  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      isActive && setIsLoading(true);
      isActive && setIsError(false);
      try {
        const response = await fetchResponseBlob(tripleUrl, user.id_token);
        isActive && setData(response);
      } catch (error) {
        isActive && setData(null);
        isActive && setIsError(true);
      }

      isActive && setIsLoading(false);
    };
    // Deliberately ignore the promise. Its setData side-effect is the goal.
    fetchData();
    // Update flag to prevent async actions from settings state on an unmounted
    // component and delayed requests from overwriting later requests.
    return () => {
      isActive = false;
    };
    // Refetch data if the user changes auth status. We do not care about the
    // id_token, as this changes every heartbeat and only the initial fetch needs
    // it to succeed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripleUrl, user.authenticated]);

  return (
    <>
      {/* Show a transparant 1 pixel image if still loading */}
      {(isLoading || isError) && (
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII="
          alt={alt}
        />
      )}
      {/* Replace the image with the loaded blob. The revokeObjectUrl on onLoad is required to prevent memory leaks. */}
      {!isLoading && data && (
        <img
          src={URL.createObjectURL(data)}
          onLoad={() => URL.revokeObjectURL(data)}
          alt={alt}
        />
      )}
    </>
  );
};

export default memo(TripleIfImage);

/**
 * Generates a III url for a 'full' image.
 *
 * Helper.
 *
 * Uses IIIF's sizeByConfinedWh.
 * See https://iiif.io/api/image/3.0/#42-size
 *
 * @param {string} iiifBaseUrl
 *   The base url of the image (without info.json and terminal /)
 * @param {int} width
 *   Requested max width of the image.
 * @param {int} height
 *   Requested max height of the image.
 * @param {int} rotation
 *   Optional; The rotation in degrees, default 0.
 * @param {string} quality
 *   Optional; Quality of the image, default 'default'.
 * @param {string} format
 *   Optional; Requested format of the image, default 'jpg'.
 *
 * @returns {string}
 */
function generateUrlFromBase(
  iiifBaseUrl,
  width = 0,
  height = 0,
  rotation = 0,
  quality = 'default',
  format = 'jpg'
) {
  const size = width && height ? `!${width},${height}` : 'max';
  const region = 'full';
  return `${iiifBaseUrl}/${region}/${size}/${rotation}/${quality}.${format}`;
}

/**
 * Fetches an image blob from a url.
 *
 * Helper.
 *
 * @param {string} uri
 *   Uri to fetch.
 * @param {string} token
 *   An optional id_token identifying the user.
 *
 * @returns {Promise<any>}
 */
async function fetchResponseBlob(uri, token = '') {
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  const result = await fetch(uri, { mode: 'cors', headers });
  return await result.blob();
}
