import styled from 'styled-components';
import Mls from 'components/detail/Mls';

const StyledSubjectWithList = styled.div`
  > ul {
    &:not(:first-child) {
      margin-top: 0;
    }

    > li {
      ::marker {
        content: none;
      }
    }
  }
`;

/**
 * Renders a subject with a list. The list is optionally collapsible.
 */
function SubjectWithList({ label, initiallyVisibleChildren, children }) {
  return (
    <StyledSubjectWithList>
      <p>
        <Mls strings={label} />
      </p>
      {children.length > 0 ? <ul>{children}</ul> : ''}
    </StyledSubjectWithList>
  );
}

export default SubjectWithList;
