import styled from 'styled-components';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import Square from 'components/graphical/Square';
import { IconCheck } from 'components/graphical/Icons';

const StyledCheckboxAsButton = styled.button`
  transition: all ${easing.normal};

  .square {
    width: 2.4rem;

    svg {
      transition: all ${easing.normal};
    }
  }

  &:not(.active) {
    .square {
      svg {
        opacity: 0;
      }
    }
  }

  &.active {
    .square {
      background-color: ${color.yellow};
      border-color: transparent;
    }
  }
`;

function CheckboxAsButton({ buttonOnClick, buttonClassName, children }) {
  return (
    <StyledCheckboxAsButton onClick={buttonOnClick} className={buttonClassName}>
      <Square className="square">
        <IconCheck />
      </Square>
      {children}
    </StyledCheckboxAsButton>
  );
}

export default CheckboxAsButton;
