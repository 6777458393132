const flags = {
  ALLOW_FULL_IMAGE: 0,
  DISALLOW_IMAGE: -1,
};

const permissionMatrix = {
  CRLARGE: {
    ROLE_ANONYMOUS: 1200,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: 1200,
    ROLE_SUBSCRIBER: 1200,
  },
  CRTHUMB: {
    ROLE_ANONYMOUS: 200,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: 200,
    ROLE_SUBSCRIBER: 200,
  },
  NOLIM: {
    ROLE_ANONYMOUS: flags.ALLOW_FULL_IMAGE,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: flags.ALLOW_FULL_IMAGE,
    ROLE_SUBSCRIBER: flags.ALLOW_FULL_IMAGE,
  },
  NOPUB: {
    ROLE_ANONYMOUS: flags.DISALLOW_IMAGE,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: flags.DISALLOW_IMAGE,
    ROLE_SUBSCRIBER: flags.DISALLOW_IMAGE,
  },
  RKDONLY: {
    ROLE_ANONYMOUS: flags.DISALLOW_IMAGE,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: flags.DISALLOW_IMAGE,
    ROLE_SUBSCRIBER: flags.DISALLOW_IMAGE,
  },
  SUBONLY: {
    ROLE_ANONYMOUS: 200,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: 200,
    ROLE_SUBSCRIBER: flags.ALLOW_FULL_IMAGE,
  },
  UNKNOWN: {
    ROLE_ANONYMOUS: flags.DISALLOW_IMAGE,
    ROLE_EMPLOYEE: flags.ALLOW_FULL_IMAGE,
    ROLE_ON_PREMISE: flags.ALLOW_FULL_IMAGE,
    ROLE_REGISTERED_USER: flags.DISALLOW_IMAGE,
    ROLE_SUBSCRIBER: flags.DISALLOW_IMAGE,
  },
};

/**
 * Get the allowed asset pixels to display. In this way it can be determined
 * if the users is allowed to see the asset.
 * See: https://redmine.limoengroen.nl/issues/64005
 *
 * @param user
 *   The user context.
 * @param asset
 *   The asset containing accessStatus.
 * @returns {number}
 *  0: Allow asset to fully display.
 *  -1: Disallow asset to display.
 *  >0: Limited asset display.
 */
function getAllowedPixelsToDisplay(user, asset) {
  let roles = ['ROLE_ANONYMOUS'];
  if (user.roles.length > 0) {
    roles = user.roles;
  }

  let highestValue = flags.DISALLOW_IMAGE;
  for (const role of roles) {
    if (
      !permissionMatrix.hasOwnProperty(asset.accessStatus) ||
      !permissionMatrix[asset.accessStatus].hasOwnProperty(role)
    ) {
      return flags.DISALLOW_IMAGE;
    }
    const currentValue = permissionMatrix[asset.accessStatus][role];
    if (currentValue === flags.ALLOW_FULL_IMAGE) {
      return flags.ALLOW_FULL_IMAGE;
    }

    if (currentValue !== undefined && currentValue > highestValue) {
      highestValue = currentValue;
    }
  }

  return highestValue;
}

export { getAllowedPixelsToDisplay, flags };
