import { stringify } from 'qs';

export default async function request(options) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  const backendBaseUrl = '/api';
  const searchEndpoint = '/search';
  const query = options.parameters
    ? stringify(options.parameters, { addQueryPrefix: true })
    : '';

  const response = await fetch(`${backendBaseUrl}${searchEndpoint}${query}`, {
    method: 'GET',
    headers,
  });

  let json;
  try {
    json = await response.json();
  } catch (error) {
    // Nothing to do here, certain responses won't have json
  }

  if (response.status >= 200 && response.status < 300) {
    return json;
  } else {
    const message = json && json.error ? json.error : response.status;
    throw new Error(message);
  }
}
