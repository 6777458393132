import styled from 'styled-components';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { t } from '@lingui/macro';
import {
  IconInfo,
  // IconSearchExtensive,
  IconSearchVisual,
} from 'components/graphical/Icons';
import size from 'styles/base/variables/size';
import color from 'styles/base/variables/color';
import { mq, bp } from 'styles/base/variables/mediaQueries';
import useUserContext from 'hooks/useUserContext';

const StyledSearchActions = styled.div`
  margin-top: 1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4em;
    justify-content: flex-end;

    > li {
      &:last-child {
        .btn {
          ${mq(bp.mobileMenu)} {
            margin-right: -${size.buttonPaddingInline};
          }
        }
      }
    }

  .btn-explanation {
    .icon {
      border: 0.2rem solid ${color.anthracite};
      width: 2rem;
      aspect-ratio: 1/1;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

function SearchActions() {
  const user = useUserContext();

  return (
    user.authenticated !== null && (
      <StyledSearchActions>
        <nav>
          <ul>
            <li>
              <LocalizedLink
                className="btn btn-explanation"
                to="/manual-rkd-research"
              >
                <span className="text">{t`Manual RKD Research`}</span>
                <IconInfo />
              </LocalizedLink>
            </li>
            <li>
              <LocalizedLink
                className="btn btn-explanation"
                to="/search-manual"
              >
                <span className="text">{t`Search manual`}</span>
                <IconInfo />
              </LocalizedLink>
            </li>
            {/*
            <li>
              <LocalizedLink className="btn" to="/search">
                <span className="text">{t`Advanced search`}</span>
                <IconSearchExtensive />
              </LocalizedLink>
            </li>
            */}
            <li>
              <LocalizedLink className="btn" to="/visual-search">
                <span className="text">{t`Visual search`}</span>
                <IconSearchVisual />
              </LocalizedLink>
            </li>
          </ul>
        </nav>
      </StyledSearchActions>
    )
  );
}

export default SearchActions;
