// Splits string to object with value and unit.
const splitValueAndUnit = function (value) {
  if (value) {
    var parts = value.replace(/([a-z]+)/i, ' $1 ').split(/[^-.0-9a-z]+/gi);
    return {
      value: Number(parts[0]),
      unit: parts[1],
    };
  }
};

export default splitValueAndUnit;
