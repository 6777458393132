import splitValueAndUnit from 'styles/base/helpers/splitValueAndUnit';

const bpAsNumber = {
  small: 480,
  medium: 768,
  mobileMenu: 890,
  large: 980,
  extraLarge: 1280,
  gridMax: 1600,
  gridMaxAndPadding: 1800,
};

const bp = {
  small: `${bpAsNumber.small}px`,
  medium: `${bpAsNumber.medium}px`,
  large: `${bpAsNumber.large}px`,
  extraLarge: `${bpAsNumber.extraLarge}px`,
  gridMax: `${bpAsNumber.gridMax}px`,
  gridMaxAndPadding: `${bpAsNumber.gridMaxAndPadding}px`,
  mobileMenu: `${bpAsNumber.mobileMenu}px`,
};

const mq = function (min, max) {
  if (max) {
    const maxSplitted = splitValueAndUnit(max);
    return `@media screen and (min-width: ${min}) and (max-width: ${
      maxSplitted.value - 1
    }${maxSplitted.unit})`;
  } else {
    return `@media screen and (min-width: ${min})`;
  }
};

export { bp, bpAsNumber, mq };
