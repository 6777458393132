import { useContext } from 'react';
import { UserContext } from 'context/UserContext';

/**
 * Gets the user object.
 *
 * This hooks aids in DX by throwing an exception when the context cannot be used.
 *
 * @return
 *   The user context.
 */
const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      'useUserContext was used outside of the UserContextProvider.'
    );
  }

  return context;
};

export default useUserContext;
