import { Plural, Trans } from '@lingui/macro';
import styled from 'styled-components';
import respValue from 'styles/base/helpers/respValue';
import color from 'styles/base/variables/color';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import { LogoRkdShape } from 'components/graphical/LogoRKD';
import useUserContext from 'hooks/useUserContext';
import useAnalyticsContext from 'hooks/useAnalyticsContext';
import SubscriptionRequired from 'components/misc/SubscriptionRequired';
import BasicPage from 'components/layout/BasicPage';
import { IconAlert } from 'components/graphical/Icons';

const StyledResultsHeaderVisualSearch = styled.div`
  background-color: ${color.paper};
  ${respValue('padding', '3rem')};

  // To position rkd-shape.
  position: relative;
  overflow: hidden;

  .logo-rkd--shape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${color.sand};

    svg {
      width: 18.7rem;
      height: auto;
    }
  }

  .header--main {
    // To position above rkd-shape.
    position: relative;
    z-index: 1;

    ${mq(bp.small)} {
      display: flex;
      align-items: center;
      ${respValue('gap', '3rem')};
    }

    .visual {
      max-width: 18rem;
    }

    .textual {
      max-width: 100rem;

      ${mq(0, bp.small)} {
        margin-top: 1.5rem;
      }

      > * {
        + * {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .visual-search-file-upload {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
  }
`;

const StyledFileError = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: baseline;
  color: ${color.torchRed};

  .icon {
    position: relative;
    top: 0.2em;
  }
`;

function ResultsHeaderVisualSearch({
  visualSearchResults,
  onFileChange,
  onFormSubmit,
  fileDataUrl,
  onClearVisualSearch,
  formatError,
}) {
  const visualSearchResultsCount = visualSearchResults?.length ?? 0;

  // Check if user has correct role.
  const user = useUserContext();
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  const analytics = useAnalyticsContext();

  if (isAuthorized) {
    if (visualSearchResults) {
      // Authorized user performed visual search request.
      analytics.addTrack('trackSearch', {
        dbs: 'rkdimages_and_lite',
        tm: {
          title: 'Visual Search',
          uri: window.location.href,
        },
        sa: false,
        actionType: 'visual_search_request',
        searchResultCount: visualSearchResultsCount,
      });
    }
  } else if (user.authenticated) {
    // User logged in but does not have sufficient access.
    analytics.addTrack('trackDenial', {
      deny: 'No_License',
      tm: {
        title: 'Visual Search',
        uri: window.location.href,
      },
      actionType: 'page_view',
    });
  }

  return (
    user.authenticated !== null &&
    (!isAuthorized ? (
      <BasicPage>
        <SubscriptionRequired />
      </BasicPage>
    ) : (
      <StyledResultsHeaderVisualSearch className="results--header--visual-search">
        <LogoRkdShape />
        <div className="header--main">
          {fileDataUrl && (
            <div className="visual">
              <img src={fileDataUrl} alt="uploaded file" />
            </div>
          )}

          <div className="textual">
            {!visualSearchResults && (
              <>
                <h2 className="heading h3">
                  <Trans>Search by image</Trans>
                </h2>
                <div className="visual-search-description">
                  <p>
                    <Trans>
                      Visual search goes through the RKDimages and RKDimages
                      Lite databases for images with similarities based on the
                      uploaded image. The results with the most matches are
                      displayed first. You can upload the following files: jpg,
                      jpeg and png.
                    </Trans>
                  </p>
                </div>
                <form
                  className="visual-search-file-upload"
                  onSubmit={onFormSubmit}
                >
                  {fileDataUrl === null && (
                    <input
                      type="file"
                      onChange={onFileChange}
                      accept="image/*"
                    />
                  )}
                  {formatError && (
                    <StyledFileError>
                      <IconAlert />
                      <span className="text">
                        <Trans>
                          Invalid file type. You can only upload jpg, jpeg and
                          png files. Please check if the uploaded file meets
                          these conditions and try again.
                        </Trans>
                      </span>
                    </StyledFileError>
                  )}
                  <button className="btn btn-yellow" type="submit">
                    <Trans>Search</Trans>
                  </button>
                  {fileDataUrl && (
                    <button
                      className="btn btn-black"
                      onClick={onClearVisualSearch}
                    >
                      <Trans>Clear</Trans>
                    </button>
                  )}
                </form>
              </>
            )}

            {visualSearchResults && (
              <>
                <h2 className="heading h3">
                  {/* prettier-ignore */}
                  <Plural
                      value={visualSearchResultsCount}
                      _0="No results"
                      one={
                        <Trans># <span className="font-light">result matches the picture</span></Trans>
                      }
                      other={
                        <Trans># <span className="font-light">results match the picture</span></Trans>
                      }
                    />
                </h2>
                <button className="btn btn-black" onClick={onClearVisualSearch}>
                  <Trans>Clear</Trans>
                </button>
              </>
            )}
          </div>
        </div>
      </StyledResultsHeaderVisualSearch>
    ))
  );
}

export default ResultsHeaderVisualSearch;
