import { useContext } from 'react';
import { SnackBarContext } from 'context/SnackBarContext';

/**
 * Gets the user object.
 *
 * This hooks aids in DX by throwing an exception when the context cannot be used.
 *
 * @return
 *   The user context.
 */
const useSnackBarContext = () => {
  const context = useContext(SnackBarContext);

  if (context === undefined) {
    throw new Error(
      'useSnackBarContext was used outside of the SnackBarContextProvider.'
    );
  }

  return context;
};

export default useSnackBarContext;
