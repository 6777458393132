import styled from 'styled-components';
import FullClick from 'components/helpers/FullClick';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import color from 'styles/base/variables/color';
import borderRadius from 'styles/base/variables/borderRadius';
import easing from 'styles/base/variables/easing';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { Plural, Trans } from '@lingui/macro';
import respValue from 'styles/base/helpers/respValue';

const StyledTile = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${color.sand};
  height: 100%;
  min-height: 20rem;
  border-radius: ${borderRadius.small};
  transition: box-shadow ${easing.normal};

  // To position visual.
  position: relative;
  z-index: 0;

  &:hover {
    box-shadow: 0 0 0.75rem 0 ${color.anthraciteDimmed60};
  }

  &.dark {
    background-color: ${color.anthracite};

    .textual {
      color: ${color.white};
    }

    &:hover {
      box-shadow: 0 0 0.75rem 0 ${color.anthracite};
    }
  }

  &.has-visual {
    &:hover {
      box-shadow: 0 0 0.75rem 0 ${color.anthracite};
    }

    .textual {
      background-color: ${color.anthraciteDimmed60};
      border-radius: ${borderRadius.small};
    }
  }

  .visual {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      border-radius: ${borderRadius.small};
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .textual {
    display: flex;
    width: 100%;
    ${respValue('padding-top', '3rem', '2.2rem')}
    ${respValue('padding-right', '4rem', '3rem')}
    ${respValue('padding-bottom', '3rem', '2.2rem')}
    ${respValue('padding-left', '4rem', '3rem')}

    .main {
      flex-grow: 1;
    }

    > .icon {
      flex-shrink: 0;
      margin-left: 1rem;

      .disc {
        background-color: ${color.white};
        color: ${color.anthracite};
      }

      .icon-search {
        svg {
          width: 3rem;
        }
      }
    }

    .heading {
      margin-bottom: 0.2em;
      ${respValue('font-size', '2.8rem', '2rem')};
    }
  }
`;

function Tile({ theme, title, subtitle, icon, image, href, localized, count }) {
  const classNames = ['tile', image ? 'has-visual' : '', theme ?? ''];

  // Define link content.
  let linkContent = (
    <VisuallyHidden>
      <Trans>Read more about {title}</Trans>
    </VisuallyHidden>
  );

  return (
    <FullClick>
      <StyledTile className={classNames.join(' ')}>
        {image && (
          <div className="visual">
            <img src={image.src} alt={image.alt} />
          </div>
        )}
        <div className="textual">
          <div className="main">
            <h2 className="heading">{title}</h2>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            {count >= 0 && (
              <Plural
                value={count}
                _0="No results"
                one={
                  <Trans>
                    # <span className="font-light">result</span>
                  </Trans>
                }
                other={
                  <Trans>
                    # <span className="font-light">results</span>
                  </Trans>
                }
              />
            )}
          </div>
          <div className="icon">{icon}</div>
        </div>
        {localized ? (
          <LocalizedLink to={href} className="full-click__trigger">
            {linkContent}
          </LocalizedLink>
        ) : (
          <a href={href} className="full-click__trigger">
            {linkContent}
          </a>
        )}
      </StyledTile>
    </FullClick>
  );
}

export default Tile;
