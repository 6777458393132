import { useLocation } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';

/**
 * Hook to determine whether the component is rendered on the main search page.
 *
 * @returns {boolean}
 *   TRUE if on the main search page, FALSE otherwise.
 */
const useIsSearchPage = () => {
  const { pathname } = useLocation();
  const localizedPath = getLocalizedPath('/search');
  return pathname === localizedPath;
};

export default useIsSearchPage;
