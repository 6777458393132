import { stringify } from 'qs';

export default function createSearchUrlQuery(
  searchTerm,
  filters,
  prefix = true
) {
  const queryFilters = [];

  if (filters) {
    filters.forEach((filter) => {
      queryFilters.push({
        field: filter.field,
        values: filter.values,
        type: 'all',
      });
    });
  }

  const query = stringify(
    { q: searchTerm ?? '', filters: queryFilters },
    {
      arrayFormat: 'indices',
      addQueryPrefix: prefix,
    }
  );

  return query;
}
