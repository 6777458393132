import styled from 'styled-components';
import zIndex from 'styles/base/variables/zIndex';

const FullClick = styled.div`
  position: relative;

  .full-click__trigger {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: ${zIndex.fullClick - 1};
    }

    &:focus-visible {
      outline: 0;

      &::before {
        outline: 0.2rem solid currentColor;
        outline-offset: 0.3rem;
      }
    }
  }

  button:not(.full-click__trigger),
  a:not(.full-click__trigger) {
    position: relative;
    z-index: ${zIndex.fullClick};
  }
`;

export default FullClick;
