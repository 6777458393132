import { useState } from 'react';
import HashChange from 'react-hashchange';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import border from 'styles/base/variables/border';
import borderRadius from 'styles/base/variables/borderRadius';
import respValue from 'styles/base/helpers/respValue';
import slugify from 'slugify';
import { IconPointerDown } from 'components/graphical/Icons';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import Mls from 'components/detail/Mls';

const StyledSection = styled.div`
  background-color: ${color.white};
  border: ${border.dimmed};
  border-radius: ${borderRadius.small};

  ${respValue('padding-top', '3rem')};
  ${respValue('padding-right', '4rem')};
  ${respValue('padding-bottom', '3rem')};
  ${respValue('padding-left', '4rem')};

  > .heading {
    button {
      ${alignIconDefaults()};
      width: 100%;

      .icon {
        transition: transform ${easing.normal};
      }
    }
  }

  > .content {
    ${respValue('margin-top', '3rem')};
    border-top: ${border.dimmed};
    ${respValue('padding-top', '3rem')};

    // To position yellow border.
    position: relative;

    &:before {
      content: '';
      width: 12rem;
      height: 0.4rem;
      background-color: ${color.yellow};
      display: block;
      position: absolute;
      top: -0.4rem;
    }
  }

  &.open {
    > .heading {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &:not(.open) {
    > .content {
      display: none;
    }
  }
`;

function Section({ label, children }) {
  // Toggle section.
  const [itemIsOpen, setItemIsOpen] = useState(true);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  return (
    <StyledSection
      id={slugify(getLocalizedValueFromMls(label))}
      className={itemIsOpen ? 'open' : ''}
    >
      <HashChange
        onChange={({ hash }) => {
          if (hash.substr(1) === slugify(getLocalizedValueFromMls(label))) {
            setItemIsOpen(true);
          }
        }}
      ></HashChange>
      <h2 className="heading">
        <button
          className="btn-reset"
          onClick={itemHandleClick}
          aria-expanded={itemIsOpen ? 'true' : 'false'}
        >
          <span className="text">
            <Mls strings={label} />
          </span>
          <IconPointerDown />
        </button>
      </h2>
      <div className="content">{children}</div>
    </StyledSection>
  );
}

export default Section;
