import styled from 'styled-components';
import color from 'styles/base/variables/color';
import borderRadius from 'styles/base/variables/borderRadius';
import easing from 'styles/base/variables/easing';
import focusStyling from 'styles/base/helpers/focusStyling';
import Square from 'components/graphical/Square';

const StyledCheckbox = styled.span`
  .square {
    width: 2rem;
    background-color: ${color.white};
    position: relative;
  }

  input:focus-visible + label & {
    .square {
      ${focusStyling};
    }
  }

  .square {
    &:before {
      transition: ${easing.normal};
      content: '';
      position: absolute;
      border-radius: ${borderRadius.smaller};
      inset: 0.2rem;
    }
  }

  input:checked + label & {
    .square {
      &:before {
        background-color: currentColor;
      }
    }
  }
`;

function Checkbox({ ownClassName }) {
  return (
    <StyledCheckbox className={ownClassName}>
      <Square className="square" />
    </StyledCheckbox>
  );
}

export default Checkbox;
