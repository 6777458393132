import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import borderRadius from 'styles/base/variables/borderRadius';
import zIndex from 'styles/base/variables/zIndex';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { IconClose } from 'components/graphical/Icons';
import FocusTrap from 'focus-trap-react';
import OutsideClick from 'components/helpers/OutsideClick';
import { letterSpacing } from 'styles/base/variables/typography';

const StyledModal = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${color.anthraciteDimmed50};
  z-index: ${zIndex.modal};

  .modal--outer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10vw);
    max-width: 64rem;
    max-height: calc(100% - 10vw);
    overflow: auto;
    background-color: ${color.paper};
    border-radius: ${borderRadius.medium};
  }

  .modal--inner {
    padding: 5rem 5rem 4rem 5rem;

    // To position close button.
    position: relative;

    .modal--actions {
      .close-modal {
        width: 4.8rem;
        height: 4.8rem;
        padding: 0;
        position: absolute;
        top: 1rem;
        right: 1rem;
        justify-content: center;
        align-items: center;
        border-radius: 0;
      }
    }

    .modal--content {
      > * {
        + * {
          margin-top: 1em;
        }
      }

      .modal--content-title {
        font-size: 2.6rem;
        letter-spacing: ${letterSpacing.heading};
      }
    }
  }
`;

function Modal({ children, closeTrigger, disableClick = false }) {
  // Modal with backdrop.
  // Has close-button which needs to get onOutsideClick.
  // FocusTrap needs to have "allowOutsideClick: true",
  // otherwise OutsideClick will never be clicked.
  // Use disableClick = true to not close on outside clicks. This
  // requires implementing a way to close the modal another way.

  return (
    <StyledModal>
      <div className="modal--outer">
        <OutsideClick onOutsideClick={closeTrigger} disableClick={disableClick}>
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
            }}
          >
            <div className="modal--inner">
              <div className="modal--actions">
                <button
                  className="btn btn-reset close-modal"
                  onClick={closeTrigger}
                >
                  <VisuallyHidden>
                    <Trans>Close</Trans>
                  </VisuallyHidden>
                  <IconClose />
                </button>
              </div>
              <div className="modal--content">{children}</div>
            </div>
          </FocusTrap>
        </OutsideClick>
      </div>
    </StyledModal>
  );
}

export default Modal;
