import { translatePath } from 'utils/localizedRoutes';

// List of English route paths that have a full-width layout.
// Note that we don't need to add paths for other languages,
// because translatePath takes care of that.
const fullWidthRoutes = ['/en/detail'];

function isFullWidthRoute(location) {
  const pathname = location.pathname;
  const match = fullWidthRoutes.find((route) => {
    return translatePath({ pathname }, 'en').startsWith(route);
  });
  return match;
}
export { isFullWidthRoute };
