import styled from 'styled-components';
import easing from 'styles/base/variables/easing';
import borderRadius from 'styles/base/variables/borderRadius';

const Square = styled.span`
  width: 4rem;
  aspect-ratio: 1/1;
  border-radius: ${borderRadius.small};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${easing.normal};
  border: 0.1rem solid currentColor;
`;

export default Square;
