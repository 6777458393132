import ArtworkDetailView from 'components/artwork/ArtworkDetail';
import useApiData from 'hooks/useApiData';
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams } from 'react-router-dom';
import GridContainer from 'components/layout/GridContainer';
import { t } from '@lingui/macro';
import Error from 'components/misc/Error';
import Loading from 'components/misc/Loading';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import ArchiveDetailView from 'components/artwork/ArchiveDetailView';
import { useEffect } from 'react';
import {
  archiveUriRegExp,
  archiveUriType,
  imageUriType,
  getArchiveItem,
  openToTreeNode,
} from 'utils/archiveTreeUtils';

function ArtworkDetail() {
  const { id } = useParams();
  let detailType = imageUriType;
  let apiPath = '/detail';

  if (archiveUriRegExp.test(id)) {
    detailType = archiveUriType;
    apiPath = '/get-archive-tree';
  }

  const {
    isLoading,
    isError,
    data: [details],
  } = useApiData([{ path: apiPath, params: [`id=${id}`] }], [{}]);

  // Setter setSearchParams seems not to be used but is required with
  // useSearchParams(). Therefore we exclude only line below from eslint.
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isNavigationLoading,
    isNavigationError,
    data: [navigationData],
  } = useApiData(
    [
      {
        path: '/search-navigation',
        params: [
          `${searchParams.get('c')}&n=${encodeURIComponent(
            searchParams.get('n')
          )}`,
        ],
      },
    ],
    [{}]
  );

  useEffect(() => {
    if (archiveUriRegExp.test(id) && details.archiveTree !== undefined) {
      openToTreeNode(details.archiveTree, id);
    }
  }, [details.archiveTree, id]);

  if (isError) {
    return (
      <GridContainer>
        <Error title={t`The requested object could not be loaded.`} />
      </GridContainer>
    );
  }

  if (isLoading) {
    return (
      <GridContainer>
        <Loading layoutStyle="full" />
      </GridContainer>
    );
  }

  if (detailType === imageUriType) {
    const baseDetails = {
      id: id,
      title: details.title,
      subtitle: details.subtitle,
      meta: details.meta,
      additionalContent: details.additional_content,
    };
    const props = {
      images: details.images,
      baseDetails: baseDetails,
      tree: details.tree,
      navigationData: {
        data: navigationData,
        isNavigationLoading: isNavigationLoading,
        isNavigationError: isNavigationError,
      },
    };

    return (
      <>
        <Helmet>
          <title>{getLocalizedValueFromMls(details.title)}</title>
        </Helmet>
        <ArtworkDetailView {...props} />
      </>
    );
  } else if (detailType === archiveUriType) {
    const item = getArchiveItem(details.archiveTree, id);
    const props = {
      id: id,
      title: item?.label?.value ?? '',
      archiveTree: details.archiveTree,
      tree: details.tree,
      images: details.images,
    };

    return (
      <>
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
        <ArchiveDetailView {...props} />
      </>
    );
  }
}

export default ArtworkDetail;
