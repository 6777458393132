/**
 * Scale an image resulting in its largest dimension to be maxSize or smaller.
 *
 * @param file {File} Input file of a form.
 * @param maxSize {number} Dimenstions of the output image.
 * @param format {string} Image format as mime type.
 *
 * @returns {Promise<Blob | null>} Promise resolving to a scale image or a null if provided an invalid file type
 */
export async function scaleImage(file, maxSize, format = 'image/jpeg') {
  // Ensure the file is an image.
  if (!file.type.match(/image.*/)) {
    return null;
  }

  const image = new Image();
  image.src = URL.createObjectURL(file);

  await new Promise((res) => (image.onload = res));
  const canvas = document.createElement('canvas');

  let width = image.width;
  let height = image.height;
  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }
  canvas.width = width;
  canvas.height = height;

  canvas
    .getContext('2d', { alpha: true })
    .drawImage(image, 0, 0, width, height);

  return new Promise((res) => canvas.toBlob(res, format));
}
