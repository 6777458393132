const size = {
  // Normal space between gridContainer and page.
  pagePadding: '5vw',
  gridMax: '160rem',
  gridColumns: 12,
  gridGutter: '2rem',
  // Space between gridContainer and page when gridContainer spans full page-width.
  gridFullPagePadding: '8rem',
  gridFullAsideContentWidth: '45rem',
  // Button.
  buttonPaddingBlock: '0.8em',
  buttonPaddingInline: '1.5em',
};

export default size;
