import { Trans } from '@lingui/macro';
import styled from 'styled-components';
// import { IconDownload, IconTrash } from 'components/graphical/Icons';
import { IconTrash } from 'components/graphical/Icons';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';

const StyledUserCollectionItemMenu = styled.div`
  > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    > li {
      display: inline-flex;

      .btn {
        padding: 0.5rem;
      }
    }
  }
`;

function MenuUserCollectionItem({
  itemId,
  handleConfirmClick,
  processingState,
}) {
  return (
    <StyledUserCollectionItemMenu>
      <ul className="collection-item-menu">
        {/*<li>*/}
        {/*  <button className="btn btn-reset">*/}
        {/*    <VisuallyHidden>*/}
        {/*      <Trans>Download/Export</Trans>*/}
        {/*    </VisuallyHidden>*/}
        {/*    <IconDownload />*/}
        {/*  </button>*/}
        {/*</li>*/}
        <li>
          <button
            className="btn btn-reset"
            onClick={() => handleConfirmClick(itemId)}
            disabled={processingState}
          >
            <VisuallyHidden>
              <Trans>Delete from selection</Trans>
            </VisuallyHidden>
            <IconTrash />
          </button>
        </li>
      </ul>
    </StyledUserCollectionItemMenu>
  );
}

export default MenuUserCollectionItem;
