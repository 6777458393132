import { ReactComponent as SvgLogoRkdNl } from 'assets/svg/logo-rkd-NL.svg';
import { ReactComponent as SvgLogoRkdEn } from 'assets/svg/logo-rkd-EN.svg';
import { ReactComponent as SvgLogoRkdShape } from 'assets/svg/logo-rkd--shape.svg';
import { ReactComponent as SvgLogoRkdResearchNl } from 'assets/svg/logo-rkd-research-NL.svg';
import { ReactComponent as SvgLogoRkdResearchEn } from 'assets/svg/logo-rkd-research-EN.svg';
import { ReactComponent as SvgLogoRkdResearchTextual } from 'assets/svg/logo-rkd-research--textual.svg';
import { useLingui } from '@lingui/react';

export const LogoRkd = function () {
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;
  let icon = <SvgLogoRkdEn focusable="false" />;
  if (activeLocale === 'nl') {
    icon = <SvgLogoRkdNl focusable="false" />;
  }
  return (
    <span className="logo-rkd" aria-hidden="true">
      {icon}
    </span>
  );
};

export const LogoRkdShape = function () {
  return (
    <span className="logo-rkd--shape" aria-hidden="true">
      <SvgLogoRkdShape focusable="false" />
    </span>
  );
};

export const LogoRkdResearch = function () {
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;
  let icon = <SvgLogoRkdResearchEn focusable="false" />;
  if (activeLocale === 'nl') {
    icon = <SvgLogoRkdResearchNl focusable="false" />;
  }
  return (
    <span className="logo-rkd-research" aria-hidden="true">
      {icon}
    </span>
  );
};

export const LogoRkdResearchTextual = function () {
  return (
    <span className="logo-rkd-research--textual" aria-hidden="true">
      <SvgLogoRkdResearchTextual focusable="false" />
    </span>
  );
};
