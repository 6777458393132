import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';
import Loading from 'components/misc/Loading';
import { t, Trans } from '@lingui/macro';
import {
  IconPointerFilledLeft,
  IconPointerFilledRight,
  IconPointerLeft,
} from '../graphical/Icons';

const StyledDetailsNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;

  .back-link {
    display: inline-flex;
    font-weight: bold;

    .text {
      margin-left: 10px;
    }
  }

  .pager {
    display: inline-flex;
    align-items: center;

    .results {
      margin: 0 10px;
    }
  }
`;

/**
 * Details navigation component.
 *
 * @param navigationData
 *   The details navigation.
 *
 * @returns {JSX.Element}
 */
function DetailsNavigation({ navigationData }) {
  const data = navigationData.data;
  const isLoading = navigationData.isNavigationLoading;
  const isError = navigationData.isNavigationError;
  const overview = data?.overview?.path !== undefined && data?.overview?.query;
  const hasPreviousItem =
    data?.previous?.path !== undefined && data?.previous?.query !== undefined;
  const hasNextItem =
    data?.next?.path !== undefined && data?.next?.query !== undefined;
  const hasCounter = data?.ui !== undefined;

  return (
    <StyledDetailsNavigation>
      {isLoading && <Loading spinnerSize="2rem" hiddenText={t`Loading...`} />}
      {!isLoading && !isError && typeof navigationData === 'object' && (
        <>
          <div>
            {overview && (
              <Link
                className="back-link"
                to={`${getLocalizedPath(data.overview.path)}?${
                  data.overview.query
                }`}
              >
                <IconPointerLeft />
                <span className="text">
                  <Trans>Back</Trans>
                </span>
              </Link>
            )}
          </div>
          <div className="pager">
            {hasPreviousItem && (
              <Link
                to={`${getLocalizedPath(data.previous.path)}${
                  data.previous.query
                }`}
              >
                <IconPointerFilledLeft />
              </Link>
            )}
            {hasCounter && (
              <span className="results">
                <strong>{data.ui.current}</strong> / {data.ui.total}
              </span>
            )}
            {hasNextItem && (
              <Link
                to={`${getLocalizedPath(data.next.path)}${data.next.query}`}
              >
                <IconPointerFilledRight />
              </Link>
            )}
          </div>
        </>
      )}
    </StyledDetailsNavigation>
  );
}

export default DetailsNavigation;
