import styled from 'styled-components';
import GridContainer from 'components/layout/GridContainer';
import AdjustOrder from 'components/helpers/AdjustOrder';
import ButtonLink from 'components/misc/ButtonLink';
import color from 'styles/base/variables/color';
import { fontSize, lineHeight } from 'styles/base/variables/typography';
import respValue from 'styles/base/helpers/respValue';
import roundTwo from 'styles/base/helpers/roundTwo';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import SpanGridCols from 'components/layout/SpanGridCols';
import TagsList from 'components/misc/TagsList';

const StyledHero = styled.div`
  ${respValue('margin-bottom', '5rem')}

  .hero--inner {
    position: relative;
    z-index: 0;

    .visual {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .textual {
      width: 100%;
      background-color: ${color.anthraciteDimmed60};
      color: ${color.white};
      padding-top: 5rem;
      ${respValue('padding-bottom', '10rem')}

      .textual--inner {
        ${mq(bp.extraLarge)} {
          width: ${SpanGridCols(6)};
          margin-left: auto;
          margin-right: auto;
        }
      }

      .tags {
        ${mq(0, bp.mobileMenu)} {
          display: none;
        }
      }

      .heading {
        ${respValue(
          'font-size',
          fontSize.heading.h1Extreme.desktop,
          fontSize.heading.h1Extreme.mobile
        )};
        line-height: ${lineHeight.heading.h1Extreme};
        margin-top: 0.3em;
        margin-bottom: 0.05em;
        max-width: 54rem;

        // To align with subtitle.
        transform: translateX(-0.05em);
      }

      .subtitle {
        ${respValue('font-size', '2.7rem')};
        line-height: ${roundTwo(35 / 27)};
        max-width: 54rem;
      }
    }
  }
`;

function Hero({ title, subtitle, tags, image }) {
  return (
    <StyledHero className="hero">
      <div className="hero--inner">
        <div className="visual">
          <img src={image.src} alt={image.alt} />
        </div>
        <AdjustOrder className="textual">
          <GridContainer>
            <AdjustOrder className="textual--inner">
              <h1 className="heading">{title}</h1>
              <p className="subtitle font-normal">{subtitle}</p>
              {tags && (
                <TagsList className="tags order-min">
                  {tags.map((item, i) => {
                    return (
                      <ButtonLink
                        className="btn-rounded btn-small btn-ghost-white"
                        key={i}
                        label={item.label}
                        href={item.href}
                      />
                    );
                  })}
                </TagsList>
              )}
            </AdjustOrder>
          </GridContainer>
        </AdjustOrder>
      </div>
    </StyledHero>
  );
}

export default Hero;
