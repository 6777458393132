import { useEffect } from 'react';

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;

    const scripts = document.getElementsByTagName('script');
    let hasScript = false;
    for (let i = 0; i < document.getElementsByTagName('script').length; i++) {
      if (scripts[i].src === url) {
        hasScript = true;
        break;
      }
    }
    const firstScript = scripts[0];

    // Prevent duplicate.
    if (!hasScript) {
      firstScript.parentNode.insertBefore(script, firstScript);

      return () => {
        firstScript.parentNode.removeChild(firstScript);
      };
    }
  }, [url]);
};

export default useScript;
