import { Helmet } from 'react-helmet';
import { t, Trans } from '@lingui/macro';
import useConsentContext from 'hooks/useConsentContext';
import BasicPage from 'components/layout/BasicPage';
import { useEffect, useState } from 'react';
import BodyText from 'components/misc/BodyText';

function PrivacyPolicy() {
  const consent = useConsentContext();
  const [trackingConsent, setTrackingConsent] = useState(
    consent.consentStatus.tracking
  );

  useEffect(() => {
    setTrackingConsent(consent.consentStatus.tracking);
  }, [consent.consentStatus.tracking]);

  const handleReopenConsent = () => {
    consent.resetConsent();
  };

  return (
    <>
      <Helmet>
        <title>{t`Privacy Policy`}</title>
      </Helmet>
      <BasicPage>
        <BodyText>
          <h1>{t`Privacy Policy`}</h1>
          <p>
            <Trans>
              This website uses functional and analytical cookies. Functional
              cookies are required for a properly functioning website. By using
              this website, you have agreed to the functional cookies. We use
              analytical cookies to analyse the traffic on this website. Currently
              you have{' '}
              <span className="text font-thick">
                {trackingConsent ? t`agreed` : t`disagreed`}
              </span>{' '}
              to the use of analytical cookies. For more detailed information,
              please read{' '}
              <a
                href={t`https://rkd.nl/en/privacy-policy-rkd`}
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
              .
            </Trans>
          </p>
          <p className="actions--outer">
            <button className="btn btn-yellow" onClick={handleReopenConsent}>
              <Trans>Change my cookie settings</Trans>
            </button>
          </p>
        </BodyText>
      </BasicPage>
    </>
  );
}

export default PrivacyPolicy;
