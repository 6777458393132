import styled from 'styled-components';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { IconPointerLeft } from 'components/graphical/Icons';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';

const StyledBackLink = styled.div`
  a {
    ${alignIconDefaults()};
  }
`;

function BackLink({ label, href }) {
  return (
    <StyledBackLink>
      <LocalizedLink to={href}>
        <IconPointerLeft />
        <span className="text font-thick">{label}</span>
      </LocalizedLink>
    </StyledBackLink>
  );
}

export default BackLink;
