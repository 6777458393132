/**
 * Component that executes given "onOutsideClick" if you click outside of it.
 */
import React from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

const OutsideClick = ({
  onOutsideClick,
  children,
  elementTag,
  disableClick = false,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: onOutsideClick,
    disableClick: disableClick,
  });
  const OuterTag = elementTag || 'div';

  return (
    <OuterTag className="outside-click" ref={ref}>
      {children}
    </OuterTag>
  );
};

export default OutsideClick;
