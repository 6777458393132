import { appendClassName } from '@elastic/react-search-ui-views/lib/cjs/view-helpers';
import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { getLocalizedLangAndValue } from 'utils/processors';
import { kebabCase } from 'lodash';
import {
  VisuallyHidden,
  VisuallyHiddenStyling,
} from 'components/helpers/VisuallyHidden';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import { IconPointerDown } from 'components/graphical/Icons';
import Checkbox from 'components/graphical/Checkbox';
import styled from 'styled-components';
import easing from 'styles/base/variables/easing';
import SearchForSearchFilters from './SearchForSearchFilters';

const StyledMultiCheckboxLabelFacet = styled.div`
  fieldset {
    legend {
      width: 100%;

      button {
        width: 100%;
        ${alignIconDefaults()};
        margin-bottom: 0;

        .count {
          flex: 0 0 auto;
        }

        .icon {
          transition: transform ${easing.normal};
          margin-left: auto;
        }
      }
    }

    > * {
      + * {
        margin-top: 1em;
      }
    }

    fieldset {
      padding-bottom: 0.5em;

      &:not(.no-indent) {
        margin-left: calc(2rem + 0.5em);
      }

      > * {
        + * {
          margin-top: 0.35em;
        }
      }
    }

    .sui-multi-checkbox-facet {
      > * {
        + * {
          margin-top: 0.35em;
        }
      }
    }

    &.depth-0 {
      &:not(.open) {
        > *:not(legend) {
          display: none;
        }
      }
    }

    &.open {
      > legend {
        > button {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    input[type='checkbox'] {
      ${VisuallyHiddenStyling};
    }

    label[for] {
      ${alignIconDefaults(null, 'baseline')};
      width: 100%;

      > *:not(.text) {
        flex-shrink: 0;
        position: relative;
        top: 0.2em;
      }

      .text {
        // We should set width, but the value is not important,
        // because it can grow and shrink via alignIconDefaults.
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .count {
        margin-left: auto;
      }
    }
  }
`;

/**
 * Component to render a single facet (i.e. a list of filter options on a
 * certain field in the search index.)
 *
 * This component is based on the MultiCheckboxFacet component from
 * @elastic/react-search-ui-views. Customizations include:
 * - Conversion from typescript to javascript;
 * - Support for option labels that differ from option values;
 * - Support for nested (hierarchical) facets;
 * - Improvements to the markup, like a better HTML ID for options.
 */
function MultiCheckboxLabelFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder,
  childFacets,
  depth = 0,
  values,
  facetParentId,
}) {
  // Toggle facet.
  const [facetIsOpen, setFacetIsOpen] = useState(false);
  const facetHandleClick = () => {
    setFacetIsOpen(!facetIsOpen);
  };

  const selectedCount = values.length;

  return (
    <StyledMultiCheckboxLabelFacet>
      <fieldset
        className={appendClassName(
          ['sui-facet', facetIsOpen ? 'open' : null, `depth-${depth}`],
          className
        )}
      >
        <legend className="">
          {depth === 0 ? (
            <button
              className="btn-reset h3"
              onClick={facetHandleClick}
              aria-expanded={facetIsOpen ? 'true' : 'false'}
            >
              <span className="text">{label}</span>
              {selectedCount > 0 ? (
                <span className="count font-smaller font-light">
                  {' '}
                  ( {selectedCount} )
                </span>
              ) : null}
              <IconPointerDown />
            </button>
          ) : (
            <VisuallyHidden>{label}</VisuallyHidden>
          )}
        </legend>

        {showSearch && (
          <div className="sui-facet-search">
            <input
              type="search"
              placeholder={searchPlaceholder || 'Search'}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
        )}
        <SearchForSearchFilters facetParentId={facetParentId} />
        <div className="sui-multi-checkbox-facet">
          {options.length < 1 && <div>No matching options</div>}
          {options.map((option) => {
            const checked = option.selected;
            const value = option.value;
            let optionLabel = getLocalizedLangAndValue(option.label);
            if (!optionLabel.string) {
              // Default to Dutch translation.
              optionLabel = getLocalizedLangAndValue(option.label, 'nl');
            }
            const htmlId = `option__${kebabCase(label)}__${kebabCase(value)}`;
            return (
              <div key={value} className="form-item">
                <input
                  id={htmlId}
                  type="checkbox"
                  checked={checked}
                  onChange={() => (checked ? onRemove(value) : onSelect(value))}
                />
                <label htmlFor={htmlId}>
                  <Checkbox />
                  <span className="text" lang={optionLabel.langCode}>
                    {optionLabel.string}
                  </span>
                  <span className="count font-smaller font-light">
                    {option.count.toLocaleString('en')}
                  </span>
                </label>
                {
                  // If checked, render child facet if available.
                  checked && childFacets?.[value]
                }
              </div>
            );
          })}
        </div>

        {showMore && (
          <button
            type="button"
            className="btn-reset sui-facet-view-more"
            onClick={onMoreClick}
          >
            <VisuallyHidden>
              <Trans>Show more options</Trans>
            </VisuallyHidden>
            <span aria-hidden="true">
              <Trans>+ More</Trans>
            </span>
          </button>
        )}
      </fieldset>
    </StyledMultiCheckboxLabelFacet>
  );
}

export default MultiCheckboxLabelFacet;
