import Search from 'routes/Search';
import ArtworkDetail from 'routes/ArtworkDetail';
import MyCollections from 'routes/MyCollections';
import CollectionDetail from 'routes/CollectionDetail';
import SearchExplanation from 'routes/SearchExplanation';
import VisualSearch from 'routes/VisualSearch';
import { i18n } from '@lingui/core';
import LinkToMyWorkspace from 'components/misc/LinkToMyWorkspace';
import { matchPath, generatePath } from 'react-router-dom';
// import RendererTest from 'development/RendererTest';
// import ImagesDemo from 'development/ImagesDemo';
import FullTripleIfImage from 'routes/FullTripleIfImage';
import PrivacyPolicy from 'routes/PrivacyPolicy';
import AboutRkdResearch from 'routes/AboutRkdResearch';
import ManualRkdResearch from 'routes/ManualRkdResearch';
import ArtArchitectureThesaurus from 'routes/ArtArchitectureThesaurus';
import SearchTheAAT from 'routes/SearchTheAAT';
import ContributionsToTheAAT from 'routes/ContributionsToTheAAT';

/**
 * List of translated route paths and their elements.
 *
 * To link to a translated route, use the <LocalizedLink> component with the
 * English path as `to` prop.
 *
 * @type {[{path: {en: string, nl: string}, element: JSX.Element}]}
 */
export const localizedRoutes = [
  {
    path: {
      en: 'detail',
      nl: 'detail',
    },
    element: ArtworkDetail,
  },
  {
    path: {
      en: 'detail/:id',
      nl: 'detail/:id',
    },
    element: ArtworkDetail,
  },
  {
    path: {
      en: 'search',
      nl: 'zoeken',
    },
    element: Search,
  },
  {
    path: {
      en: 'visual-search',
      nl: 'visual-search',
    },
    element: VisualSearch,
  },
  {
    path: {
      en: 'my-workspace',
      nl: 'my-workspace',
    },
    element: LinkToMyWorkspace,
  },
  {
    path: {
      en: 'search-manual',
      nl: 'zoekinstructie',
    },
    element: SearchExplanation,
  },
  // {
  //   path: {
  //     en: 'renderer-test',
  //     nl: 'renderer-test',
  //   },
  //   element: RendererTest,
  // },
  {
    path: {
      en: 'my-selections',
      nl: 'mijn-selecties',
    },
    element: MyCollections,
  },
  {
    path: {
      en: 'my-selections/:id',
      nl: 'mijn-selecties/:id',
    },
    element: CollectionDetail,
  },
  // {
  //   path: {
  //     en: 'images-demo',
  //     nl: 'afbeeldingen-demo',
  //   },
  //   element: ImagesDemo,
  // },
  {
    path: {
      en: 'full-iiif-image/:src',
      nl: 'volledige-iiif-afbeelding/:src',
    },
    element: FullTripleIfImage,
  },
  {
    path: {
      en: 'privacy-policy',
      nl: 'privacybeleid',
    },
    element: PrivacyPolicy,
  },
  {
    path: {
      en: 'about-rkd-research',
      nl: 'over-rkd-research',
    },
    element: AboutRkdResearch,
  },
  {
    path: {
      en: 'art-and-architecture-thesaurus',
      nl: 'art-and-architecture-thesaurus',
    },
    element: ArtArchitectureThesaurus,
  },
  {
    path: {
      en: 'search-the-aat',
      nl: 'zoeken-in-de-aat',
    },
    element: SearchTheAAT,
  },
  {
    path: {
      en: 'contributions-to-the-aat',
      nl: 'bijdragen-aan-de-aat',
    },
    element: ContributionsToTheAAT,
  },
  {
    path: {
      en: 'manual-rkd-research',
      nl: 'handleiding-rkd-research',
    },
    element: ManualRkdResearch,
  },
];

/**
 * Translates a full localized path, e.g. /en/search, to a full localized path
 * in the given locale, e.g. /nl/zoeken.
 *
 * Intended for use in the language switcher.
 */
export function translatePath(location, localeCode) {
  const { pathname = '', search = '', hash = '' } = location;
  const [, pathLocale, ...pathRouteParts] = pathname.split('/');
  const pathRoute = pathRouteParts.join('/');

  if (typeof pathRoute === 'undefined') {
    return `/${localeCode}`;
  }
  if (localeCode === pathLocale) {
    return `${pathname}${search}${hash}`;
  }

  let routerMatch = null;
  let localizedRouteMatch = null;
  for (const route of localizedRoutes) {
    routerMatch = matchPath(route.path[pathLocale], `/${pathRoute}`);
    if (routerMatch) {
      // We found a match, so remember the match and the route info and break.
      localizedRouteMatch = route;
      break;
    }
  }

  if (localizedRouteMatch === null) {
    // Fallback if nothing matched: return localized home.
    return `/${localeCode}`;
  }

  // Unfortunately generatePath does not take care of encoding URL components.
  const encodedParams = encodeParams(routerMatch);

  let path = generatePath(
    `/${localeCode}/${localizedRouteMatch.path[localeCode]}`,
    encodedParams
  );
  if (search) {
    path = `${path}${search}`;
  }
  if (hash) {
    path = `${path}${hash}`;
  }

  return path;
}

/**
 * Returns the localized path of a route for a given English sub-path.
 *
 * Intended to generate localized links.
 */
export function getLocalizedPath(to, localeCode = null) {
  // If no localeCode is given, default to the current language.
  localeCode = localeCode ?? i18n.locale;

  const isRelative = !to.startsWith('/');
  if (isRelative) {
    console.error('No support for relative paths yet.');
    return to;
  }

  // Use the React Router API (matchPath()) to see if the `to` value matches
  // the English path of a route.
  let routerMatch = null;
  let localizedRouteMatch = null;
  for (const route of localizedRoutes) {
    routerMatch = matchPath(route.path.en, to);
    if (routerMatch) {
      // We found a match, so remember the match and the route info and break.
      localizedRouteMatch = route;
      break;
    }
  }

  if (localizedRouteMatch === null) {
    // Fallback if nothing matched: return localized home.
    return `/${localeCode}`;
  }

  // Unfortunately generatePath does not take care of encoding URL components.
  const encodedParams = encodeParams(routerMatch);
  // Generate a localized path with the matching locale prefix, replacing
  // dynamic parts with the encoded parameters.
  return generatePath(
    `/${localeCode}/${localizedRouteMatch.path[localeCode]}`,
    encodedParams
  );
}

/**
 * Helper function to encode the params in a match object a returned by
 * matchPath().
 */
function encodeParams(match) {
  const encodedParams = {};
  if (match.params) {
    for (var key in match.params) {
      encodedParams[key] = encodeURIComponent(match.params[key]);
    }
  }
  return encodedParams;
}
