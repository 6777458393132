import styled from 'styled-components';
import color from 'styles/base/variables/color';
import { Trans } from '@lingui/macro';

const StyledSkipLink = styled.a`
  background-color: ${color.paper};
  text-align: center;
  padding: 1rem;

  &:not(:focus-visible) {
    position: absolute !important;
    height: 0px;
    width: 0px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
`;

function SkipLink() {
  return (
    <StyledSkipLink href="#main-content" className="skip-link font-thick">
      <Trans>Skip to main content</Trans>
    </StyledSkipLink>
  );
}

export default SkipLink;
