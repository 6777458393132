import { ReactComponent as SvgAddToFolder } from 'assets/svg/add-to-folder.svg';
import { ReactComponent as SvgAddFolder } from 'assets/svg/add-folder.svg';
import { ReactComponent as SvgAlert } from 'assets/svg/alert.svg';
import { ReactComponent as SvgBurger } from 'assets/svg/burger.svg';
import { ReactComponent as SvgCheck } from 'assets/svg/check.svg';
import { ReactComponent as SvgClose } from 'assets/svg/close.svg';
import { ReactComponent as SvgDownload } from 'assets/svg/download.svg';
import { ReactComponent as SvgEllipsis } from 'assets/svg/ellipsis.svg';
import { ReactComponent as SvgFilter } from 'assets/svg/filter.svg';
import { ReactComponent as SvgFolder } from 'assets/svg/folder.svg';
import { ReactComponent as SvgImage } from 'assets/svg/image.svg';
import { ReactComponent as SvgInfo } from 'assets/svg/info.svg';
import { ReactComponent as SvgLock } from 'assets/svg/lock.svg';
import { ReactComponent as SvgLogOut } from 'assets/svg/log-out.svg';
import { ReactComponent as SvgMonitor } from 'assets/svg/monitor.svg';
import { ReactComponent as SvgPencil } from 'assets/svg/pencil.svg';
import { ReactComponent as SvgPlus } from 'assets/svg/plus.svg';
import { ReactComponent as SvgPointerDown } from 'assets/svg/pointer-down.svg';
import { ReactComponent as SvgPointerLeft } from 'assets/svg/pointer-left.svg';
import { ReactComponent as SvgPointerRight } from 'assets/svg/pointer-right.svg';
import { ReactComponent as SvgUser } from 'assets/svg/user.svg';
import { ReactComponent as SvgShare } from 'assets/svg/share.svg';
import { ReactComponent as SvgSearch } from 'assets/svg/search.svg';
import { ReactComponent as SvgSearchExtensive } from 'assets/svg/search-extensive.svg';
import { ReactComponent as SvgSearchVisual } from 'assets/svg/search-visual.svg';
import { ReactComponent as SvgTrash } from 'assets/svg/trash.svg';
import { ReactComponent as SvgPointerFilledLeft } from 'assets/svg/pointer-filled-left.svg';
import { ReactComponent as SvgPointerFilledRight } from 'assets/svg/pointer-filled-right.svg';

import styled from 'styled-components';

const StyledIcon = styled.span`
  // Take correct own height;
  display: flex;
`;

export const IconAddToFolder = function () {
  return (
    <StyledIcon className="icon icon-add-to-folder" aria-hidden="true">
      <SvgAddToFolder focusable="false" />
    </StyledIcon>
  );
};

export const IconAddFolder = function () {
  return (
    <StyledIcon className="icon icon-add-folder" aria-hidden="true">
      <SvgAddFolder focusable="false" />
    </StyledIcon>
  );
};

export const IconAlert = function () {
  return (
    <StyledIcon className="icon icon-alert" aria-hidden="true">
      <SvgAlert focusable="false" />
    </StyledIcon>
  );
};

export const IconBurger = function () {
  return (
    <StyledIcon className="icon icon-burger" aria-hidden="true">
      <SvgBurger focusable="false" />
    </StyledIcon>
  );
};

export const IconCheck = function () {
  return (
    <StyledIcon className="icon icon-check" aria-hidden="true">
      <SvgCheck focusable="false" />
    </StyledIcon>
  );
};

export const IconClose = function () {
  return (
    <StyledIcon className="icon icon-close" aria-hidden="true">
      <SvgClose focusable="false" />
    </StyledIcon>
  );
};

export const IconDownload = function () {
  return (
    <StyledIcon className="icon icon-download" aria-hidden="true">
      <SvgDownload focusable="false" />
    </StyledIcon>
  );
};

export const IconEllipsis = function () {
  return (
    <StyledIcon className="icon icon-ellipsis" aria-hidden="true">
      <SvgEllipsis focusable="false" />
    </StyledIcon>
  );
};

export const IconFilter = function () {
  return (
    <StyledIcon className="icon icon-filter" aria-hidden="true">
      <SvgFilter focusable="false" />
    </StyledIcon>
  );
};

export const IconFolder = function () {
  return (
    <StyledIcon className="icon icon-folder" aria-hidden="true">
      <SvgFolder focusable="false" />
    </StyledIcon>
  );
};

export const IconInfo = function () {
  return (
    <StyledIcon className="icon icon-info" aria-hidden="true">
      <SvgInfo focusable="false" />
    </StyledIcon>
  );
};

export const IconImage = function () {
  return (
    <SvgImage className="icon icon-image" aria-hidden="true">
      <SvgInfo focusable="false" />
    </SvgImage>
  );
};

export const IconLock = function () {
  return (
    <StyledIcon className="icon icon-lock" aria-hidden="true">
      <SvgLock focusable="false" />
    </StyledIcon>
  );
};

export const IconLogOut = function () {
  return (
    <StyledIcon className="icon icon-log-out" aria-hidden="true">
      <SvgLogOut focusable="false" />
    </StyledIcon>
  );
};

export const IconMonitor = function () {
  return (
    <StyledIcon className="icon icon-monitor" aria-hidden="true">
      <SvgMonitor focusable="false" />
    </StyledIcon>
  );
};

export const IconPencil = function () {
  return (
    <StyledIcon className="icon icon-pencil" aria-hidden="true">
      <SvgPencil focusable="false" />
    </StyledIcon>
  );
};

export const IconPlus = function () {
  return (
    <StyledIcon className="icon icon-plus" aria-hidden="true">
      <SvgPlus focusable="false" />
    </StyledIcon>
  );
};

export const IconPointerDown = function () {
  return (
    <StyledIcon className="icon icon-pointer-down" aria-hidden="true">
      <SvgPointerDown focusable="false" />
    </StyledIcon>
  );
};

export const IconPointerLeft = function () {
  return (
    <StyledIcon className="icon icon-pointer-left" aria-hidden="true">
      <SvgPointerLeft focusable="false" />
    </StyledIcon>
  );
};

export const IconPointerRight = function () {
  return (
    <StyledIcon className="icon icon-pointer-right" aria-hidden="true">
      <SvgPointerRight focusable="false" />
    </StyledIcon>
  );
};

export const IconUser = function () {
  return (
    <StyledIcon className="icon icon-user" aria-hidden="true">
      <SvgUser focusable="false" />
    </StyledIcon>
  );
};

export const IconShare = function () {
  return (
    <StyledIcon className="icon icon-share" aria-hidden="true">
      <SvgShare focusable="false" />
    </StyledIcon>
  );
};

export const IconSearch = function () {
  return (
    <StyledIcon className="icon icon-search" aria-hidden="true">
      <SvgSearch focusable="false" />
    </StyledIcon>
  );
};

export const IconSearchExtensive = function () {
  return (
    <StyledIcon className="icon icon-search" aria-hidden="true">
      <SvgSearchExtensive focusable="false" />
    </StyledIcon>
  );
};

export const IconSearchVisual = function () {
  return (
    <StyledIcon className="icon icon-search" aria-hidden="true">
      <SvgSearchVisual focusable="false" />
    </StyledIcon>
  );
};

export const IconTrash = function () {
  return (
    <StyledIcon className="icon icon-trash" aria-hidden="true">
      <SvgTrash focusable="false" />
    </StyledIcon>
  );
};

export const IconPointerFilledLeft = function () {
  return (
    <StyledIcon className="icon icon-pointer-filled-left" aria-hidden="true">
      <SvgPointerFilledLeft focusable="false" />
    </StyledIcon>
  );
};

export const IconPointerFilledRight = function () {
  return (
    <StyledIcon className="icon icon-pointer-filled-right" aria-hidden="true">
      <SvgPointerFilledRight focusable="false" />
    </StyledIcon>
  );
};
