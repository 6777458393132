const color = {
  white: '#FFFFFF',
  blackDimmed20: '#00000033',
  blackDimmed40: '#00000066',
  blackDimmed60: '#00000099',
  yellow: '#FECB00',
  sand: '#DEDED1',
  paper: '#F2F2ED',
  paperDimmed: '#F2F2ED99',
  anthracite: '#474747',
  anthraciteDimmed20: '#47474733',
  anthraciteDimmed50: '#47474780',
  anthraciteDimmed60: '#47474799',
  boulder: '#757575',
  boulderDimmed50: '#75757580',
  tealGreen: '#3F5D44',
  mineshaft: '#333333',
  mineshaftDimmed50: '#33333399',
  mineshaftDimmed90: '#333333E6',
  torchRed: '#FE0033',
};

export default color;
