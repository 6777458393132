import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import BasicPage from 'components/layout/BasicPage';
import BodyText from 'components/misc/BodyText';
import NotFound from 'components/misc/NotFound';

// Images.
import aatSearchOne from 'assets/images/aat-search/aat-search-1.png';
import aatSearchTwo from 'assets/images/aat-search/aat-search-2.png';
import aatSearchThree from 'assets/images/aat-search/aat-search-3.png';

// This page is only needed for Dutch, therefore string translations is not needed.
// The route for English must exist, so in that case output NotFound.
function SearchTheAAT() {
  return (
    <>
      {i18n.locale === 'nl' ? (
        <>
          <Helmet>
            <title>{t`Search the AAT`}</title>
          </Helmet>
          <BasicPage>
            <BodyText>
              <h1>Zoeken in de AAT</h1>
              <p>De AAT bevat een <a href="https://www.getty.edu/research/tools/vocabularies/aat/index.html" target="_blank" rel="noopener noreferrer">eenvoudige zoekfunctie</a> voor het vinden van concepten. De volgende tips kunnen hierbij behulpzaam zijn:</p>
              <ol>
                <li><p>De interface van het zoekportaal is Engelstalig. Echter, er kan op Nederlandstalige termen gezocht worden: zoeken op "begijnhofjes" geeft als resultaat het concept "béguinages".</p></li>
                <li><p>Concepten zijn in meervoud in de AAT opgenomen ("zwaarden"). De enkelvoudsvorm ("zwaard") is vrijwel altijd als alternatieve term opgenomen, maar niet altijd. Zoeken naar de meervoudsvorm vergroot de kans op succes.</p></li>
                <li><p>Middels de Boolean operator AND kan gezocht worden op meerdere termen die beide in (de beschrijving van) een concept moeten voorkomen. Middels de Boolean operator OR kan gezocht worden op meerdere termen waarvan minimaal 1 in (de beschrijving van) een concept moeten voorkomen.</p></li>
                <li><p>Middels een asterisk (*) kunnen termen getrunceerd worden. Zoeken op "zwaard" geeft de resultaten "ceremonieel zwaard" en "zwaard (gevestwapen)"; zoeken op "zwaard*" geeft daarnaast de resultaten "zwaardsmeden" en "zwaardkoppels".</p></li>
                <li><p>Uitgebreide (Engelstalige) documentatie over het zoeken in de AAT kan <a href="https://www.getty.edu/research/tools/vocabularies/aat/help.html" target="_blank" rel="noopener noreferrer">hier</a> geraadpleegd worden.</p></li>
              </ol>
              <h2>Navigatie door de AAT-hiërarchie</h2>
              <p>De AAT is een (poly)hiërarchisch gestructureerde termenlijst. Het is mogelijk om door deze hiërarchie te navigeren om zo vanuit een concept snel gerelateerde andere concepten te kunnen vinden. De volgende tips kunnen hierbij behulpzaam zijn:</p>
              <ol>
                <li>
                  <p>Het zoekportaal van de AAT bevat een knop Browse the AAT hierarchies. Deze leidt naar een weergave van de top van de AAT-hiërarchie. Van hieruit kan verder ingezoomd worden in de hiërarchie door steeds het blauwe driehoekige symbool aan te klikken dat links van de term staat waarin je geïnteresseerd bent.</p>
                  <img src={aatSearchOne} alt="" width="735" height="587" />
                </li>
                <li>
                  <p>De beschrijving van ieder concept bevat een subkopje "Hierarchical position" waarin de hiërarchische plaats van het concept wordt weergegeven. Door op het blauwe links symbool voor het concept zelf of voor één van de hiërarchisch hogerliggende concepten te klikken wordt een meer uitgebreide hiërarchie getoond.</p>
                  <img src={aatSearchTwo} alt="" width="736" height="536" />
                </li>
                <li>
                  <p>Het concept "begijnhofjes" is ondergebracht onder het overkoepelende concept "religious communities (built complexes)". Uit de hiërarchie blijkt bijvoorbeeld dat hier ook concepten als "kluizenaarshutten" en "monnikenkloosters" onder vallen.</p>
                  <img src={aatSearchThree} alt="" width="480" height="660" />
                </li>
                <li><p>De AAT is polyhiërarchisch. Dit betekent dat een concept onder meerdere bredere termen ("parents") kan vallen. Een daarvan wordt altijd aangeduid als "preferred parent", de andere als "additional parents". Zo valt het concept "membranofonen" zowel onder "geluidsmiddelen naar akoestische kenmerken" (preferred parent) als onder "muziekinstrumenten" (additional parent).</p></li>
                <li><p>De beschrijvingen van veel concepten bevatten non-hiërarchische functionele verwijzingen naar gerelateerde concepten ("related concepts"). Zo is het concept "oud roze" gerelateerd aan ("derived/made from") aan het concept "wegedoornbes".</p></li>
                <li><p>Uitgebreide (Engelstalige) documentatie over het navigeren door de AAT-hiërarchie kan <a href="https://www.getty.edu/research/tools/vocabularies/aat/help.html" target="_blank" rel="noopener noreferrer">hier</a> geraadpleegd worden.</p></li>
              </ol>
              <h2>AAT als Linked Open Data</h2>
              <p>De AAT is beschikbaar als Linked Open Data. De Getty Research Institute biedt een SPARQL-endpoint aan waarmee de AAT (en andere Getty Vocabularies) bevraagd kan worden. Daarnaast worden datadumps in verschillende formats aangeboden. Bovendien is de AAT via een reconciliation service beschikbaar in OpenRefine. Uitgebreide documentatie over deze mogelijkheden is te vinden op <a href="https://www.getty.edu/research/tools/vocabularies/index.html" target="_blank" rel="noopener noreferrer">deze pagina</a>.</p>
              <p>Tot begin 2021 werd (een deel van) de Nederlandstalige AAT ook beschikbaar gesteld via een door het RKD onderhouden website. Via deze website konden ook AAT-kandidaattermen (beginnend met 9000) geraadpleegd worden. Deze website is om technische redenen offline gehaald. Een datadump van deze database is op te vragen via <a href="mailto:aat@rkd.nl">aat@rkd.nl</a>. Let bij het gebruik van deze datadump op het volgende:</p>
              <ol>
                <li><p>Een aantal AAT-kandidaattermen (beginnend met 9000) zijn wel opgenomen in de AAT. Daar hebben ze een ander, niet corresponderend 3000 nummer gekregen.</p></li>
                <li><p>De AAT zoals die door Getty wordt aangeboden bevat meer Nederlandstalige termen dan deze datadump.</p></li>
                <li><p>Termen die zowel in deze datadump als bij Getty voorkomen kunnen bij Getty veranderingen ondergaan hebben. Getty bevat altijd de meest actuele versie van een concept.</p></li>
              </ol>
            </BodyText>
          </BasicPage>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default SearchTheAAT;
