import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import { useRef, useState } from 'react';
import useUserContext from '../../hooks/useUserContext';

const StyledMentionForm = styled.div`
  .subtitle {
    margin-bottom: 2.4rem;
  }
`;

function MentionForm({ cancelTrigger }) {
  const [message, setMessage] = useState('');
  const categoryRef = useRef(null);
  const descriptionRef = useRef(null);
  const emailRef = useRef(null);
  const user = useUserContext();

  async function sendMessage(e) {
    e.preventDefault();

    if (descriptionRef.current.value === '') {
      setMessage(t`The field 'Describe what you want to report' is required.`);
    } else {
      fetch(`/api/notify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          csrf_token: user.csrf_token,
          category: categoryRef.current.value,
          email: emailRef.current.value,
          description: descriptionRef.current.value,
          location: window.location.toString(),
        }),
      });
      setMessage(t`Your report has been sent.`);
      setTimeout(() => cancelTrigger(), 5000);
    }
  }

  return (
    <StyledMentionForm>
      <form action="">
        <fieldset>
          <legend>
            <Trans>Report</Trans>
          </legend>
        </fieldset>
        <p className="subtitle">
          <Trans>What do you want to report?</Trans>
        </p>
        <div className="form-item">
          <label htmlFor="category">
            <Trans>Choose a category</Trans>
          </label>
          <select name="category" id="category" ref={categoryRef}>
            <option value="General Data Protection Regulation (GDPR)">{t`General Data Protection Regulation (GDPR)`}</option>
            <option value="Copyright">{t`Copyright`}</option>
            <option value="Image">{t`Image`}</option>
            <option value="Data">{t`Data`}</option>
            <option value="Other">{t`Other`}</option>
          </select>
        </div>
        <div className="form-item">
          <label htmlFor="description">
            <Trans>Describe what you want to report</Trans>
          </label>
          <textarea
            id="description"
            placeholder={t`Description`}
            rows="8"
            ref={descriptionRef}
          />
        </div>
        <div className="form-item">
          <label htmlFor="email">
            <Trans>What is your e-mail address?</Trans>
          </label>
          <input
            id="email"
            type="email"
            placeholder={t`E-mail address`}
            ref={emailRef}
          />
        </div>
        <div className="form-actions">
          <button className="btn btn-yellow" onClick={sendMessage}>
            <span className="text">
              <Trans>Send</Trans>
            </span>
          </button>
          <button className="btn btn-reset" onClick={cancelTrigger}>
            <span className="text">
              <Trans>Cancel</Trans>
            </span>
          </button>
        </div>
        {message !== '' && <p>{message}</p>}
      </form>
    </StyledMentionForm>
  );
}

export default MentionForm;
