import { bp, mq } from 'styles/base/variables/mediaQueries';
import roundTwo from 'styles/base/helpers/roundTwo';
import splitValueAndUnit from 'styles/base/helpers/splitValueAndUnit';

// Returns given property and values as css declaration with increasing values per breakpoint.
// From minAmount for smaller screens to maxAmount for large screens.
const respValue = function (property, maxAmount, minAmount) {
  const splittedMax = splitValueAndUnit(maxAmount);
  const minAmountWithFallback = minAmount
    ? splitValueAndUnit(minAmount).value
    : splittedMax.value / 2;
  const diff = splittedMax.value - minAmountWithFallback;
  const unit = splittedMax.unit ?? 'rem';
  return `
    ${mq(0, bp.medium)} {
      ${property}: ${minAmountWithFallback}${unit};
    }
    ${mq(bp.medium, bp.large)} {
      ${property}: ${roundTwo(minAmountWithFallback + diff / 2)}${unit};
    }
    ${mq(bp.large)} {
      ${property}: ${splittedMax.value}${unit};
    }
  `;
};

export default respValue;
