import styled from 'styled-components';
import color from 'styles/base/variables/color';
import Mls from 'components/detail/Mls';
import { withSearch } from '@elastic/react-search-ui';
import {
  getCurrentlyActiveDatabase,
  getDbIndexFromUri,
} from 'utils/getDbFromFilters';
import useApiData from 'hooks/useApiData';
import { useParams, useSearchParams } from 'react-router-dom';

/**
 * Database banner component.
 *
 * @param bannerDetails
 *   The banner details.
 * @returns {JSX.Element}
 */
const StyledDatabaseBanner = styled.div`
  &:after {
    content: '';
    width: 12rem;
    height: 0.4rem;
    background-color: ${color.yellow};
    display: block;
    margin-top: 1rem;
  }
`;

function DatabaseBanner({ filters }) {
  const {
    isLoading,
    isError,
    data: [availableSearches],
  } = useApiData(['/available-searches'], [{}]);

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  let currentDatabase = getCurrentlyActiveDatabase(filters, searchParams);

  if (typeof id !== 'undefined' && currentDatabase === 'all') {
    currentDatabase = getDbIndexFromUri(id) ?? 'all';
  }

  return (
    <StyledDatabaseBanner className="database-banner">
      {!isLoading && !isError && (
        <p className="h2">
          <Mls strings={availableSearches[currentDatabase].label} />
        </p>
      )}
    </StyledDatabaseBanner>
  );
}

export default withSearch(({ filters }) => ({
  filters,
}))(DatabaseBanner);
