import BasicPage from 'components/layout/BasicPage';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet';
import BodyText from 'components/misc/BodyText';
import { getLocalizedPath } from 'utils/localizedRoutes';
import createSearchUrlQuery from 'utils/createSearchUrlQuery';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { IconSearch } from 'components/graphical/Icons';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import styled from 'styled-components';
import border from 'styles/base/variables/border';
import color from 'styles/base/variables/color';

const StyledSearchBox = styled.div`
  display: flex;

  .btn-yellow {
    margin-left: 1rem;
    padding: 1em;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

const StyledSearchExplanation = styled.div`
  dl {
    > div {
      display: flex;
      gap: 2rem;
      margin-bottom: 3rem;
      border-bottom: ${border.dimmed};
      padding-bottom: 3rem;

      &:first-child {
        margin-top: 3rem;
        padding-top: 2rem;
        border-top: ${border.dimmed};
        padding-bottom: 2rem;
      }

      &.basic {
        align-items: center;
        dt {
          font-weight: 700;
        }
      }

      &:not(.basic) {
        dt {
          padding: 2rem;
          background-color: ${color.paper};
          text-align: center;
        }
      }

      dt {
        flex: 0 0 auto;

        ${mq(bp.small)} {
          min-width: 11rem;
        }
      }

      dd {
        margin: 0;
        flex: 1 1 auto;

        > * {
          + * {
            margin-top: 2rem;
          }
        }
      }
    }
  }
`;

function SearchExplanation() {
  return (
    <>
      <Helmet>
        <title>{t`Search manual`}</title>
      </Helmet>
      <BasicPage>
        <BodyText>
          <StyledSearchExplanation>
            <h1>{t`Search manual`}</h1>
            <p>
              <Trans>
                When searching via RKD Research you can use special characters to further specify your search query. For this you can use 'Elasticsearch simple query string syntax'. Below you will find instructions on how to apply this syntax.
              </Trans>
            </p>
            <h2>
              <Trans>Operators and examples</Trans>
            </h2>
            <dl>
              <div className="basic">
                <dt>
                  <Trans>Operator</Trans>
                </dt>
                <dd>
                  <p>
                    <Trans>
                      Explanation and example
                    </Trans>
                  </p>
                </dd>
              </div>
              <div>
                <dt className="h1">AND</dt>
                <dd>
                  <p>
                    <Trans>
                      The AND operator is the default operator if no operators
                      are used. Searching for 'Jan Sluijters' or 'Jan AND
                      Sluijters' will have the same results. The results should
                      contain A AND B. This operator can be useful in complex
                      search queries.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'Jan AND Sluijters'}
                    label={t`Example search with an AND operator`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">OR</dt>
                <dd>
                  <p>
                    <Trans>
                      Search for results that include A OR B by using the OR
                      operator. For example, search for a boat or horse.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={t`Boat OR Horse`}
                    label={t`Example search with or operator`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">-</dt>
                <dd>
                  <p>
                    <Trans>
                      Search for results that do not have the specified word or
                      phrase by using the hyphen symbol. For example, search for
                      'Peter' but without 'Vos'.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'Peter -Vos'}
                    label={t`Example search with hyphen symbol`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">"</dt>
                <dd>
                  <p>
                    <Trans>By using double quotes it is possible to wrap
                      multiple terms to search for exact matches. The result
                      must contain the exact phrase 'Rembrandt van
                      Rijn'.</Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'"Rembrandt van Rijn"'}
                    label={t`Example search double quotes`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">( )</dt>
                <dd>
                  <p>
                    <Trans>
                      Signify precedence by searching with terms wrapped in
                      parenthesis. For example, search for 'Aelbert' AND either
                      'van Spithoven' or 'Cuyp'.
                      A search without parenthesis 'Aelbert van Spithoven OR
                      Cuyp' searches for either 'Aelbert van Spithoven' or
                      'Cuyp'.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'Aelbert AND ("van Spithoven" OR Cuyp)'}
                    label={t`Example search with parenthesis`}
                  ></ExampleSearch>
                  <ExampleSearch
                    searchTerm={'Aelbert AND "van Spithoven" OR Cuyp'}
                    label={t`Example search with parenthesis`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">*</dt>
                <dd>
                  <p>
                    <Trans>
                      Use the asterisk symbol in a word(part) to
                      signify a prefix search or wildcard match. For example,
                      search for Jacob with a surname prefix 'Ap'. The results
                      may include 'Jacob Appel' as well as 'Jacob Apostel'.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'Jacob Ap*'}
                    label={t`Example search with asterisk symbol`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">?</dt>
                <dd>
                  <p>
                    <Trans>
                      Use the question mark symbol in a word to
                      signify a one character wildcard match. For example,
                      search for '?lauber'. The results
                      may include 'Glauber' as well as 'Klauber'.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'?lauber'}
                    label={t`Example search with a question mark symbol`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">~N</dt>
                <dd>
                  <p>
                    <Trans>
                      Using a tilde symbol after a word followed by a positive
                      integer number, you can specify the number of character
                      edits (substitute, insert or delete) that is needed to
                      change one word to another. In other words it is the
                      distance between two words (fuzziness).
                      For example, 'saned' and 'sand' have a distance of 1. To
                      demonstrate: when searching for 'Flover~1', the results
                      include 'Flower' as well as all results from artist 'John
                      Glover'.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={t`Flover~1`}
                    label={t`Example search with tilde symbol after a single word`}
                  ></ExampleSearch>
                </dd>
              </div>
              <div>
                <dt className="h1">~N</dt>
                <dd>
                  <p>
                    <Trans>
                      Using a tilde symbol after a phrase (wrapped in “double
                      quotes”) followed by a positive integer number, you can
                      specify how many words can be moved in the query to match
                      results. This is also known as the query phrase slop.
                      For example, search for “Breen Adam van”~3 and the results
                      will include "Adam van Breen" or other word combinations
                      based on the phrase. This is useful when creating flexible
                      queries.
                    </Trans>
                  </p>
                  <ExampleSearch
                    searchTerm={'"Breen Adam van"~3'}
                    label={t`Example search with tilde symbol after a phrase`}
                  ></ExampleSearch>
                </dd>
              </div>
            </dl>
            <h2>
              <Trans>
                Using operators as literal characters
              </Trans>
            </h2>
            <p>
              <Trans>
                If you would like to use one or more of these characters
                literally, you can escape it with a preceding backslash.
              </Trans>
            </p>
            <h2>
              <Trans>
                Using multiple operators
              </Trans>
            </h2>
            <p>
              <Trans>
                You can combine operators as you like. However, some queries may not return a result. For example, searching "Aelbert van Spitho*" will find zero results since you are searching for literal matches with Aelbert van Spitho*.
                Below you will find a few examples of how you could combine multiple operators.
                Searches for Jacob Cuyp or Benjamin Cuyp and excludes Aelbert.
              </Trans>
            </p>
            <p>
              <Trans>
                Below you will find a few examples of how you could combine multiple operators:
              </Trans>
            </p>
            <p>
              <Trans>
                Searches for Jacob Cuyp or Benjamin Cuyp and excludes Aelbert:
              </Trans>
            </p>
            <p>
              <ExampleSearch
                  searchTerm={'(Jacob OR Benjamin) Cuyp -Aelbert'}
                  label={t`Example search with symbol combination`}
              ></ExampleSearch>
            </p>
            <p><Trans>(Jacob OR Benjamin) Cuyp -Aelbert is the same as (Jacob Cuyp OR Benjamin Cuyp) -Aelbert</Trans></p>
            <p><Trans>Searches for bird or cloud(s) but without results with 'Karel Appel' and 'Edward Bird':</Trans></p>
            <p>
              <ExampleSearch
                searchTerm={t`Bird OR Clouds~1 -"Karel Appel" -"Edward Bird"`}
                label={t`Example search with symbol combination`}
              ></ExampleSearch>
            </p>
          </StyledSearchExplanation>
        </BodyText>
      </BasicPage>
    </>
  );
}

const ExampleSearch = ({ label, searchTerm, filters }) => {
  const path = getLocalizedPath('/search');
  const url = `${path}${createSearchUrlQuery(searchTerm, filters)}`;

  return (
    <>
      <StyledSearchBox>
        <label htmlFor={searchTerm}>
          <VisuallyHidden>{label}</VisuallyHidden>
        </label>
        <input
          id={searchTerm}
          type="text"
          className="font-light"
          name="search-term"
          placeholder={searchTerm}
          value={searchTerm}
        />
        <a
          href={url}
          className="btn btn-yellow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <VisuallyHidden>
            <Trans>Search</Trans>
          </VisuallyHidden>
          <IconSearch />
        </a>
      </StyledSearchBox>
    </>
  );
};

export default SearchExplanation;
