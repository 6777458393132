import { t } from '@lingui/macro';
import GridContainer from 'components/layout/GridContainer';
import Hero from 'components/viewmodes/Hero';
import ListCols from 'components/layout/ListCols';
import Tile from 'components/viewmodes/Tile';
import { IconMonitor, IconPointerRight, IconSearchVisual } from 'components/graphical/Icons';
import Disc from 'components/graphical/Disc';
import useUserContext from 'hooks/useUserContext';
import size from 'styles/base/variables/size';
import { getLocalizedPath } from 'utils/localizedRoutes';
import createSearchUrlQuery from 'utils/createSearchUrlQuery';

// Images.
import scene_with_trees from 'assets/images/DPG086.jpg';
import man_reading from 'assets/images/1_A042_03.jpg';
import db_artists from 'assets/images/databases/artists.jpg';
import db_archives from 'assets/images/databases/archives.jpg';
import db_collections from 'assets/images/databases/collections.jpg';
import db_excerpts from 'assets/images/databases/excerpts.jpg';
import db_library from 'assets/images/databases/library.jpg';
import db_technical from 'assets/images/databases/technical.jpg';
import flowers from 'assets/images/DPG120_bijgesneden.jpg';
import officers from 'assets/images/Frans_Hals_Vergadering_van_officieren.jpg';
import riders from 'assets/images/1_Cuyp_ruiters.jpg';
import borssom_london from 'assets/images/1-Borssom-Londen-BM.jpg';
import dresden_recto from 'assets/images/Mondriaan_Dresden_recto.jpg';
import db_marks from 'assets/images/databases/marks.jpg';


function Home() {
  const hero_images = [
    flowers,
    riders,
    officers,
    scene_with_trees,
    dresden_recto,
  ];

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  // Check if user has correct role.
  const user = useUserContext();
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  let ddLink = isAuthorized
    ? `${process.env.REACT_APP_DD_BASE_URL}`
    : '/my-workspace';
  let ddLinkIsLocalized = isAuthorized ? false : true;

  const DataHero = [
    {
      title: 'RKD Research',
      subtitle: t`Platform for art historical research`,
      image: {
        src: hero_images[randomIntFromInterval(1, hero_images.length) - 1],
        alt: '',
      },
    },
  ];

  const DataTile = [
    {
      title: t`RKDartists`,
      image: {
        src: db_artists,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdartists'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDcollections`,
      image: {
        src: db_collections,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdcollections'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDexcerpts`,
      image: {
        src: db_excerpts,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdexcerpts'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDimages`,
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      image: {
        src: man_reading,
        alt: '',
      },
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdimages'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDimages Lite`,
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      image: {
        src: borssom_london,
        alt: '',
      },
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdimages_lite'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDlibrary`,
      image: {
        src: db_library,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdlibrary'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDtechnical`,
      image: {
        src: db_technical,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdtechnical'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`RKDarchives`,
      image: {
        src: db_archives,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['rkdarchives'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`Marks on Art`,
      image: {
        src: db_marks,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(null, [
        {
          field: 'db',
          values: ['marks'],
        },
      ])}`,
      localized: false,
    },
    {
      title: t`Visual search`,
      subtitle: t`Search the databases using an image`,
      icon: <IconSearchVisual />,
      theme: 'dark',
      href: getLocalizedPath('/visual-search'),
      localized: false,
    },
    {
      title: t`My workspace`,
      subtitle: t`Analyse, compare and edit images in my workspace`,
      icon: <IconMonitor />,
      theme: 'dark',
      href: ddLink,
      localized: ddLinkIsLocalized,
    },
    {
      title: t`Art & Architecture Thesaurus (AAT)`,
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'light',
      href: getLocalizedPath('/art-and-architecture-thesaurus'),
      localized: false,
    },
  ];

  return (
    <>
      {DataHero.map((item, i) => {
        return (
          <Hero
            key={i}
            title={item.title}
            subtitle={item.subtitle}
            image={item.image}
          />
        );
      })}
      <GridContainer>
        <ListCols cols="4" gap={size.gridGutter} className="tiles-list">
          {DataTile.map((item, i) => {
            return (
              <Tile
                key={i}
                theme={item.theme}
                title={item.title}
                subtitle={item.subtitle}
                icon={item.icon}
                image={item.image}
                href={item.href}
                localized={item.localized}
              />
            );
          })}
        </ListCols>
      </GridContainer>
    </>
  );
}

export default Home;
