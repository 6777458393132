import { MapContainer, Marker, Polyline, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { latLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapLozengeAnthracite from 'assets/svg/_map-marker/map-lozenge--anthracite.svg';
import mapTriangleYellow from 'assets/svg/_map-marker/map-triangle--yellow.svg';
import mapMarkerTeal from 'assets/svg/_map-marker/map-marker--teal-green.svg';
import Mls from "./Mls";
import styled from 'styled-components';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import { useState } from 'react';
import { IconPointerDown } from 'components/graphical/Icons';
import easing from 'styles/base/variables/easing';
import respValue from 'styles/base/helpers/respValue';

const StyledMapOuter = styled.div`
  > .heading {
    button {
      ${alignIconDefaults()};

      .icon {
        transition: transform ${easing.normal};
      }
    }
  }

  > .content {
    ${respValue('margin-top', '3rem')};
  }

  &.open {
    > .heading {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &:not(.open) {
    > .content {
      display: none;
    }
  }
`;

const StyledLegend = styled.div`
  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2em;

    div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      dt {
        img {
          max-width: 2.4rem;
          max-height: 2.8rem;
        }
      }

      dd {
        margin-left: 0.5em;
      }
    }
  }
`;

function Map({title, center, isCollapsible, collapsed, inBoundsPositions, tileInfo, markers, polylines, legend}) {
  const bounds = latLngBounds(center, center);
  inBoundsPositions.forEach((v) => {
    bounds.extend(v);
  });

  const markerImage = {
    triangle: mapTriangleYellow,
    lozenge: mapLozengeAnthracite,
    marker: mapMarkerTeal,
  };

  const customMarker = { triangle: '', lozenge: '', marker: '' };

  customMarker.triangle = L.icon({
    iconUrl: mapTriangleYellow,
    iconSize: [30, 26],
    iconAnchor: [15, 26],
    popupAnchor: [0, -51],
  });

  customMarker.lozenge = L.icon({
    iconUrl: mapLozengeAnthracite,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -51],
  });

  customMarker.marker = L.icon({
    iconUrl: mapMarkerTeal,
    iconSize: [26, 36],
    iconAnchor: [13, 36],
    popupAnchor: [0, -51],
  });

  // Toggle map.
  const [itemIsOpen, setItemIsOpen] = useState(true);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  return (
    <StyledMapOuter className={itemIsOpen ? 'open' : ''}>
      <h2 className="heading">
        <button
          className="btn-reset"
          onClick={itemHandleClick}
          aria-expanded={itemIsOpen ? 'true' : 'false'}
        >
          <span className="text">
            <Mls strings={title} />
          </span>
          <IconPointerDown />
        </button>
      </h2>
      <div className="content">
        <MapContainer
          bounds={bounds}
          center={center}
          scrollWheelZoom={false}
          style={{ width: '100%', height: '50vh', minHeight: '400px' }}
        >
          <TileLayer
            attribution={tileInfo.attribution}
            url={tileInfo.url}
          ></TileLayer>
          {markers.map((marker) => {
            let color = marker.color;
            if (Object.keys(customMarker).indexOf(color) === -1) {
              color = 'marker';
            }

            return (
              <Marker
                position={marker.position}
                icon={customMarker[color]}
              />
            );
          })}
          {polylines.map((line) => {
            return (
              <Polyline
                positions={line.positions}
                pathOptions={{ color: line.color, fill: line.fill, dashArray: line.stroke}}
              />
            );
          })}
        </MapContainer>
        {legend.length &&
          <StyledLegend>
            <dl>
              {legend.map( (v) => {
                return (
                  <div>
                    <dt>
                      <img alt="" src={markerImage[v.marker]} />
                    </dt>
                    <dd>
                      {<Mls strings={v.explanation} />}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </StyledLegend>
        }
      </div>
    </StyledMapOuter>
  );
}

export default Map;
