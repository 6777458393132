import { withSearch } from '@elastic/react-search-ui';
import styled from 'styled-components';
import { IconClose } from 'components/graphical/Icons';
import { getLocalizedValue } from 'utils/processors';
import { t } from '@lingui/macro';
import { useMemo } from 'react';

const StyledActiveSearchFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;

  .filters-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
`;

function SearchActiveFilters({
  facets,
  filters,
  removeFilter,
  dateRangeFilters,
}) {
  const facetLabels = useMemo(
    () => getFilterLabels(facets, filters),
    [facets, filters]
  );

  const isDateRangeFilter = (field) =>
    dateRangeFilters && dateRangeFilters.hasOwnProperty(field);
  const isFacetFilter = (field) => facets && facets.hasOwnProperty(field);

  const filterRemove = (field, value) => {
    removeFilter(field, value);
  };

  const filterRemoveAll = (filters) => {
    filters.forEach((filter) => {
      if (filter.field === 'db') {
        return;
      }
      removeFilter(filter.field, filters.value);
    });
  };

  const showRemoveAllFilters = () => {
    let selectedFiltersCount = 0;
    for (const filter of filters) {
      // The db filter is always added. To prevent confusion with the facet
      // options and unintentionally showing 'Remove all filters' when only one
      // filter seems to be selected, it is excluded in the selected filters
      // count.
      if (filter.field === 'db') {
        continue;
      }
      selectedFiltersCount++;
      if (filter.values.length > 1 || selectedFiltersCount > 1) {
        return true;
      }
    }

    return false;
  };

  const showFilters = () => {
    for (const filter of filters) {
      // The db filter is always added. To prevent confusion with the facet
      // options it is excluded in the selected filters count.
      if (filter.field === 'db') {
        continue;
      }
      if (filter.values.length > 0) {
        return true;
      }
    }

    return false;
  };

  return (
    <StyledActiveSearchFilters className="active-filters">
      {showFilters() && (
        <ul className="filters-list">
          {filters.map((filter) => {
            return filter.values.map((value) => {
              const field = filter.field;
              let label = '';

              if (isFacetFilter(field)) {
                label = getLocalizedValue(facetLabels[field][value]) ?? null;
              } else if (isDateRangeFilter(field)) {
                label = `${value.from}-${value.to}`;
              }
              return (
                label && (
                  <li key={value} className="active-filters__item">
                    <button
                      onClick={() => filterRemove(field, value)}
                      aria-label={t`remove filter "${label}"`}
                      className="btn btn-small btn-black btn-rounded"
                    >
                      <span className="text">{label}</span>
                      <IconClose />
                    </button>
                  </li>
                )
              );
            });
          })}
        </ul>
      )}
      {showRemoveAllFilters() && (
        <div className="filters-reset">
          <button
            onClick={() => filterRemoveAll(filters)}
            className="btn-reset"
          >
            {t`Remove all filters`}
          </button>
        </div>
      )}
    </StyledActiveSearchFilters>
  );
}

/**
 * Gets the facet labels.
 *
 * @param facets
 *   The available facets.
 * @param filters
 *   The provided filters.
 * @returns {{}}
 *   Array of filter labels.
 */
const getFilterLabels = (facets, filters) => {
  let facetLabels = {};
  for (const { field, values } of filters) {
    if (!facets.hasOwnProperty(field)) {
      continue;
    }

    values.forEach((value) => {
      if (facets[field][0]) {
        if (typeof facetLabels[field] === 'undefined') {
          facetLabels[field] = {};
        }
        const facet = facets[field][0].data.find(
          (item) => item.value === value
        );
        if (facet) {
          facetLabels[field][value] =
            typeof facet.label !== 'undefined' ? facet.label : null;
        }
      }
    });
  }
  return facetLabels;
};

export default withSearch(
  ({ facets, filters, removeFilter, dateRangeFilters }) => ({
    facets,
    filters,
    removeFilter,
    dateRangeFilters,
  })
)(SearchActiveFilters);
