import { getLocalizedLangAndValue, newLineToLineBreak } from 'utils/processors';

/**
 * Multilingual string component.
 *
 * @param strings
 *   The multilingual string array containing localeCode keys (en, nl, ...).
 * @param langCode
 *   The specified language code.
 * @returns {*|string|string}
 *   The localized value string.
 */
function Mls({ strings, langCode }) {
  const string = strings.strings || strings;
  if (string !== undefined) {
    const {
      string: translatedString,
      langCode: selectedLangcode,
      activeLanguage,
    } = getLocalizedLangAndValue(string, langCode);

    if (activeLanguage === true) {
      return <span>{newLineToLineBreak(translatedString)}</span>;
    } else {
      return (
        <span lang={selectedLangcode}>
          {newLineToLineBreak(translatedString)}
        </span>
      );
    }
  }
  return '';
}

export default Mls;
