import AdjustOrder from 'components/helpers/AdjustOrder';
import BodyText from 'components/misc/BodyText';
import getLocalizedValueFromMls from '../../utils/getLocalizedValueFromMls';

/**
 * Base details component.
 *
 * @param baseDetails
 *   The base details.
 * @returns {JSX.Element}
 */
function BaseDetails({ baseDetails }) {
  return (
    <AdjustOrder className="textual">
      <h1 className="h2 heading">
        {getLocalizedValueFromMls(baseDetails.title)}
      </h1>
      <p>{getLocalizedValueFromMls(baseDetails.subtitle)}</p>
      <p className="meta order-min">
        {getLocalizedValueFromMls(baseDetails.meta)}
      </p>
      {baseDetails.additionalContent && (
        <BodyText
          dangerouslySetInnerHTML={{
            __html: getLocalizedValueFromMls(baseDetails.additionalContent),
          }}
        ></BodyText>
      )}
    </AdjustOrder>
  );
}

export default BaseDetails;
