import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';
import { locales } from 'utils/i18n';
import { translatePath } from 'utils/localizedRoutes';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';

const StyledLanguageMenu = styled.nav`
  ul {
    ${mq(0, bp.mobileMenu)} {
      // Make first item align at the left.
      margin-left: -1rem;
    }

    li {
      ${mq(0, bp.mobileMenu)} {
        padding: 0.7rem 0;
      }

      button {
        padding: 1rem;

        // To position "border-bottom".
        position: relative;

        &:after {
          position: absolute;
          content: '';
          display: block;
          width: calc(85% - 2rem);
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          bottom: 4px;
          background-color: transparent;
          transition: background-color ${easing.normal};
        }

        &:hover {
          &:after {
            background-color: ${color.sand};
          }
        }

        &.active {
          font-weight: 700;

          &:after {
            background-color: currentColor;
          }
        }

        &[disabled] {
          opacity: 1;
        }
      }
    }
  }
`;

function MenuLanguage() {
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;
  const navigate = useNavigate();

  function switchLanguage(localeCode) {
    const href = translatePath(window.location, localeCode);
    navigate(href);
  }

  return (
    <StyledLanguageMenu className="font-smaller">
      <ul>
        {Object.keys(locales).map((localeCode) => {
          const isActive = localeCode === activeLocale;
          return (
            <li key={localeCode}>
              <button
                onClick={() => switchLanguage(localeCode)}
                className={`btn-reset ${isActive ? 'active' : ''}`}
                disabled={isActive}
                aria-label={locales[localeCode].label}
                lang={localeCode}
              >
                {locales[localeCode].abbreviation}
              </button>
            </li>
          );
        })}
      </ul>
    </StyledLanguageMenu>
  );
}

export default MenuLanguage;
