import { Facet, withSearch } from '@elastic/react-search-ui';
import { getLocalizedValue } from 'utils/processors';
import MultiCheckboxLabelFacet from 'components/search/MultiCheckboxLabelFacet';

const facetHierarchySeparator = '__';

function Facets({ facets, filters, removeFilter }) {
  const processedFacets = buildFacetHierarchyRecursive(facets);

  function buildFacetHierarchyRecursive(facets, parentId = '', depth = 0) {
    // Object to store the results.
    const hierarchicalFacets = {};

    // Iterate over the facets object to find what we're looking for.
    for (const facetId in facets) {
      // We are not interested in this facet if we are recursing and the facet ID
      // does not start with the parentId.
      if (parentId.length && !facetId.startsWith(parentId)) {
        continue;
      }

      // The currentLevelId is the part of the facet ID after the parentId
      const currentLevelId = facetId.slice(parentId.length);

      // If the currentLevelId still contains a separator, it should not be
      // rendered here. (It will be picked up later.)
      if (currentLevelId.includes(facetHierarchySeparator)) {
        continue;
      }

      // Proceed rendering this facet. First, see if we can find child facets.
      const [newParentId] = currentLevelId.split(facetHierarchySeparator, 2);
      const recursionParentId = parentId
        ? `${parentId}${newParentId}${facetHierarchySeparator}`
        : `${newParentId}${facetHierarchySeparator}`;
      const childFacets = buildFacetHierarchyRecursive(
        facets,
        recursionParentId,
        depth + 1
      );

      const facet = facets[facetId][0];

      // Override the default onRemove handler.
      const onRemove = (value) => {
        // If this facet has children, find descendant filters and remove them.
        Object.keys(childFacets).length &&
          filters.forEach((filter) => {
            filter.field.startsWith(
              `${facet.field}${facetHierarchySeparator}`
            ) && removeFilter(filter.field);
          });
        // Default onRemove behavior.
        removeFilter(facet.field, value, 'all');
      };

      hierarchicalFacets[currentLevelId] = (
        <Facet
          key={facetId}
          field={facet.field}
          label={getLocalizedValue(facet.label)}
          view={MultiCheckboxLabelFacet}
          childFacets={childFacets}
          depth={depth}
          onRemove={onRemove}
          facetParentId={facetId}
        />
      );
    }
    return hierarchicalFacets;
  }

  return (
    <div className="facets-container">
      {Object.keys(processedFacets).map((id) => {
        return processedFacets[id];
      })}
    </div>
  );
}

export default withSearch(({ facets, filters, removeFilter }) => ({
  facets,
  filters,
  removeFilter,
}))(Facets);
