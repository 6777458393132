import { useEffect, useState } from 'react';

/**
 * Fetches data from one or more queries.
 *
 * Queries are passed as paths to the API (/api) endpoint and executed in
 * parallel.
 *
 * @param {(string[]|{path: string, params: string[]}[])} queries
 *   An array of queries. Each query is either a string (the query path)
 *   or an object containing the path and body.
 * @param {Object[]} [initialData]
 *   Initial state of the data objects. When omitted defaults to an array of
 *   empty objects, one for each query passed in the queries param.
 *
 * @returns {{isLoading: boolean, isError: boolean, data: Object[]}}
 *   Object holding loading and error state and data, an array of response data
 *   from state.
 */
const useApiWithBodyContent = (queries, initialData) => {
  const [data, setData] = useState(
    initialData ?? Array(queries.length).fill({})
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const uri = '/api';
  const queriesJsonString = JSON.stringify(queries);
  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      isActive && setIsLoading(true);
      isActive && setIsError(false);
      try {
        const responses = await Promise.all(
          queries.map((query) => {
            if (typeof query === 'object' && query !== null) {
              const path = query.path ?? '';
              const body = query.body;
              return fetchResponseJson(`${uri}${path}`, body);
            } else {
              return fetchResponseJson(`${uri}${query}`, '');
            }
          })
        );

        isActive && setData(responses);
      } catch (error) {
        isActive && setData(initialData);
        isActive && setIsError(true);
      }
      isActive && setIsLoading(false);
    };
    // Deliberately ignore the promise. Its setData side-effect is the goal.
    fetchData();
    // Update flag to prevent async actions from settings state on an unmounted
    // component and delayed requests from overwriting later requests.
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, queriesJsonString]);
  return { data, isLoading, isError };
};

export default useApiWithBodyContent;

/**
 * Fetches json from a url.
 *
 * Helper for useSpinqueData.
 *
 * @param {string} uri
 *   Uri to fetch.
 *
 * @param {any} content
 *   Content to stringify as JSON.
 *
 * @returns {Promise<any>}
 */
async function fetchResponseJson(uri, content) {
  const result = await fetch(uri, {
    method: 'POST',
    body: JSON.stringify(content),
  });
  return await result.json();
}
