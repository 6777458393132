import styled from 'styled-components';
import GridContainer from 'components/layout/GridContainer';
import SpanGridCols from 'components/layout/SpanGridCols';
import { mq, bp } from 'styles/base/variables/mediaQueries';

const StyledBasicPage = styled.div`
  .content--main {
    ${mq(bp.large)} {
      width: ${SpanGridCols(9)};
      margin-left: auto;
      margin-right: auto;
    }

    ${mq(bp.extraLarge)} {
      width: ${SpanGridCols(6)};
    }
  }
`;

function BasicPage({ children }) {
  return (
    <StyledBasicPage>
      <GridContainer>
        <div className="content--main">{children}</div>
      </GridContainer>
    </StyledBasicPage>
  );
}

export default BasicPage;
