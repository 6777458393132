import color from './color';

const border = {
  active: `4px solid ${color.yellow}`,
  activeWidth: `4px`,
  default: `1px solid ${color.anthracite}`,
  defaultWidth: `4px`,
  dimmed: `1px solid ${color.sand}`,
  dimmedWidth: `1px`,
};

export default border;
