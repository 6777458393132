import { useState } from 'react';
import styled from 'styled-components';
import GridContainer from 'components/layout/GridContainer';
import MenuSnackBar from 'components/navigation/MenuSnackBar';
import Selected from 'components/viewmodes/Selected';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import size from 'styles/base/variables/size';
import { IconPointerDown, IconTrash } from 'components/graphical/Icons';
import { useLocation } from 'react-router-dom';
import { isFullWidthRoute } from 'utils/fullWidthRoutes';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import SpanGridCols from 'components/layout/SpanGridCols';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import useSnackBarContext from 'hooks/useSnackBarContext';
import { Trans } from '@lingui/macro';
import Pipe from 'components/graphical/Pipe';
import CheckboxAsButton from 'components/graphical/CheckboxAsButton';

const StyledSnackBar = styled.div`
  box-shadow: 0 30rem 8rem 10rem ${color.mineshaftDimmed50};
  transition: all ${easing.slow};

  &.open {
    box-shadow: 0 12rem 8rem 10rem ${color.mineshaftDimmed50};
  }

  .bar {
    background-color: ${color.anthracite};
    color: ${color.white};
    padding: 1.5rem 0rem;

    .sub-menu {
      color: ${color.anthracite};
    }
  }

  .bar--inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &:not(.open) {
    > .content--outer {
      max-height: 0;

      > .content {
        display: none;
      }
    }
  }

  > .content--outer {
    transition: max-height ${easing.slow};
    overflow: hidden;
    max-height: 32rem;

    > .content {
      background-color: ${color.paper};
      padding-top: 2rem;

      > * {
        + * {
          margin-top: 2rem;
        }
      }

      .content--row--manage-overflow {
        overflow: auto;
      }

      .content--row--indent {
        ${mq(bp.extraLarge)} {
          padding-left: calc(${SpanGridCols(3)} + ${size.gridGutter});
        }
      }

      .content--actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;

        button {
          ${alignIconDefaults()};
        }

        .btn-select {
          .square {
            width: 2.4rem;
          }

          &:not(.active) {
            &.some-active {
              .square {
                background-color: ${color.yellow};
                border-color: transparent;

                &:before {
                  position: absolute;
                  content: '';
                  width: 1.2rem;
                  height: 0.2rem;
                  background-color: currentColor;
                }
              }
            }
          }
        }
      }

      .content--items {
        padding-bottom: 3rem;
        display: flex;
        gap: 1rem;

        > * {
          &:last-child {
            padding-right: ${size.pagePadding};
          }
        }
      }
    }
  }

  .snackbar-content-toggler {
    > *:not(:last-child) {
      margin-right: 2em;
    }

    > .icon {
      transition: transform ${easing.normal};
    }

    > .label {
      ${alignIconDefaults()};

      .disc {
        width: 2.1rem;
        background-color: ${color.yellow};
        color: ${color.anthracite};
      }
    }

    &.open {
      > .icon {
        transform: rotate(180deg);
      }
    }
  }
`;

function SnackBar() {
  const snackBar = useSnackBarContext();

  const [snackBarIsOpen, setSnackBarIsOpen] = useState(snackBar.isOpen);

  const handleToggle = () => {
    setSnackBarIsOpen(!snackBarIsOpen);
    snackBar.setIsOpen(!snackBarIsOpen);
  };

  let allItemsAreSelected = snackBar.selectedItemCount === snackBar.itemCount;
  let someItemsAreSelected =
    snackBar.hasSelectedItems() &&
    snackBar.selectedItemCount !== snackBar.itemCount;

  return (
    <StyledSnackBar className={snackBarIsOpen ? 'open' : null}>
      <div className="content--outer">
        <div className="content">
          <GridContainer full={isFullWidthRoute(useLocation())}>
            <div className="content--row--indent">
              <div className="content--actions">
                <CheckboxAsButton
                  buttonOnClick={() =>
                    snackBar.setSelectAll(!allItemsAreSelected)
                  }
                  buttonClassName={`btn-select btn-reset font-smaller font-normal ${
                    allItemsAreSelected ? 'active' : ''
                  } ${someItemsAreSelected ? 'some-active' : ''}`}
                >
                  <span className="text">
                    {allItemsAreSelected ? (
                      <Trans>Deselect all</Trans>
                    ) : (
                      <Trans>Select all</Trans>
                    )}
                  </span>
                </CheckboxAsButton>
                {snackBar.hasSelectedItems() && (
                  <>
                    <Pipe />
                    <button
                      className="btn-reset font-smaller font-normal"
                      onClick={() => snackBar.clearSelectedItems()}
                    >
                      <IconTrash />
                      <span className="text">
                        <Trans>Remove selected</Trans>
                      </span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </GridContainer>
          <div className="content--row--manage-overflow">
            <GridContainer full={isFullWidthRoute(useLocation())}>
              <div className="content--row--indent">
                <div className="content--items">
                  {Object.keys(snackBar.items).map((id) => {
                    return (
                      <Selected
                        id={id}
                        key={id}
                        isSelectedSnackBarItem={snackBar.isSelectedSnackBarItem}
                        selectSnackBarItem={snackBar.toggleSelectItem}
                        removeSnackBarItem={snackBar.removeItem}
                      />
                    );
                  })}
                </div>
              </div>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className="bar">
        <GridContainer full={isFullWidthRoute(useLocation())}>
          <div className="bar--inner">
            <button
              className={`btn-reset btn snackbar-content-toggler ${
                snackBarIsOpen ? 'open' : ''
              }`}
              onClick={handleToggle}
              aria-expanded={snackBarIsOpen ? 'true' : 'false'}
            >
              <IconPointerDown />
              <span className="label">
                <span className="text">
                  {snackBar.itemCount} <Trans>items</Trans>
                </span>
              </span>
            </button>
            <MenuSnackBar />
          </div>
        </GridContainer>
      </div>
    </StyledSnackBar>
  );
}

export default SnackBar;
