import { Trans } from '@lingui/macro';

function Error({ title, message }) {
  return (
    <>
      <h2>{title ?? <Trans>An error has occurred</Trans>}</h2>
      {message && <p>{message}</p>}
    </>
  );
}

export default Error;
