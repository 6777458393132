import styled from 'styled-components';
import color from 'styles/base/variables/color';
import Section from 'components/detail/Section';
import RowList from 'components/detail/RowList';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import size from 'styles/base/variables/size';
import border from 'styles/base/variables/border';
import Disc from 'components/graphical/Disc';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import useApiData from 'hooks/useApiData';
import { useParams } from 'react-router-dom';
import Loading from 'components/misc/Loading';
import { t, Trans } from '@lingui/macro';
import { useRef, useState } from 'react';
import useUserContext from 'hooks/useUserContext';
import { IconAlert, IconPencil, IconTrash } from 'components/graphical/Icons';
import Checkbox from 'components/graphical/Checkbox';
import { VisuallyHiddenStyling } from 'components/helpers/VisuallyHidden';
import LoginLink from 'components/misc/LoginLink';

const StyledPublicComments = styled.div`
  .comment-body {
    &:not([contenteditable='false']) {
      padding: 3rem;
      box-shadow: inset 0 0 1.5rem ${color.blackDimmed40};
      background-color: ${color.anthracite};
      color: ${color.white};
      outline: none;
    }
  }

  .edit-actions {
    background-color: ${color.paper};
    padding: 1.5rem 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .comment-actions {
    margin-top: 1rem;
    border-top: ${border.dimmed};
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;

    button {
      ${alignIconDefaults(`${10 / 16}em`)};

      .disc {
        background-color: ${color.paper};
      }
    }
  }

  .comment-message {
    ${alignIconDefaults(`${10 / 16}em`)};
  }

  .comment-container {
    margin-top: 3rem;
    border-top: ${border.dimmed};
    padding-top: 2rem;
  }
`;

const StyledCommentRow = styled.div`
  // Based on StyledRow from Row.js.
  ${mq(bp.small)} {
    display: flex;
    flex-wrap: wrap;
  }

  > dt {
    ${mq(0, bp.small)} {
      margin-bottom: 0.35em;
    }

    ${mq(bp.small)} {
      flex: 0 0 auto;
      width: 30%;
    }
  }

  > dd {
    margin: 0;

    ${mq(bp.small)} {
      flex: 1 1 auto;
      width: 70%;
      padding-left: ${size.gridGutter};
    }
  }
`;

const StyledCommentHideAuthorCheckbox = styled.div`
  input[type='checkbox'] {
    ${VisuallyHiddenStyling};
  }

  label[for] {
    ${alignIconDefaults(`${11 / 16}em`)};
  }
`;

const StyledCommentAlertMessage = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: baseline;
  color: ${color.torchRed};

  .icon {
    position: relative;
    top: 0.2em;
  }
`;

function CommentHideAuthorCheckbox({
  commentUpdateHideAuthorRef,
  isAuthorHidden = false,
}) {
  return (
    <StyledCommentHideAuthorCheckbox>
      <input
        type="checkbox"
        name={'hide-author'}
        id={'hide-author'}
        ref={commentUpdateHideAuthorRef}
        defaultChecked={isAuthorHidden}
      />
      <label htmlFor={'hide-author'}>
        <Checkbox ownClassName="check" />
        <span className="text font-normal">
          <Trans>Hide author in comment.</Trans>
        </span>
      </label>
    </StyledCommentHideAuthorCheckbox>
  );
}

function CommentRow({
  author,
  date,
  commentText,
  isOwnContent,
  commentId,
  handleOnCommentChangeSubmit,
  handleOnCommentRemove,
  minCommentLength,
  authorHidden,
}) {
  const [commentEditIsOpen, setCommentEditIsOpen] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isPromptToRemove, setIsPromptToRemove] = useState(false);
  const commentRef = useRef(null);
  const commentEditButtonRef = useRef(null);
  const commentRemoveButtonRef = useRef(null);
  const commentUpdateHideAuthorRef = useRef(null);

  const handleOpenEditClick = () => {
    setCommentEditIsOpen(true);
    setSubmissionMessage('');
    setIsPromptToRemove(false);

    // Set focus.
    window.requestAnimationFrame(function () {
      commentRef.current.focus();
    });
  };

  // Close edit on esc-key.
  const editHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      editHandleClick();
    }
    setIsPromptToRemove(false);
  };

  const handleOnSave = () => {
    setIsPromptToRemove(false);
    setIsUpdating(true);

    const commentAuthorVisibilityChanged =
      commentUpdateHideAuthorRef.current.checked !== authorHidden;

    if (
      commentText === commentRef.current.textContent &&
      !commentAuthorVisibilityChanged
    ) {
      const message = t`Please modify your addition/comment and click save.`;
      setSubmissionMessage(message);
      setIsUpdating(false);
      setCommentEditIsOpen(false);

      return;
    }

    if (commentRef.current.textContent.length < minCommentLength) {
      const message = t`Please enter at least 10 characters.`;
      setSubmissionMessage(message);
      setIsUpdating(false);
      setCommentEditIsOpen(false);

      return;
    }

    const commentChangeSubmitMessage = handleOnCommentChangeSubmit(
      commentId,
      commentRef.current.textContent,
      commentUpdateHideAuthorRef.current.checked
    );

    if (commentChangeSubmitMessage['status'] === 'updated') {
      window.requestAnimationFrame(function () {
        commentEditButtonRef.current.focus();
      });
      setIsUpdating(false);
    } else if (commentChangeSubmitMessage['status'] === 'error') {
      window.requestAnimationFrame(function () {
        commentEditButtonRef.current.focus();

        // Restore original content.
        commentRef.current.textContent = commentText;
        setSubmissionMessage(commentChangeSubmitMessage['message']);
      });
      setIsUpdating(false);
    }
    setCommentEditIsOpen(false);
  };

  const removeHandleClick = () => {
    setIsPromptToRemove(true);
  };

  const handleOnRemove = () => {
    setIsUpdating(true);
    handleOnCommentRemove(commentId);
    setCommentEditIsOpen(false);
    setIsPromptToRemove(false);
  };

  const editHandleClick = () => {
    window.requestAnimationFrame(function () {
      commentEditButtonRef.current.focus();

      // Restore original content.
      commentRef.current.textContent = commentText;
    });
    setCommentEditIsOpen(false);
    setIsPromptToRemove(false);
    setIsUpdating(false);
  };

  return (
    <StyledCommentRow>
      <dt>
        <div className="font-thick">{author}</div>
        <div className="font-smaller">{date}</div>
      </dt>
      <dd>
        {isUpdating ? (
          <Loading spinnerSize="2rem" />
        ) : (
          <div
            className="comment-body"
            contentEditable={commentEditIsOpen ? '' : false}
            ref={commentRef}
            onKeyDown={editHandleKeyDown}
            data-placeholder={commentText}
          >
            {commentText}
          </div>
        )}
        {submissionMessage !== '' && (
          <div className="comment-message">
            <IconAlert />
            <span>{submissionMessage}</span>
          </div>
        )}
        {commentEditIsOpen && (
          <div className="edit-actions">
            <CommentHideAuthorCheckbox
              commentUpdateHideAuthorRef={commentUpdateHideAuthorRef}
              isAuthorHidden={authorHidden}
            />
            {isPromptToRemove ? (
              <button
                className="btn btn-yellow btn-small"
                onClick={handleOnRemove}
                disabled={isUpdating}
              >
                <Trans>Remove</Trans>
              </button>
            ) : (
              <button
                className="btn btn-yellow btn-small"
                onClick={handleOnSave}
                disabled={isUpdating}
              >
                <Trans>Save</Trans>
              </button>
            )}
            <button
              className="btn btn-ghost-black btn-small"
              onClick={editHandleClick}
            >
              <Trans>Cancel</Trans>
            </button>
          </div>
        )}
        {isOwnContent && (
          <div className="comment-actions">
            <button
              className="btn-reset"
              onClick={handleOpenEditClick}
              ref={commentEditButtonRef}
              disabled={isUpdating}
            >
              <Disc className="disc">
                <IconPencil />
              </Disc>
              <span className="text font-thick font-smaller">
                <Trans>Edit comment</Trans>
              </span>
            </button>
            {commentEditIsOpen && (
              <button
                className="btn-reset"
                onClick={removeHandleClick}
                ref={commentRemoveButtonRef}
                disabled={isUpdating}
              >
                <Disc className="disc">
                  <IconTrash />
                </Disc>
                <span className="text font-thick font-smaller">
                  <Trans>Remove comment</Trans>
                </span>
              </button>
            )}
            {isPromptToRemove && (
              <div className="comment-message">
                <IconAlert />
                <Trans>
                  Do you really want to remove this addition/comment?
                </Trans>
              </div>
            )}
          </div>
        )}
      </dd>
    </StyledCommentRow>
  );
}

function CommentAlertMessage({ message }) {
  return (
    <StyledCommentAlertMessage>
      <IconAlert />
      {message}
    </StyledCommentAlertMessage>
  );
}

function PublicComments() {
  const user = useUserContext();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [doRefresh, setDoRefresh] = useState(false);
  const commentRef = useRef(null);
  const commentHideAuthorRef = useRef(null);
  const minCommentLength = 10;
  const {
    isLoading,
    isError,
    data: [comments],
  } = useApiData(
    [
      {
        path: `/comments`,
        params: [`id=${encodeURIComponent(id)}`, `u=${doRefresh}`],
      },
    ],
    [[]]
  );

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const comment = commentRef.current.value;
    if (comment.length < minCommentLength) {
      const message = t`Enter a comment with a minimum length of ${minCommentLength} characters.`;
      commentRef.current.focus();
      setSubmissionMessage(message);

      return;
    }

    createComment(
      commentRef.current.value,
      commentHideAuthorRef.current.checked
    )
      .then(() => {
        commentRef.current.value = '';
        setIsSubmitting(false);
        setDoRefresh((prevState) => {
          return !prevState;
        });
      })
      .catch(() => {
        setIsSubmitting(false);
        const message = t`Error the comment could not be posted. Try again later.`;
        setSubmissionMessage(message);
      });
  };

  const handleOnCommentChangeSubmit = (commentId, commentText, hideAuthor) => {
    let status = [];
    updateComment(commentId, commentText, hideAuthor)
      .then(() => {
        commentRef.current.value = '';
        setIsSubmitting(false);
        setDoRefresh((prevState) => {
          return !prevState;
        });
        status['status'] = 'updated';
        setSubmissionMessage('');
      })
      .catch(() => {
        setIsSubmitting(false);
        status['status'] = 'error';
        status[
          'message'
        ] = t`Error the comment could not be updated. Try again later.`;
      });

    return status;
  };

  const handleOnCommentRemove = (commentId) => {
    let status = [];
    removeComment(commentId)
      .then(() => {
        commentRef.current.value = '';
        setIsSubmitting(false);
        setDoRefresh((prevState) => {
          return !prevState;
        });
      })
      .catch(() => {
        setIsSubmitting(false);
        status['status'] = 'error';
        status[
          'message'
        ] = t`Error the comment could not be removed. Try again later.`;
      });

    return status;
  };

  const handleOnKeyUp = (e) => {
    setSubmissionMessage('');
  };

  const getDisplayTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);

    let displayTimeString = '';
    if (hours > 0) {
      displayTimeString += t`${hours} hours`;
    }
    if (minutes > 0) {
      displayTimeString +=
        (displayTimeString === '' ? '' : ' ') + t`${minutes} minutes`;
    }
    if (sec > 0) {
      displayTimeString +=
        (displayTimeString === '' ? '' : ' ') + t`${sec} seconds`;
    }

    return displayTimeString;
  };

  const responseMessage = async (response, type) => {
    let json;
    try {
      json = await response.json();
    } catch (error) {
      // Nothing to do here, certain responses won't have json.
    }

    let message = '';
    if (response.status >= 200 && response.status < 300) {
      if (!json?.success) {
        switch (json.reason) {
          case 'rate limit exceeded':
            message = t`Comment rate limit exceeded, try again after ${getDisplayTime(
              json.retry_after
            )}.`;
            break;
          default:
            message = t`There was a problem posting/updating the comment. Please try again later.`;
            break;
        }

        setSubmissionMessage(message);
      }
    } else {
      switch (type) {
        case 'create':
          message = t`Error the comment could not be posted. Try again later.`;
          break;
        case 'update':
          message = t`Error the comment could not be updated. Try again later.`;
          break;
        default:
          message = t`There was a problem posting/updating the comment. Please try again later.`;
          break;
      }
      setSubmissionMessage(message);
    }
  };

  const createComment = async (comment, hideAuthor = false) => {
    setIsSubmitting(true);

    try {
      const response = await fetch(
        `/api/comment/add?id=${encodeURIComponent(id)}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            csrf_token: user.csrf_token,
            author: user.uid,
            comment: comment,
            hide_author: hideAuthor,
          }),
        }
      );

      await responseMessage(response, 'create');
    } catch {
      setIsSubmitting(false);
    }
  };

  const updateComment = async (
    currentCommentId,
    updatedComment,
    hideAuthor = false
  ) => {
    try {
      const response = await fetch(
        `/api/comment/update?id=${encodeURIComponent(id)}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            csrf_token: user.csrf_token,
            author: user.uid,
            comment: updatedComment,
            commentId: currentCommentId,
            hide_author: hideAuthor,
          }),
        }
      );

      await responseMessage(response, 'update');
    } catch {
      setIsSubmitting(false);
    }
  };

  const removeComment = async (currentCommentId) => {
    try {
      await fetch(`/api/comment/hide?id=${encodeURIComponent(id)}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          csrf_token: user.csrf_token,
          author: user.uid,
          commentId: currentCommentId,
        }),
      });
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledPublicComments>
      <Section label={PublicCommentsSectionLabel}>
        <RowList>
          {submissionMessage && (
            <CommentAlertMessage message={submissionMessage} />
          )}
          {isLoading && <Loading />}
          {isError && (
            <span>
              <Trans>No additions/comments could be loaded.</Trans>
            </span>
          )}
          {!isLoading && !isError && comments.length <= 0 && (
            <span>
              <Trans>No additions/comments are posted.</Trans>
            </span>
          )}
          {!isLoading &&
            !isError &&
            comments.length > 0 &&
            comments.map((comment, index) => {
              return (
                <CommentRow
                  key={index}
                  author={comment.author}
                  date={comment.date}
                  commentText={comment.text}
                  isOwnContent={comment.isOwnContent ?? false}
                  commentId={comment.commentId}
                  handleOnCommentChangeSubmit={handleOnCommentChangeSubmit}
                  handleOnCommentRemove={handleOnCommentRemove}
                  minCommentLength={minCommentLength}
                  authorHidden={comment.authorHidden}
                />
              );
            })}
        </RowList>
        <div className="comment-container">
          {user.authenticated ? (
            <form onSubmit={handleOnSubmit}>
              <label htmlFor="public-comment">
                <Trans>Enter additions or comments:</Trans>
              </label>
              <textarea
                id="public-comment"
                placeholder={t`Enter additions or comments...`}
                maxLength="1000"
                minLength={minCommentLength}
                ref={commentRef}
                disabled={isSubmitting || isLoading}
                onKeyUp={handleOnKeyUp}
              ></textarea>
              {submissionMessage && (
                <CommentAlertMessage message={submissionMessage} />
              )}
              <CommentHideAuthorCheckbox
                commentUpdateHideAuthorRef={commentHideAuthorRef}
              />
              <div className="form-actions">
                <button
                  className="btn btn-yellow"
                  disabled={isSubmitting || isLoading}
                >
                  <Trans>Save</Trans>
                </button>
              </div>
              {isSubmitting && <Loading spinnerSize="2rem" />}
            </form>
          ) : (
            <div>
              <p>
                <Trans>Login to post additions or comments.</Trans>
              </p>
              <div className="form-actions">
                <LoginLink className="btn btn-yellow">
                  <Trans>Login</Trans>
                </LoginLink>
              </div>
            </div>
          )}
        </div>
      </Section>
    </StyledPublicComments>
  );
}

const PublicCommentsSectionLabel = {
  type: 'mls',
  strings: {
    en: 'Additions/comments from visitors',
    nl: 'Aanvullingen/opmerkingen van bezoekers',
  },
};

export { PublicCommentsSectionLabel, PublicComments };
