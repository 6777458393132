import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import BasicPage from 'components/layout/BasicPage';
import BodyText from 'components/misc/BodyText';
import ListCols from 'components/layout/ListCols';

// Images.
import addToExistingSelectionEN from 'assets/images/manual/en/add-to-existing-selection.jpg';
import addToExistingSelectionNL from 'assets/images/manual/nl/add-to-existing-selection.jpg';
import addToNewSelectionEN from 'assets/images/manual/en/add-to-new-selection.jpg';
import addToNewSelectionNL from 'assets/images/manual/nl/add-to-new-selection.jpg';
import addToSelectionConfirmationEN from 'assets/images/manual/en/add-to-selection-confirmation.jpg';
import addToSelectionConfirmationNL from 'assets/images/manual/nl/add-to-selection-confirmation.jpg';
import databasesSearchEN from 'assets/images/manual/en/databases-search.jpg';
import databasesSearchNL from 'assets/images/manual/nl/databases-search.jpg';
import databasesTilesEN from 'assets/images/manual/en/databases-tiles.jpg';
import databasesTilesNL from 'assets/images/manual/nl/databases-tiles.jpg';
import detailPageEN from 'assets/images/manual/en/detail-page.jpg';
import detailPageNL from 'assets/images/manual/nl/detail-page.jpg';
import detailSelectEN from 'assets/images/manual/en/detail-select.jpg';
import detailSelectNL from 'assets/images/manual/nl/detail-select.jpg';
import filterAppliedEN from 'assets/images/manual/en/filter-applied.jpg';
import filterAppliedNL from 'assets/images/manual/nl/filter-applied.jpg';
import filterSearchEN from 'assets/images/manual/en/filter-search.jpg';
import filterSearchNL from 'assets/images/manual/nl/filter-search.jpg';
import filterEN from 'assets/images/manual/en/filter.jpg';
import filterNL from 'assets/images/manual/nl/filter.jpg';
import imagesCopyrightDownloadEN from 'assets/images/manual/en/images-copyright--download.jpg';
import imagesCopyrightDownloadNL from 'assets/images/manual/nl/images-copyright--download.jpg';
import imagesCopyrightNoDownloadEN from 'assets/images/manual/en/images-copyright--no-download.jpg';
import imagesCopyrightNoDownloadNL from 'assets/images/manual/nl/images-copyright--no-download.jpg';
import loginFormEN from 'assets/images/manual/en/login-form.jpg';
import loginFormNL from 'assets/images/manual/nl/login-form.jpg';
import loginLinkEN from 'assets/images/manual/en/login-link.jpg';
import loginLinkNL from 'assets/images/manual/nl/login-link.jpg';
import mySelectionsLinkEN from 'assets/images/manual/en/my-selections-link.jpg';
import mySelectionsLinkNL from 'assets/images/manual/nl/my-selections-link.jpg';
import mySelectionsTemporaryEN from 'assets/images/manual/en/my-selections-temporary.jpg';
import mySelectionsTemporaryNL from 'assets/images/manual/nl/my-selections-temporary.jpg';
import mySelectionsEN from 'assets/images/manual/en/my-selections.jpg';
import mySelectionsNL from 'assets/images/manual/nl/my-selections.jpg';
import myWorkspaceEN from 'assets/images/manual/en/my-workspace.jpg';
import myWorkspaceNL from 'assets/images/manual/nl/my-workspace.jpg';
import saveSelectionEN from 'assets/images/manual/en/save-selection.jpg';
import saveSelectionNL from 'assets/images/manual/nl/save-selection.jpg';
import visualSearchEN from 'assets/images/manual/en/visual-search.jpg';
import visualSearchNL from 'assets/images/manual/nl/visual-search.jpg';

function ManualRkdResearch() {
  // Use correct localized images.
  let addToExistingSelection = addToExistingSelectionEN;
  let addToNewSelection = addToNewSelectionEN;
  let addToSelectionConfirmation = addToSelectionConfirmationEN;
  let databasesSearch = databasesSearchEN;
  let databasesTiles = databasesTilesEN;
  let detailPage = detailPageEN;
  let detailSelect = detailSelectEN;
  let filterApplied = filterAppliedEN;
  let filterSearch = filterSearchEN;
  let filter = filterEN;
  let imagesCopyrightDownload = imagesCopyrightDownloadEN;
  let imagesCopyrightNoDownload = imagesCopyrightNoDownloadEN;
  let loginForm = loginFormEN;
  let loginLink = loginLinkEN;
  let mySelectionsLink = mySelectionsLinkEN;
  let mySelectionsTemporary = mySelectionsTemporaryEN;
  let mySelections = mySelectionsEN;
  let myWorkspace = myWorkspaceEN;
  let saveSelection = saveSelectionEN;
  let visualSearch = visualSearchEN;

  if (i18n.locale === 'nl') {
    addToExistingSelection = addToExistingSelectionNL;
    addToNewSelection = addToNewSelectionNL;
    addToSelectionConfirmation = addToSelectionConfirmationNL;
    databasesSearch = databasesSearchNL;
    databasesTiles = databasesTilesNL;
    detailPage = detailPageNL;
    detailSelect = detailSelectNL;
    filterApplied = filterAppliedNL;
    filterSearch = filterSearchNL;
    filter = filterNL;
    imagesCopyrightDownload = imagesCopyrightDownloadNL;
    imagesCopyrightNoDownload = imagesCopyrightNoDownloadNL;
    loginForm = loginFormNL;
    loginLink = loginLinkNL;
    mySelectionsLink = mySelectionsLinkNL;
    mySelectionsTemporary = mySelectionsTemporaryNL;
    mySelections = mySelectionsNL;
    myWorkspace = myWorkspaceNL;
    saveSelection = saveSelectionNL;
    visualSearch = visualSearchNL;
  }

  // Use correct localized urls.
  let searchInstructionHref = "https://research.rkd.nl/en/search-manual";
  let researchSubscriptionsHref = "https://webshop.rkd.nl/lidmaatschappen?___store=EN";

  if (i18n.locale === 'nl') {
    searchInstructionHref = "https://research.rkd.nl/nl/zoekinstructie";
    researchSubscriptionsHref = "https://webshop.rkd.nl/lidmaatschappen?___store=NL";
  }

  return (
    <>
      <Helmet>
        <title>{t`Manual RKD Research`}</title>
      </Helmet>
      <BasicPage>
        <BodyText>
          <h1>{t`Manual RKD Research`}</h1>
          <p>
            <Trans>
              This manual provides an explanation of how to use RKD Research. Are you missing a topic? We appreciate it if you pass it on, please send an email to <a href="mailto:info@RKD.nl">info@RKD.nl</a>.
            </Trans>
          </p>
          <p>
            <Trans>
              Want to stay informed about developments at RKD Research? Then <a href="https://mailchi.mp/rkd/newsletter" target="_blank" rel="noopener noreferrer">sign up</a> now for the newsletter.
            </Trans>
          </p>

          {/* Databases */}
          <details>
            <summary><h2>{t`Databases`}</h2></summary>
            <h3>{t`Various databases`}</h3>
            <p>
              <Trans>
                Through RKD Research you can search eight online databases containing over six million digitized documents and images: RKDartists, RKDimages, RKDimages Lite, RKDtechnical, RKDlibrary, RKDcollections, RKDexcerpts and RKDarchives. At the bottom of the homepage of <a href="https://research.rkd.nl" target="_blank" rel="noopener noreferrer">https://research.rkd.nl</a> you will find links to the available databases. Behind your search you can specify which database(s) you want to search in.
              </Trans>
            </p>
            <img src={databasesSearch} alt="" width="975" height="187" />
            <h3>{t`Difference between RKDimages and RKDimages Lite`}</h3>
            <p>{t`In RKDimages you will find images of works of art with validated information. You know that an RKD person has looked at it. RKDimages Lite contains raw research data, the digital version of the image documentation. Another difference is that an RKDimages Lite record has less data and metadata than an RKDimages record. A work of art has only one RKDimages record, whereas in RKDimages Lite there may be multiple records.`}</p>
            <h3>{t`Search`}</h3>
            <p>
              <Trans>
                It is possible to perform advanced searches using search operators such as + - | and so on. An explanation can be found on the <a href={searchInstructionHref} target="_blank" rel="noopener noreferrer">search instruction</a> page.
              </Trans>
            </p>
            <img src={databasesTiles} alt="" width="1566" height="457" />
          </details>

          {/* Filter */}
          <details>
            <summary><h2>{t`Filter`}</h2></summary>
            <p>{t`On the left side of your search result you will see a number of filter options. These filter options may vary from database to database. The filter options are closed by default. Click on a filter to unfold it. The options with the most results are at the top. You can display more options by clicking '+ More'. Next, select a filter. In the image, you can see that the 'material/technology' filter is unfolded and 'crops in gray' is selected. The search result is automatically updated and the active filter 'washed in gray' is shown under the number of results.`}</p>
            <img src={filter} alt="" width="299" height="622" />
            <p>{t`'grey wash' appears as an active filter beneath the search results.`}</p>
            <img src={filterApplied} alt="" width="226" height="204" />
            <p>{t`The option 'washed in gray' is selected.`}</p>
            <h3>{t`Searching within a filter`}</h3>
            <p>{t`Looking for a particular filter? You can search the options within a filter using a search bar in the filter. In the image, we search for 'ink'. The desired option 'washed ink' is found. Then you can select the filter by clicking the yellow button 'Add filter'. Note that the filter is not active until you add the filter.`}</p>
            <p>{t`Within the 'Material/Technique' filter, search for 'ink'. Check the desired option and click Add Filter to make the filter active.`}</p>
            <img src={filterSearch} alt="" width="300" height="863" />
          </details>

          {/* Detail page  */}
          <details>
            <summary><h2>{t`Detail page `}</h2></summary>
            <p>{t`On the detail page, on the left side you will find all available information about the record and a summary of the data.`}</p>
            <p>{t`Below that you will see a fold-out table of contents of the detail page. For example, click on 'Provenance' to navigate to that section.`}</p>
            <img src={detailPage} alt="" width="441" height="881" />
            <p>{t`The left side of the detail page with the summary, table of contents and report button.`}</p>
            <p>{t`The icon with the exclamation point and triangle can be used to make a report. For example, the visitor can report an AVG/copyright violation or add data.`}</p>
          </details>

          {/* Images */}
          <details>
            <summary><h2>{t`Images`}</h2></summary>
            <p>{t`On the details page you will find one or more images. In the screenshot you see two thumbnails, the small squares below the image. These allow you to select the desired image.`}</p>
            <p>{t`Images are visible to everyone at the highest possible resolution. An image can be downloaded at low resolution without a subscription, with an RKD Research subscription you can download the images at the highest possible resolution.`}</p>
            <p>{t`Images of copyrighted works are only visible outside the RKD with permission of the rights holder. The non-visible mages can be viewed in full resolution in the RKD study room. These images cannot be downloaded.`}</p>
            <img src={imagesCopyrightDownload} alt="" width="1238" height="727" />
            <img src={imagesCopyrightNoDownload} alt="" width="1015" height="732" />
          </details>

          {/* Create account */}
          <details>
            <summary><h2>{t`Create account`}</h2></summary>
            <p>{t`Are you new to RKD Research? Then create an account to save research results. To use visual search and my workspace, you need a subscription.`}</p>
            <h3>{t`Creating an account can be done in three steps (see images on the right):`}</h3>
            <ol>
              <li>{t`Click on 'Login' at the top right.`}</li>
              <li>{t`Select the option 'Sign up here'.`}</li>
              <li>{t`Complete the form and click 'Register'.`}</li>
            </ol>
            <p>{t`If you already have an account, you can log in via the 'Login' button at the top right. This account can also be used for the RKD webshop. If you already have a webshop account, you can use it to log in to RKD Research as well.`}</p>
            <ol>
              <li>
                <p>{t`Click on 'Login' in the upper right corner.`}</p>
                <img src={loginLink} alt="" width="432" height="242" />
              </li>
              <li>
                <p>{t`Select the option 'Log in here'.`}</p>
                <img src={loginForm} alt="" width="466" height="605" />
              </li>
            </ol>
          </details>

          {/* My selections */}
          <details>
            <summary><h2>{t`My selections`}</h2></summary>
            <p>{t`Results can be kept in a digital workbook: my selections. This way you can easily keep your favourite records handy for different projects. You can also view, edit, and compare these selections in my workspace.`}</p>
            <h3>{t`Saving a selection in five steps`}</h3>
            <ol>
              <li>
                <p>{t`Log in. Click on 'Log in' in the upper right corner.`}</p>
                <img src={loginLink} alt="" width="432" height="242" />
              </li>
              <li>
                <p>{t`Go to the search overview and check one or more results there.`}</p>
                <img src={mySelections} alt="" width="965" height="558" />
              </li>
              <li>
                <p>{t`Click on 'Add to'.`}</p>
                <img src={saveSelection} alt="" width="262" height="84" />
              </li>
              <li>{t`If necessary, create a new selection.`}</li>
              <li>{t`Choose the desired selection and click 'Add'.`}</li>
            </ol>
            <ListCols cols="2" className="images-outer">
              <img src={addToNewSelection} alt="" width="410" height="692" />
              <img src={addToExistingSelection} alt="" width="428" height="692" />
            </ListCols>
            <h3>{t`Retrieve selections`}</h3>
            <p>{t`After you have made a selection, you can view it by clicking on the link.`}</p>
            <img src={addToSelectionConfirmation} alt="" width="316" height="140" />
            <p>{t`The selections can also be found via the My selections button (top right when logged in).`}</p>
            <img src={mySelectionsLink} alt="" width="212" height="82" />
            <h3>{t`Tip 1`}</h3>
            <p>{t`You can also select results from the detail page using the Select/Deselect button at the top right of the image.`}</p>
            <img src={detailSelect} alt="" width="902" height="690" />
            <h3>{t`Tip 2`}</h3>
            <p>{t`Once you've selected one or more results, they come into a temporary selection. This is shown in the black bar at the bottom of the page. Expand the bar using the arrow on the left to manage the temporary selection. You can remove a result from the temporary selection by clicking on the trash can.`}</p>
            <img src={mySelectionsTemporary} alt="" width="902" height="412" />
          </details>

          {/* My workspace */}
          <details>
            <summary><h2>{t`My workspace`}</h2></summary>
            <p>{t`As a subscriber, you can view, compare, and edit images in my workspace. You can download and save high-resolution images. By making selections in a digital workbook, you can easily keep your favourite records at hand for different projects. See also My Selections.`}</p>
            <img src={myWorkspace} alt="" width="800" height="452" />
            <p className="actions--outer">
              <a href={researchSubscriptionsHref} className="btn btn-yellow">{t`Yes, I want a subscription to RKD Research`}</a>
            </p>
          </details>

          {/* Visual search */}
          <details>
            <summary><h2>{t`Visual search`}</h2></summary>
            <p>{t`With an RKD Research subscription you can use visual search. Upload an image from your computer and the visual search will search all images from all databases for similarities.`}</p>
            <img src={visualSearch} alt="" width="800" height="585" />
            <p className="actions--outer">
              <a href={researchSubscriptionsHref} className="btn btn-yellow">{t`Yes, I want a subscription to RKD Research`}</a>
            </p>
          </details>
        </BodyText>
      </BasicPage>
    </>
  );
}

export default ManualRkdResearch;
