import { useRef, useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import GridContainer from 'components/layout/GridContainer';
// import MenuUserTabs from 'components/navigation/MenuUserTabs';
import BackLink from 'components/navigation/BackLink';
import MenuCollection from 'components/navigation/MenuCollection';
import MenuCollectionItem from 'components/navigation/MenuCollectionItem';
import respValue from 'styles/base/helpers/respValue';
import { t } from '@lingui/macro';
import size from 'styles/base/variables/size';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import SpanGridCols from 'components/layout/SpanGridCols';
import { useNavigate, useParams } from 'react-router-dom';
import useApiData from 'hooks/useApiData';
import ListCols from 'components/layout/ListCols';
import { getLocalizedPath } from 'utils/localizedRoutes';
import Card from 'components/viewmodes/Card';
import Loading from 'components/misc/Loading';
import Error from 'components/misc/Error';
import border from 'styles/base/variables/border';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import MyWorkspaceLink from 'components/misc/MyWorkspaceLink';
import useUserContext from 'hooks/useUserContext';

const StyledCollectionDetail = styled.div`
  .content--header {
    .content--header--nav {
      ${respValue('margin-top', '5rem')};
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem 3rem;
      justify-content: space-between;
    }

    .content--header--textual {
      ${respValue('margin-top', '4rem')};

      ${mq(bp.extraLarge)} {
        padding-left: calc(${SpanGridCols(1)} + ${size.gridGutter});
        width: ${SpanGridCols(7)};
      }

      &.content-is-editable {
        .content--header--textual--editable {
          padding: 3rem;
          box-shadow: inset 0 0 1.5rem ${color.blackDimmed40};
          background-color: ${color.anthracite};
          color: ${color.white};
          outline: none;
        }
      }

      .content--header--textual--inner {
        .content--header--textual--editable {
          > * {
            + * {
              margin-top: 2rem;
            }
          }
        }

        .edit--actions {
          background-color: ${color.paper};
          padding: 1.5rem 3rem;
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;
        }
      }
    }
  }

  .content--main {
    ${respValue('margin-top', '4rem')};

    .content--main--inner {
      border-top: ${border.dimmed};
      ${respValue('padding-top', '3rem')};

      > * {
        + * {
          ${respValue('margin-top', '3rem')};
        }
      }

      .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      .collection-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: ${border.dimmed};
        padding-bottom: 1rem;
        gap: 2rem;
      }
    }
  }

  .textual--editable-with-placeholder:empty:not(:focus)::before {
    content: attr(data-placeholder);
  }
`;

function CollectionDetail() {
  const user = useUserContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const [collectionUpdated, setCollectionUpdated] = useState(0);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const {
    isLoading,
    isError,
    data: [collection],
  } = useApiData(
    [{ path: `/user/collection/${id}`, params: [`u=${collectionUpdated}`] }],
    [[]]
  );

  // Toggle Edit.
  const refEditElement = useRef(null);
  const refEditTitle = useRef(null);
  const refEditDescription = useRef(null);
  const refEditToggler = useRef(null);

  /**
   * Asynchronous function to edit/update collection name and/or description.
   *
   * @param id
   * @returns {Promise<void>}
   */
  const editCollection = async (id) => {
    const collectionTitle = refEditTitle.current.textContent;
    const introElement = refEditDescription.current;

    let collectionIntro = '';
    if (introElement !== undefined) {
      collectionIntro = introElement.textContent;
    }

    const res = await fetch(`/api/user/collections/collection/${id}/edit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        csrf_token: user.csrf_token,
        label: collectionTitle,
        description: collectionIntro,
      }),
    });

    // Prepare response message.
    const data = await res.json();
    setSubmissionMessage(data);
  };

  const handleDeleteCollection = () => {
    setIsProcessing(true);
    deleteCollection(collection.id)
      .then(() => {
        // On successful deletion redirect to all selections page.
        setIsProcessing(false);
        const localizedPath = getLocalizedPath('/my-selections');
        navigate(localizedPath);
      })
      .catch(() => {
        // Handle failure
        setIsProcessing(false);
      });
  };

  const deleteCollection = async (collectionId) => {
    const res = await fetch(
      `/api/user/collections/collection/${collectionId}/remove`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          csrf_token: user.csrf_token,
        }),
      }
    );

    // Prepare response message.
    const data = await res.json();
    setSubmissionMessage(data);
  };

  const handleDeleteCollectionItem = (itemId) => {
    setIsProcessing(true);
    deleteCollectionItem(collection.id, itemId)
      .then(() => {
        setIsProcessing(false);
        setCollectionUpdated(collectionUpdated + 1);
      })
      .catch(() => {
        setIsProcessing(false);
      });
  };

  const deleteCollectionItem = async (collectionId, itemId) => {
    const res = await fetch(
      `/api/user/collections/collection/${collectionId}/remove-item`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          csrf_token: user.csrf_token,
          item_id: itemId,
        }),
      }
    );

    // Prepare response message.
    const data = await res.json();
    setSubmissionMessage(data);
  };

  const [editIsOpen, setEditIsOpen] = useState(false);
  const editHandleClick = () => {
    setEditIsOpen(!editIsOpen);
    // Set focus.
    window.requestAnimationFrame(function () {
      if (editIsOpen) {
        // Focus the toggler.
        refEditToggler.current.focus();

        // Restore original text.
        const titleElement = refEditTitle.current;
        const introElement = refEditDescription.current;
        titleElement.textContent = collection.title;
        introElement.textContent = collection.description;
      } else {
        // Focus the editable element.
        refEditTitle.current.focus();
      }
    });
  };

  // Close edit on esc-key.
  const editHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      editHandleClick();
    }
  };

  const handleOnSave = () => {
    editCollection(collection.id).then(() => {
      setEditIsOpen(false);
    });
  };

  // Check if user has correct role.
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_REGISTERED_USER') > -1 ||
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  return (
    user.authenticated !== null &&
    (!isAuthorized ? (
      <StyledCollectionDetail>
        <GridContainer>
          <div className="indent--1-col">
            <Error title={t`You need to login to use selections.`} />
          </div>
        </GridContainer>
      </StyledCollectionDetail>
    ) : (
      <StyledCollectionDetail>
        <div className="content--header">
          {/*<MenuUserTabs />*/}
          <GridContainer>
            <div className="content--header--nav indent--1-col">
              <BackLink label={t`All selections`} href="/my-selections" />
              {!isError && !isLoading && collection.items && (
                <MenuCollection
                  editTogglerOnClick={editHandleClick}
                  editTogglerRef={refEditToggler}
                  collection={collection}
                  handleConfirmClick={handleDeleteCollection}
                  processingState={isProcessing}
                />
              )}
            </div>
            {!isError && !isLoading && collection.items && (
              <div
                className={`content--header--textual ${
                  editIsOpen ? 'content-is-editable' : ''
                }`}
                onKeyDown={editHandleKeyDown}
              >
                {submissionMessage.errors && (
                  <div>Errors occurred during submission</div>
                )}
                <div className="content--header--textual--inner">
                  <div
                    ref={refEditElement}
                    className="content--header--textual--editable"
                  >
                    <h1
                      id="content--collection-title"
                      className={`font-light ${
                        editIsOpen ? 'textual--editable-with-placeholder' : ''
                      }`}
                      contentEditable={editIsOpen ? '' : false}
                      ref={refEditTitle}
                      data-placeholder={t`Enter selection name...`}
                      suppressContentEditableWarning={true}
                    >
                      {collection.title}
                    </h1>
                    <p
                      id="content--collection-intro"
                      className={`${
                        editIsOpen ? 'textual--editable-with-placeholder' : ''
                      }`}
                      contentEditable={editIsOpen ? '' : false}
                      ref={refEditDescription}
                      data-placeholder={t`Enter description...`}
                      suppressContentEditableWarning={true}
                    >
                      {collection.description}
                    </p>
                  </div>
                  {editIsOpen && (
                    <div className="edit--actions">
                      <button
                        className="btn btn-yellow btn-small"
                        onClick={handleOnSave}
                      >
                        <Trans>Save</Trans>
                      </button>
                      <button
                        className="btn btn-ghost-black btn-small"
                        onClick={editHandleClick}
                      >
                        <Trans>Cancel</Trans>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </GridContainer>
        </div>
        <div className="content--main">
          <GridContainer>
            <div className="content--main--inner indent--1-col">
              {isError ? (
                <Error
                  title={t`The requested selections could not be loaded.`}
                />
              ) : isLoading ? (
                <Loading layoutStyle="full" />
              ) : (
                collection.items && (
                  // TODO: check if card props are correct.
                  <>
                    <div className="actions">
                      <MyWorkspaceLink
                        url={`${process.env.REACT_APP_DD_BASE_URL}?collection_id=${collection.id}`}
                      ></MyWorkspaceLink>
                    </div>
                    <div className="collection-items">
                      <VisuallyHidden>
                        <h2>{t`Selection items`}</h2>
                      </VisuallyHidden>
                      <ListCols cols="4" as="ul">
                        {Object.keys(collection.items).map((key, i) => {
                          const item = collection.items[key];
                          return (
                            <li key={i} className="collection-item">
                              <Card
                                title={getLocalizedValueFromMls(item.title)}
                                meta={getLocalizedValueFromMls(item.meta)}
                                subtitle={getLocalizedValueFromMls(
                                  item.subtitle
                                )}
                                image={item.image}
                                href={getLocalizedPath(
                                  `/detail/${encodeURIComponent(item.id)}`
                                )}
                              />
                              <MenuCollectionItem
                                itemId={item.id}
                                processingState={isProcessing}
                                handleConfirmClick={handleDeleteCollectionItem}
                              />
                            </li>
                          );
                        })}
                      </ListCols>
                    </div>
                  </>
                )
              )}
            </div>
          </GridContainer>
        </div>
      </StyledCollectionDetail>
    ))
  );
}

export default CollectionDetail;
