import BodyText from 'components/misc/BodyText';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

function HeaderBanner() {
  // Use correct localized urls.
  let websiteRkdHref = "https://www.rkd.nl/en/state-of-affairs-rkd-research";

  if (i18n.locale === 'nl') {
    websiteRkdHref = "https://www.rkd.nl/stand-van-zaken-rkd-research";
  }

  return (
    <BodyText className="font-smaller">
      <Trans>
      We are working hard to improve RKD Research and complete the data. For the latest updates: <a href={websiteRkdHref} target="_blank" rel="noopener noreferrer">{websiteRkdHref}</a>
      </Trans>
    </BodyText>
  );
}

export default HeaderBanner;
