import { withSearch } from '@elastic/react-search-ui';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Facets from 'components/search/Facets';
import Results from 'components/search/Results';
import ResultsHeader from 'components/search/ResultsHeader';
import ResultsHeaderVisualSearch from 'components/search/ResultsHeaderVisualSearch';
import GridContainer from 'components/layout/GridContainer';
import SpanGridCols from 'components/layout/SpanGridCols';
import {
  VisuallyHidden,
  VisuallyHiddenStyling,
} from 'components/helpers/VisuallyHidden';
import { mq, bp } from 'styles/base/variables/mediaQueries';
import color from 'styles/base/variables/color';
import border from 'styles/base/variables/border';
import { IconClose, IconFilter } from 'components/graphical/Icons';
import size from 'styles/base/variables/size';
import zIndex from 'styles/base/variables/zIndex';
import ResultsPager from 'components/search/ResultsPager';
import DateRangeFilters from 'components/search/DateRangeFilters';
// import HierarchicalFilters from 'components/search/HierarchicalFilters';
import { parse } from 'qs';
import getDbFromFilters from 'utils/getDbFromFilters';
import DatabaseBanner from 'components/detail/DatabaseBanner';

const StyledSearch = styled.div`
  &:not(.filters-open) {
    .aside--inner {
      ${mq(0, bp.large)} {
        display: none;
      }
    }
  }

  .content--header {
    margin-bottom: 4rem;

    ${mq(bp.large)} {
      display: flex;
      justify-content: space-between;
    }

    .aside {
      ${mq(bp.large)} {
        width: ${SpanGridCols(3)};
        flex-shrink: 0;
        padding-right: 3rem;
      }
    }

    .main {
      ${mq(0, bp.large)} {
        margin-top: 3rem;
      }

      ${mq(bp.large)} {
        width: ${SpanGridCols(9)};
        flex-shrink: 0;
      }
    }
  }

  .content--main {
    ${mq(bp.large)} {
      display: flex;
      justify-content: space-between;
    }

    .aside {
      ${mq(bp.large)} {
        width: ${SpanGridCols(3)};
        flex-shrink: 0;
        padding-right: 3rem;
      }

      .filters-toggler {
        ${mq(bp.large)} {
          display: none;
        }
      }

      .aside--inner {
        ${mq(0, bp.large)} {
          position: fixed;
          inset: 0;
          background-color: ${color.paper};
          z-index: ${zIndex.aboveFullClickPlus};
          padding: ${size.pagePadding};
          overflow: auto;
        }

        ${mq(bp.large)} {
          max-width: 27rem;
        }

        > .filters-toggler {
          padding: 1em;
          position: relative;
          top: -1em;
          left: -1em;
        }

        > .heading {
          margin-bottom: 1em;

          ${mq(bp.large)} {
            ${VisuallyHiddenStyling};
          }
        }
      }

      .date-range-filter {
        border-top: ${border.dimmed};
        padding: 2.8rem 0;
      }

      .facets-container {
        > * {
          border-top: ${border.dimmed};
          padding: 2rem 0;
        }
      }
    }

    .main {
      ${mq(0, bp.large)} {
        margin-top: 3rem;
      }

      ${mq(bp.large)} {
        width: ${SpanGridCols(9)};
        flex-shrink: 0;
      }

      .search-results {
        .results-list--as-cards {
          ${mq(bp.gridMax)} {
            grid-template-columns: repeat(4, 1fr);
          }
        }

        .results-list--as-tiles {
          ${mq(bp.medium, bp.extraLarge)} {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
`;

function Search({
  resultSearchTerm,
  wasSearched,
  setSearchTerm,
  isOverview,
  sortOptions,
  setSort,
  filters,
}) {
  // const {
  //   isLoading,
  //   isError,
  //   data: [hierarchicalFilters],
  // } = useApiData([{ path: '/filter-test' }], [{}]);

  const queryParameters = new URLSearchParams(window.location.search);
  const currentSortOption = queryParameters.get('sort-field') ?? 'relevance';
  // const currentSortDirection = queryParameters.get('sort-direction') ?? '';
  const [activeSorting, setActiveSorting] = useState(currentSortOption);
  const [database, setDatabase] = useState(
    getDbFromFilters(
      parse(decodeURIComponent(queryParameters.toString()))?.filters
    )
  );

  useEffect(() => {
    if (sortOptions && database !== getDbFromFilters(filters)) {
      let hasSortOption = false;
      // Does the database have the requested sorting option.
      for (const [, value] of Object.entries(sortOptions)) {
        if (value.id === activeSorting) {
          hasSortOption = true;
          break;
        }
      }
      if (!hasSortOption) {
        // Reset if the database does not have a matched sorting option.
        setActiveSorting('relevance');
        setSort('relevance', '');
        setDatabase(getDbFromFilters(filters));
      }
    }

    // On database overview or a database without sorting options no sorting
    // should be included from a previous request.
    if (!sortOptions && getDbFromFilters(filters) === 'all') {
      setSort('', '');
    }
  }, [
    database,
    activeSorting,
    setActiveSorting,
    filters,
    sortOptions,
    setSort,
  ]);

  // Toggle filters.
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const filterHandleClick = () => {
    setFilterIsOpen(!filterIsOpen);
  };
  // Close filters on esc-key.
  const filterHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setFilterIsOpen(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {resultSearchTerm ? t`Search for "${resultSearchTerm}"` : t`Search`}
        </title>
      </Helmet>
      <StyledSearch
        className={`${filterIsOpen ? 'filters-open' : ''}`}
        onKeyDown={filterHandleKeyDown}
      >
        <GridContainer>
          <VisuallyHidden>
            <h1>{t`Search`}</h1>
          </VisuallyHidden>
          <div className="content--header">
            <div className="aside">
              <DatabaseBanner />
            </div>
            <div className="main">
              {/* Remove the Visual Search header, at least temporarily. */}
              {false && <ResultsHeaderVisualSearch />}
              <ResultsHeader
                setActiveSorting={setActiveSorting}
                activeSorting={activeSorting}
              />
            </div>
          </div>
          <div className="content--main">
            <div className="aside">
              <button
                className="btn btn-yellow filters-toggler"
                onClick={filterHandleClick}
              >
                <IconFilter />
                <span className="text">
                  <span aria-hidden="true">
                    <Trans>Filter</Trans>
                  </span>
                  <VisuallyHidden>
                    <Trans>Open filters</Trans>
                  </VisuallyHidden>
                </span>
              </button>
              {!isOverview && (
                <div className="aside--inner">
                  <button
                    className="btn-reset filters-toggler"
                    onClick={filterHandleClick}
                  >
                    <IconClose />
                    <span className="text">
                      <VisuallyHidden>
                        <Trans>Close filters</Trans>
                      </VisuallyHidden>
                    </span>
                  </button>
                  <h2 className="heading">
                    <Trans>Filter</Trans>
                  </h2>
                  <DateRangeFilters />
                  <Facets />
                  {/*{!isLoading && !isError && hierarchicalFilters && (*/}
                  {/*  <HierarchicalFilters*/}
                  {/*    hierarchicalFilters={hierarchicalFilters.tree}*/}
                  {/*    loadedFilters={hierarchicalFilters.loadedFilters}*/}
                  {/*  />*/}
                  {/*)}*/}
                </div>
              )}
            </div>
            <div className="main">
              <VisuallyHidden>
                <h2>{t`Results`}</h2>
              </VisuallyHidden>
              <Results />
              {!isOverview && <ResultsPager />}
            </div>
          </div>
        </GridContainer>
      </StyledSearch>
    </>
  );
}

export default withSearch(
  ({
    resultSearchTerm,
    wasSearched,
    setSearchTerm,
    isOverview,
    sortOptions,
    setSort,
    filters,
  }) => ({
    resultSearchTerm,
    wasSearched,
    setSearchTerm,
    isOverview,
    sortOptions,
    setSort,
    filters,
  })
)(Search);
