import { defaultLocale, locales } from 'utils/i18n';
import { i18n } from '@lingui/core';

export function getLocalizedValue(data) {
  const localeCode = i18n.locale;

  // Optimal situation: Return the active locale value.
  if (data?.[localeCode]) {
    return data[localeCode];
  }
  // Fallback: Return the default locale value.
  if (data?.[defaultLocale]) {
    return data[defaultLocale];
  }

  // No value was found, this is probably a bug.
  if (process.env.NODE_ENV === 'development') {
    console.warn('No localized data found. Data:', data);
  }
  // If the data happens to be a string (it shouldn't!) return it.
  return typeof data === 'string' ? data : '';
}

/**
 * Gets the localized langCode and string value.
 * @param strings
 *   The multilingual string array containing localeCode keys (en, nl, ...).
 * @param langCode
 *   The specified langCode.
 *
 * @returns {{langCode: string, string: string, activeLanguage: null|false|true}}
 *   activeLanguage will be true if the string returned is the active language.
 */
export function getLocalizedLangAndValue(strings, langCode = '') {
  // Optimal situation: Return the active locale langCode and value.
  if (langCode && strings?.[langCode]) {
    return {
      langCode: langCode,
      string: strings[langCode],
      activeLanguage: null,
    };
  }

  const localeCode = i18n.locale;

  // Optimal situation: Return the active locale langCode and value.
  if (strings?.[localeCode]) {
    return {
      langCode: localeCode,
      string: strings[localeCode],
      activeLanguage: true,
    };
  }

  // Fallback: Return the default locale langCode and value.
  if (strings?.[defaultLocale]) {
    return {
      langCode: defaultLocale,
      string: strings[defaultLocale],
      activeLanguage: false,
    };
  }

  // If nothing was found, iterate over defined locales and try to find something. Tweak the
  // locale definition order to get the best possible fallbacks.
  const allAvailableLocaleCodes = Object.keys(locales);
  for (let i = 0; i < allAvailableLocaleCodes.length; i++) {
    if (strings?.[allAvailableLocaleCodes[i]]) {
      return {
        langCode: allAvailableLocaleCodes[i],
        string: strings[allAvailableLocaleCodes[i]],
        activeLanguage: false,
      };
    }
  }

  // No value was found, this is probably a bug.
  if (process.env.NODE_ENV === 'development') {
    console.warn('No localized data found. Data:', strings, langCode);
  }

  return { langCode: defaultLocale, string: '' };
}

/**
 * Replaces new lines '\n' with line breaks.
 *
 * @param string
 *   The string to process.
 *
 * @returns {*}
 *   Array containing all string rows with added line break.
 */
export const newLineToLineBreak = (string) => {
  const newLines = string.split('\n');
  if (newLines.length > 1) {
    return newLines.map((line, index) => {
      // Add line break, but skip the first.
      return index > 0 ? [<br key={index} />, line] : line;
    });
  }

  return string;
};
