import ListCols from 'components/layout/ListCols';
import { t, Trans } from '@lingui/macro';
import Card from 'components/viewmodes/Card';
import { getLocalizedPath } from 'utils/localizedRoutes';
import CheckboxAsButton from 'components/graphical/CheckboxAsButton';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import useSnackBarContext from 'hooks/useSnackBarContext';
import styled from 'styled-components';
import zIndex from 'styles/base/variables/zIndex';
import color from 'styles/base/variables/color';
import Loading from 'components/misc/Loading';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import useApiWithBodyContent from 'hooks/useApiWithBodyContent';

const StyledVisualSearchResults = styled.div`
  .results--header {
    margin-bottom: 4rem;
  }

  > ol {
    > li {
      // To position button.
      position: relative;

      > button {
        position: absolute;
        padding: 1rem;
        top: 0rem;
        right: 0rem;
        z-index: ${zIndex.fullClick + 1};

        .square {
          border-width: 0.2rem;
        }

        &:not(.active) {
          .square {
            background-color: ${color.blackDimmed20};
            border-color: ${color.white};
          }

          &:hover {
            .square {
              background-color: ${color.blackDimmed60};
            }
          }
        }
      }
    }
  }
`;

function VisualSearchResults({
  visualSearchResults,
  imgBaseUrl,
  loading,
  error,
}) {
  const snackBar = useSnackBarContext();
  const visualSearchResultsCount = visualSearchResults?.length ?? 0;

  // @todo Paging.
  let firstResults = [];
  if (visualSearchResultsCount) {
    firstResults = visualSearchResults.slice(0, 100);
  }

  const idMap = {
    lref_image: 'https://data.rkd.nl/images/',
    lref_imageslite: 'https://data.rkd.nl/imageslite/',
  };

  // filter the response.
  const ids = firstResults.map((result) => {
    let id = '';
    for (const prop of ['lref_image', 'lref_imageslite']) {
      if (typeof result[prop] !== 'undefined' && result[prop] !== null) {
        id = `${idMap[prop]}${result[prop]}`;
        break;
      }
    }

    return id;
  });

  // Load all cards. The /cards controller guarantees that the order of received cards
  // is the order of the given ids.
  const {
    isLoading,
    isError,
    data: [cards],
  } = useApiWithBodyContent(
    [
      {
        path: '/cards',
        body: ids,
      },
    ],
    []
  );

  function handleSnackBarItem(r) {
    snackBar.toggleItem({
      id: r.id,
      card: r,
    });
  }

  return (
    <div className="search-results">
      {(isLoading || loading) && (
        <>
          <div>{t`Loading...`}</div>
          <Loading spinnerSize="3rem" hiddenText={t`Loading...`} />
        </>
      )}
      {cards && Object.keys(cards).length > 0 && (
        <StyledVisualSearchResults className="visual-search--results">
          <div className="results--header">
            <h2 className="heading">{t`Results`}</h2>
          </div>
          <ListCols cols="4" as="ol">
            {Object.entries(cards).map(([key, item]) => (
              <li key={item.id}>
                <Card
                  title={getLocalizedValueFromMls(item.title)}
                  meta={getLocalizedValueFromMls(item.meta)}
                  subtitle={getLocalizedValueFromMls(item.subtitle)}
                  image={item.image}
                  href={getLocalizedPath(
                    `/detail/${encodeURIComponent(item.id)}`
                  )}
                  linkOptions={{
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }}
                />
                <CheckboxAsButton
                  buttonOnClick={() => handleSnackBarItem(item)}
                  buttonClassName={`btn-reset ${
                    snackBar.hasItem(item.id) ? 'active' : ''
                  }`}
                >
                  <VisuallyHidden>
                    {snackBar.hasItem(item.id) ? (
                      <Trans>Remove from selection</Trans>
                    ) : (
                      <Trans>Add to selection</Trans>
                    )}
                  </VisuallyHidden>
                </CheckboxAsButton>
              </li>
            ))}
          </ListCols>
        </StyledVisualSearchResults>
      )}
      {!isLoading &&
        !loading &&
        ((visualSearchResults !== null && visualSearchResultsCount === 0) ||
          isError ||
          error) && (
          <p>
            <Trans>Your search did not return any results.</Trans>
          </p>
        )}
    </div>
  );
}

export default VisualSearchResults;
