function ButtonLink({ label, href, className }) {
  const classNames = [className, 'btn'];
  return (
    <a className={classNames.join(' ')} href={href}>
      {label}
    </a>
  );
}

export default ButtonLink;
