import styled from 'styled-components';
import AdjustOrder from 'components/helpers/AdjustOrder';
import FullClick from 'components/helpers/FullClick';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import easing from 'styles/base/variables/easing';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import TripleIfImage from 'components/misc/TripleIfImage';

const StyledCard = styled.div`
  .visual {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    transition: opacity ${easing.normal};

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform ${easing.slow};
    }
  }

  .textual {
    margin-top: 2rem;

    .heading {
      line-height: 1.5;
      margin-top: 0.15em;
    }

    .meta {
      margin-top: 0.35em;
    }
  }

  .full-click__trigger {
    &:hover {
      ~ * {
        .textual {
          .heading {
            text-decoration: underline;
          }
        }

        .visual {
          opacity: 0.7;
        }
      }
    }
  }
`;

function Card({ title, subtitle, meta, image, href, linkOptions }) {
  // Make sure subtitle doesn't contain html-tags.
  const cleanSubtitle = subtitle.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <FullClick>
      <StyledCard>
        <Link to={href} className="full-click__trigger" {...linkOptions}>
          <VisuallyHidden>
            <Trans>Read more about {subtitle.replace(/<[^>]+>/g, '')}</Trans>
          </VisuallyHidden>
        </Link>
        <AdjustOrder>
          <AdjustOrder className="textual">
            <h3
              className="heading text--body"
              dangerouslySetInnerHTML={{ __html: cleanSubtitle }}
            />
            <div
              className="h3 order-min"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="meta font-smaller"
              dangerouslySetInnerHTML={{ __html: meta }}
            />
          </AdjustOrder>
          <div className="visual order-min">
            {image && (
              <TripleIfImage
                baseUrl={image.src}
                alt={''}
                width={320}
                height={320}
              />
            )}
          </div>
        </AdjustOrder>
      </StyledCard>
    </FullClick>
  );
}

export default Card;
