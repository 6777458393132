import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { IconMonitor } from 'components/graphical/Icons';
import useUserContext from 'hooks/useUserContext';
import LocalizedLink from 'components/helpers/LocalizedLink';
import useAnalyticsContext from 'hooks/useAnalyticsContext';

const StyledMyWorkspaceLink = styled.div`
  svg {
    width: 2rem;
    height: auto;
  }
`;

function MyWorkspaceLink({ url, label, className }) {
  // Check if user has correct role.
  const user = useUserContext();
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  // Define class names.
  const classNames = [className, 'btn btn-yellow btn-rounded btn-my-workspace'];

  // Define button content.
  let buttonContent = (
    <>
      <span className="text">{label ?? <Trans>My workspace</Trans>}</span>
      <IconMonitor />
    </>
  );

  const analytics = useAnalyticsContext();
  const handleMyWorkspaceOpen = () => {
    if (!isAuthorized) {
      // Has no sufficient My workspace access.
      analytics.addTrack('trackDenial', {
        deny: 'No_License',
        actionType: 'digital_desktop',
      });
    }
  };

  return (
    <StyledMyWorkspaceLink>
      {isAuthorized ? (
        <a className={classNames.join(' ')} href={url}>
          {buttonContent}
        </a>
      ) : (
        <LocalizedLink
          className={classNames.join(' ')}
          to="/my-workspace"
          onClick={handleMyWorkspaceOpen}
        >
          {buttonContent}
        </LocalizedLink>
      )}
    </StyledMyWorkspaceLink>
  );
}

export default MyWorkspaceLink;
