import { lazy, Suspense, useState } from 'react';
import { t } from '@lingui/macro';
import Mls from 'components/detail/Mls';
import Modal from 'components/misc/Modal';

const ThesaurusTermDetails = lazy(() =>
  import('components/detail/ThesaurusTermDetails')
);

function ThesaurusExplainer({ label, id }) {
  // Toggle ThesaurusExplainer.
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  return (
    <div className="thesaurus-explainer">
      <button
        className="btn btn-small btn-ghost-black btn-rounded"
        onClick={itemHandleClick}
        aria-expanded={itemIsOpen ? 'true' : 'false'}
      >
        <Mls strings={label} />
      </button>
      {itemIsOpen && (
        <Modal closeTrigger={itemHandleClick}>
          <Suspense
            fallback={
              <p className="font-normal font-smaller">{t`Loading...`}</p>
            }
          >
            <ThesaurusTermDetails id={id} />
          </Suspense>
        </Modal>
      )}
    </div>
  );
}

export default ThesaurusExplainer;
