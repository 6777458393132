import React, { createContext } from 'react';
import { isEqual } from 'lodash';
import useUserContext from 'hooks/useUserContext';
import useConsentContext from 'hooks/useConsentContext';

const AnalyticsContext = createContext();

const AnalyticsContextProvider = ({ children }) => {
  const user = useUserContext();
  const consent = useConsentContext();

  const getAuthenticationMembers = () => {
    let members = {};
    if (user.aid) {
      members.aid = user.aid;
      members.aname = user.aname;
    } else {
      members.anon = true;
    }
    return members;
  };

  const generateDefaults = () => {
    let identifiers = {};
    identifiers.pid = process.env.REACT_APP_LIBLYNX_PID;
    identifiers.plid = process.env.REACT_APP_LIBLYNX_LPID;
    identifiers.plname = 'RKD Research';

    return {
      ...identifiers,
      ...getAuthenticationMembers(),
    };
  };

  const isLLStateChanged = () => {
    const isChanged = !isEqual(window._llState, generateDefaults());
    window._llState = generateDefaults();
    return isChanged;
  };

  const initialise = () => {
    window._ll = window._ll || [];
    window._ll.push(['setDefaults', generateDefaults()]);
  };

  const trackSearch = (data) => {
    let trackingData = {};
    switch (data.dbs) {
      case 'rkdimages':
        trackingData.dbs = [{ dbid: 1, dbname: 'RKDimages' }];
        break;
      case 'rkdimages_lite':
        trackingData.dbs = [{ dbid: 2, dbname: 'RKDimages Lite' }];
        break;
      case 'rkdimages_and_lite':
        trackingData.dbs = [
          { dbid: 1, dbname: 'RKDimages' },
          { dbid: 2, dbname: 'RKDimages Lite' },
        ];
        break;
      default:
      // Unknown database name.
    }

    // Clean previous dbs item.
    const { dbs, ...rest } = data;

    return { ...trackingData, ...rest };
  };

  const addTrack = (id, data) => {
    if (consent.consentStatus.tracking !== true) {
      return;
    }

    if (isLLStateChanged()) {
      initialise();
    }

    let trackingData = {};
    switch (id) {
      case 'trackSearch':
        trackingData = trackSearch(data);
        break;
      case 'trackRequest':
      case 'trackDenial':
      case 'trackInvestigation':
        trackingData = data;
        break;
      default:
      // Unknown tracking id.
    }
    window._ll.push([id, { ...trackingData, ...getAuthenticationMembers() }]);
  };

  return (
    <AnalyticsContext.Provider value={{ addTrack }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsContextProvider };
