import { getLocalizedValue } from 'utils/processors';

function getLocalizedValueFromMls({ type, strings }) {
  if (type === 'mls') {
    return getLocalizedValue(strings);
  }
  return '';
}

export default getLocalizedValueFromMls;
