import styled from 'styled-components';
import color from 'styles/base/variables/color';
import size from 'styles/base/variables/size';
import zIndex from 'styles/base/variables/zIndex';
import borderRadius from 'styles/base/variables/borderRadius';
import { mq, bp } from 'styles/base/variables/mediaQueries';
import GridContainer from 'components/layout/GridContainer';
import MenuArtwork from 'components/navigation/MenuArtwork';
import respValue from 'styles/base/helpers/respValue';
import renderDetailsAsComponents from 'utils/renderDetailsAsComponents';
import useSnackBarContext from 'hooks/useSnackBarContext';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import TripleIfImage from 'components/misc/TripleIfImage';
import DetailImageViewer from 'components/misc/DetailImageViewer';
import CheckboxAsButton from 'components/graphical/CheckboxAsButton';
import { Trans } from '@lingui/macro';
import { useSearchParams } from 'react-router-dom';
import DatabaseBanner from 'components/detail/DatabaseBanner';

const StyledArtworkDetail = styled.div`
  .content--main {
    display: grid;

    ${mq(0, bp.medium)} {
      margin-left: -${size.pagePadding};
      margin-right: -${size.pagePadding};

      grid-template-columns: 1fr;
      grid-template-areas:
        'aside-nav'
        'main-visual'
        'aside-content'
        'main-text';
    }

    ${mq(bp.medium)} {
      margin-left: -${size.gridFullPagePadding};
      margin-right: -${size.gridFullPagePadding};
      grid-template-columns: ${size.gridFullPagePadding} 1fr 2fr;
      grid-template-areas:
        'aside-nav aside-content main-visual'
        'aside-nav aside-content main-text';
    }

    ${mq(bp.extraLarge)} {
      grid-template-columns: ${size.gridFullPagePadding} ${size.gridFullAsideContentWidth} 1fr;
    }

    .aside--nav {
      grid-area: aside-nav;

      background-color: ${color.mineshaft};

      // To position above pageFooter and for when artwork sub-menu is open.
      position: relative;
      z-index: ${zIndex.asideNav};

      ${mq(0, bp.medium)} {
        padding: 1rem ${size.pagePadding};
      }

      ${mq(bp.medium)} {
        width: ${size.gridFullPagePadding};
        box-shadow: -200vh -100vh 0rem 200vh ${color.mineshaft};
      }

      .aside--nav-inner {
        ${mq(bp.medium)} {
          position: sticky;
          top: 2rem;
        }

        .visual {
          border-radius: ${borderRadius.full};
          width: 4.8rem;
          aspect-ratio: 48/48;
          overflow: hidden;
          margin: 0 auto 1rem auto;

          ${mq(0, bp.medium)} {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        nav {
          ul {
            ${mq(bp.medium)} {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            li {
              &:not(:last-child) {
                ${mq(0, bp.medium)} {
                  margin-right: 1rem;
                }
              }

              + li {
                ${mq(bp.medium)} {
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .aside--content {
      grid-area: aside-content;

      .aside--content-inner {
        ${respValue('padding-bottom', '7rem')};

        ${mq(0, bp.gridMax)} {
          padding-left: ${size.pagePadding};
          padding-right: ${size.pagePadding};
        }

        ${mq(bp.medium)} {
          position: sticky;
          ${respValue('top', '7rem')};
          max-height: 100vh;
          overflow: auto;
        }

        ${mq(bp.gridMax)} {
          ${respValue('padding-right', '5rem')};
          ${respValue('padding-left', '5rem')};
        }

        .database-banner {
          margin-bottom: 3rem;
        }

        .heading {
          margin-bottom: 0.35em;
        }

        nav {
          ${respValue('margin-top', '10rem')};

          ${mq(0, bp.medium)} {
            display: none;
          }
        }
      }
    }

    .main--visual {
      grid-area: main-visual;

      background-color: ${color.mineshaft};
      color: ${color.white};

      // To position actions and snackbar-toggler.
      position: relative;

      .snackbar-toggler {
        position: absolute;
        padding: 2rem;
        top: 0rem;
        right: 0rem;
        ${alignIconDefaults()};
        z-index: ${zIndex.aboveFullClick};

        .icon {
          color: ${color.anthracite};
        }

        .text {
          order: -1;
        }

        .square {
          width: 2rem;
          border-width: 0.2rem;
        }

        &:not(.active) {
          .square {
            background-color: ${color.blackDimmed20};
            border-color: ${color.white};
          }

          &:hover {
            .square {
              background-color: ${color.blackDimmed40};
            }
          }
        }
      }
    }

    .main--textual {
      grid-area: main-text;
      place-self: stretch;
      background-color: ${color.paper};
      box-shadow: 100vh 100vh 0rem 100vh ${color.paper};
      ${respValue('padding-top', '3rem')};
      ${respValue('padding-bottom', '3rem')};
      ${respValue('padding-left', '5rem', '1rem')};

      ${mq(0, bp.medium)} {
        ${respValue('padding-right', '5rem', '1rem')};
      }

      ${mq(bp.medium)} {
        padding-right: ${size.gridFullPagePadding};

        // Improve scroll behavior on hashchange.
        min-height: 100vh;
      }

      > * {
        + * {
          ${respValue('margin-top', '2rem')};
        }
      }
    }
  }
`;

function ArchiveDetailView({ id, title, archiveTree, tree, images }) {
  const snackBar = useSnackBarContext();
  const [searchParameters] = useSearchParams();

  function handleSnackBarItem() {
    snackBar.toggleItem({
      id: id,
      title: title,
      image: images[0],
    });
  }

  let treeToRender = [];
  if (tree.hasOwnProperty(searchParameters.get('imageId'))) {
    treeToRender = tree[searchParameters.get('imageId')];
  } else if (tree.hasOwnProperty('default')) {
    treeToRender = tree['default'];
  } else {
    treeToRender = tree;
  }

  return (
    <>
      <StyledArtworkDetail>
        <GridContainer full="true">
          <div className="content--main">
            <div className="aside--nav">
              <div className="aside--nav-inner">
                <div className="visual">
                  {images.length > 0 && (
                    <TripleIfImage
                      baseUrl={images[0].src}
                      width={50}
                      height={50}
                    />
                  )}
                </div>
                <MenuArtwork />
              </div>
            </div>
            <div className="aside--content">
              <div className="aside--content-inner">
                <DatabaseBanner />
                {renderDetailsAsComponents(archiveTree)}
              </div>
            </div>
            {images.length > 0 && (
              <div className="main--visual">
                <DetailImageViewer
                  images={images}
                  activeWidth="920"
                  activeHeight="520"
                  thumbnailWidth="80"
                  thumbnailHeight="80"
                />

                <CheckboxAsButton
                  buttonOnClick={handleSnackBarItem}
                  buttonClassName={`snackbar-toggler btn-reset ${
                    snackBar.hasItem(id) ? 'active' : ''
                  }`}
                >
                  <span className="text font-normal font-smaller">
                    {snackBar.hasItem(id) ? (
                      <Trans>Deselect</Trans>
                    ) : (
                      <Trans>Select</Trans>
                    )}
                  </span>
                </CheckboxAsButton>
              </div>
            )}
            <div className="main--textual">
              {renderDetailsAsComponents(treeToRender)}
            </div>
          </div>
        </GridContainer>
      </StyledArtworkDetail>
    </>
  );
}

export default ArchiveDetailView;
