import { withSearch, Paging } from '@elastic/react-search-ui';
import styled from 'styled-components';
import { IconPointerRight, IconEllipsis } from 'components/graphical/Icons';
import color from 'styles/base/variables/color';

const StyledResultsPager = styled.div`
  margin-top: 6rem;

  .unstyled-list--inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
  }

  .rc-pagination-item {
    padding: 0.5rem;
    cursor: pointer;
    line-height: 1;
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
    color: ${color.white};

    &:not([aria-disabled='true']) {
      background-color: ${color.anthracite};
      cursor: pointer;
    }

    &[aria-disabled='true'] {
      background-color: ${color.sand};
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    svg {
      max-width: 100%;
      height: auto;
    }
  }

  .rc-pagination-prev {
    margin-right: 1rem;
    transform: scaleX(-1);
  }

  .rc-pagination-next {
    margin-left: 1rem;
  }

  .rc-pagination-jump-prev-custom-icon,
  .rc-pagination-jump-next-custom-icon {
    padding: 0.5rem;
    cursor: pointer;
    opacity: 0.5;
  }

  .rc-pagination-item-active {
    font-weight: bold;
  }
`;

function ResultsPager({ totalResults, current, resultsPerPage, totalPages }) {
  return (
    <StyledResultsPager className="results--pager">
      <Paging
        className="unstyled-list--inline"
        nextIcon={<IconPointerRight />}
        prevIcon={<IconPointerRight />}
        jumpNextIcon={<IconEllipsis />}
        jumpPrevIcon={<IconEllipsis />}
      />
    </StyledResultsPager>
  );
}

export default withSearch(
  ({ totalResults, current, setCurrent, resultsPerPage, totalPages }) => ({
    totalResults,
    current,
    setCurrent,
    resultsPerPage,
    totalPages,
  })
)(ResultsPager);
