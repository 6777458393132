import { useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import { IconTrash, IconPencil } from 'components/graphical/Icons';
import Disc from 'components/graphical/Disc';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import Modal from 'components/misc/Modal';

const StyledUserCollectionMenu = styled.div`
  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;

    > li {
      button {
        ${alignIconDefaults(`${10 / 16}em`)};

        .disc {
          background-color: ${color.paper};
        }
      }
    }
  }
`;

function MenuUserCollection({
  editTogglerOnClick,
  editTogglerRef,
  collection,
  handleConfirmClick,
  processingState,
}) {
  // Toggle Delete confirmation.
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  return (
    <StyledUserCollectionMenu>
      <ul>
        <li>
          <button
            className="btn-reset"
            onClick={editTogglerOnClick}
            ref={editTogglerRef}
          >
            <Disc className="disc">
              <IconPencil />
            </Disc>
            <span className="text font-thick">
              <Trans>Edit title and description</Trans>
            </span>
          </button>
        </li>
        <li>
          <button
            className="btn-reset"
            onClick={itemHandleClick}
            aria-expanded={itemIsOpen ? 'true' : 'false'}
          >
            <Disc className="disc">
              <IconTrash />
            </Disc>
            <span className="text font-thick">
              <Trans>Delete selection</Trans>
            </span>
          </button>
          {itemIsOpen && (
            <Modal closeTrigger={itemHandleClick}>
              <p className="modal--content-title">
                <Trans>Delete selection?</Trans>
              </p>
              <p className="font-smaller">
                <Trans>
                  Are you sure you want to delete the{' '}
                  <span className="font-medium">{collection.title}</span>{' '}
                  selection?
                </Trans>
              </p>
              <div className="modal--content-actions">
                <button
                  className="btn btn-yellow"
                  onClick={() => handleConfirmClick()}
                  disabled={processingState}
                >
                  <Trans>Yes</Trans>
                </button>
                <button
                  className="btn btn-ghost-black"
                  onClick={itemHandleClick}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </Modal>
          )}
        </li>
      </ul>
    </StyledUserCollectionMenu>
  );
}

export default MenuUserCollection;
