import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import BasicPage from 'components/layout/BasicPage';
import BodyText from 'components/misc/BodyText';
import NotFound from 'components/misc/NotFound';

// This page is only needed for Dutch, therefore string translations is not needed.
// The route for English must exist, so in that case output NotFound.
function ContributionsToTheAAT() {
  return (
    <>
      {i18n.locale === 'nl' ? (
        <>
          <Helmet>
            <title>{t`Contributions to the AAT`}</title>
          </Helmet>
          <BasicPage>
            <BodyText>
              <h1>Bijdragen aan de AAT</h1>
              <p>De AAT bevat tienduizenden concepten maar is nog verre van compleet. Bijdrages vanuit het werkveld aan de vertaling van specialistische terminologie en de verbetering van bestaande terminologie worden zeer gewaardeerd. Daarnaast is het mogelijk om voorstellen voor nieuwe concepten te doen. Al deze bijdrages worden via een Belgisch-Nederlandse redactie aan Getty voorgedragen voor opname in de AAT.</p>
              <p>Om een nieuw concept voor te stellen moeten de volgende stappen doorlopen worden:</p>
              <ol>
                <li><p>Neem contact op met de Nederlandstalige redactie van de AAT via <a href="mailto:aat@rkd.nl">aat@rkd.nl</a>.</p></li>
                <li><p>In de AAT worden concepten volgens een vast stramien beschreven. Voorstellen voor nieuwe concepten moeten hieraan voldoen. Tot de vereiste gegevens behoren: de gebruikelijke Nederlandstalige en Engelstalige benaming(en) voor het concept (termen), een Nederlandstalige en Engelstalige definitie (scope note), een beperkt aantal betrouwbare bronnen waaruit termen en scope note zijn afgeleid, en een indicatie van de positie van het concept in de hiërarchie van de AAT. De uitgebreide richtlijnen kunnen worden opgevraagd bij de redactie.</p></li>
                <li><p>Het voorstel wordt besproken binnen de redactie. Hierbij wordt gekeken vooral gekeken of het concept past binnen de AAT, of de term passend en uniek is, of de scope note voldoende aanknopingspunten biedt om het concept duidelijk te kunnen identificeren en of het concept hiërarchisch op de juiste plaats binnen de AAT verankerd is. Minimale wijzigingen worden door de redactie zelf uitgevoerd, voor grotere wijzigingen of problemen gaat een voorstel terug naar de aanvrager. Als de redactie van mening is dat een concept geen kans maakt om opgenomen te worden in de AAT dan wordt het voorstel afgewezen, of de term wordt bijvoorbeeld voorgesteld als synoniem van een al bestaand concept.</p></li>
                <li><p>Het voorstel wordt doorgestuurd naar Getty.</p></li>
                <li><p>Getty beoordeelt de term en neemt deze op in de AAT of wijst hem af.</p></li>
              </ol>
            </BodyText>
          </BasicPage>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default ContributionsToTheAAT;
