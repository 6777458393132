import { Helmet } from 'react-helmet';

function Metatags({ metatags }) {
  return (
    <Helmet>
      {metatags.map((metatag, idx) => {
        if (!metatag.content) {
          return null;
        }
        let metaProps = {
          content: metatag.content,
        };
        if (metatag.name) {
          metaProps.name = metatag.name;
        }
        if (metatag.property) {
          metaProps.property = metatag.property;
        }
        if (metatag.itemProp) {
          metaProps.itemProp = metatag.itemProp;
        }
        if (metatag.httpEquiv) {
          metaProps.httpEquiv = metatag.httpEquiv;
        }

        return <meta key={idx} {...metaProps} />;
      })}
    </Helmet>
  );
}

export default Metatags;
