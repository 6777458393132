import React from 'react';
import Row from 'components/detail/Row';
import Section from 'components/detail/Section';
import Mls from 'components/detail/Mls';
import SubjectWithText from 'components/detail/SubjectWithText';
import TabGroup from 'components/detail/TabGroup';
import RowList from 'components/detail/RowList';
import ThesaurusExplainer from 'components/detail/ThesaurusExplainer';
import NewSearchQuery from 'components/detail/NewSearchQuery';
import NewLine from 'components/detail/NewLine';
import SubjectWithList from 'components/detail/SubjectWithList';
import ListItem from 'components/detail/ListItem';
import InternalLink from 'components/detail/InternalLink';
import ExternalLink from 'components/detail/ExternalLink';
import Table from 'components/detail/Table';
import TableRow from 'components/detail/TableRow';
import TableHead from 'components/detail/TableHead';
import TableBody from 'components/detail/TableBody';
import TableHeader from 'components/detail/TableHeader';
import TableData from 'components/detail/TableData';
import Banner from 'components/detail/Banner';
import UnorderedList from 'components/detail/UnorderedList';
import OrderedList from 'components/detail/OrderedList';
import Map from 'components/detail/Map';
import Metatags from 'components/misc/Metatags';
import ArchiveTree from 'components/detail/ArchiveTree';

/**
 * Internal function to map types to components.
 *
 * @param {string} type
 *   The requested component type.
 * @returns {(function({children: *, [p: string]: *}))|(function(*))}
 *   A React component.
 */
function getComponentForType(type) {
  switch (type) {
    case 'mls':
      return Mls;

    case 'new-search-query':
      return NewSearchQuery;

    case 'row':
      return Row;

    case 'row-list':
      return RowList;

    case 'section':
      return Section;

    case 'subject-with-text':
      return SubjectWithText;

    case 'tab-group':
      return TabGroup;

    case 'thesaurus-explainer':
      return ThesaurusExplainer;

    case 'new-line':
      return NewLine;

    case 'subject-with-list':
      return SubjectWithList;

    case 'list-item':
      return ListItem;

    case 'internal-link':
      return InternalLink;

    case 'external-link':
      return ExternalLink;

    case 'table':
      return Table;

    case 'thead':
      return TableHead;

    case 'tbody':
      return TableBody;

    case 'tr':
      return TableRow;

    case 'th':
      return TableHeader;

    case 'td':
      return TableData;

    case 'banner':
      return Banner;

    case 'ulist':
      return UnorderedList;

    case 'olist':
      return OrderedList;

    case 'map':
      return Map;

    case 'metatags':
      return Metatags;

    case 'archive-tree':
      return ArchiveTree;

    default:
      return ComponentNotImplemented;
  }
}

/**
 * Renders a symfony detail response into react components.
 */
function renderDetailsAsComponents(details) {
  const e = React.createElement;
  // React dev mode renders the component twice, removing children from
  // detail with every array.pop. Clone details to prevent this from
  // happening.
  const clonedDetails = structuredClone(details);

  let renderedElement = null;
  // A stack holding unrendered items.
  const unrenderedItemStack = [];
  // A stack holding rendered (react) elements that still need to be expanded
  // with siblings or added to their parents.
  const renderedChildrenStack = [];
  let key = 0;

  // Collects rendered children in the current level.
  let children;
  let siblings;
  const rootElements = [];

  for (let i = 0; i < details.length; i++) {
    let item = clonedDetails[i];
    children = [];
    // Render item.children depth first.
    while (item !== undefined) {
      if (item?.children?.length > 0) {
        // Item has children. Place the item on the unrendered stack and move
        // its rendered siblings in children to the rendered stack.
        unrenderedItemStack.push(item);
        renderedChildrenStack.push(children);
        children = [];
        // The pop() changes children on the item in unrenderedItemStack. This
        // is necessary.
        item = item.children.pop();
        continue;
      }

      // The element has no, or no more children. Time to render the element
      // itself.
      // Possible children were rendered in reverse order (due to the use of
      // Array.pop instead of Array.shift). Reverse the children here.
      children.reverse();

      // Pass all non-type and non-children members as props.
      const { type, children: discard, ...props } = item;
      // Get possible siblings to the use the item's future idx in siblings
      // as key prop.
      siblings = renderedChildrenStack.pop();
      if (siblings === undefined) {
        siblings = [];
      }
      // Root elements, indicated by the empty unrenderedItemStack never
      // have siblings. Use the for counter variable as key instead.
      key = unrenderedItemStack.length === 0 ? i : siblings.length;

      // Render the component with props and children.
      const elementType = getComponentForType(type);
      if (elementType === ComponentNotImplemented) {
        props.requested_type = type;
      }
      renderedElement = e(elementType, { key: key, ...props }, children);
      // Add the rendered item to its potential siblings that were rendered
      // earlier.
      siblings.push(renderedElement);
      // Continue rendering the parent.
      children = siblings;
      item = unrenderedItemStack.pop();
    }
    // Root level items have only one element.
    rootElements.push(children[0]);
  }
  // Multiple elements need to be wrapped in a Fragment.
  return e(React.Fragment, {}, rootElements);
}

export default renderDetailsAsComponents;

/**
 * Renders a simple component not implemented warning.
 */
function ComponentNotImplemented({ requested_type }) {
  return <p className="warning">Component not implemented: {requested_type}</p>;
}
