import styled from 'styled-components';
import useUserContext from 'hooks/useUserContext';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { Trans } from '@lingui/macro';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import { IconFolder } from 'components/graphical/Icons';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import border from 'styles/base/variables/border';

const StyledHeaderMenu = styled.nav`
  ul {
    li {
      display: flex;

      + li {
        ${mq(0, bp.mobileMenu)} {
          border-top: ${border.dimmed};
        }

        ${mq(bp.mobileMenu)} {
          margin-left: 2rem;
        }
      }

      a {
        ${alignIconDefaults()};
        width: 100%;

        ${mq(0, bp.mobileMenu)} {
          padding: 1.6rem 0;
        }
      }
    }
  }
`;

function MenuHeader() {
  const user = useUserContext();

  return (
    user.authenticated && (
      <StyledHeaderMenu>
        <ul className="font-smaller">
          <li>
            <LocalizedLink to="/my-selections">
              <IconFolder />
              <span className="text font-normal">
                <Trans>My selections</Trans>
              </span>
            </LocalizedLink>
          </li>
        </ul>
      </StyledHeaderMenu>
    )
  );
}

export default MenuHeader;
