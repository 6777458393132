export default function adaptRequest(request, queryConfig) {
  // Defaulting to undefined, so when searchTerm is empty, q is completely
  // omitted from the query url.
  const q = request.searchTerm || undefined;

  const filters = request.filters.map((filter) => ({
    field: filter.field,
    values: filter.values,
  }));
  const page = Math.max(0, request.current - 1);

  const sorting = {
    field: request.sortField,
    direction: request.sortDirection,
  };

  return {
    // The parameters object is converted to query string with qs.stringify.
    parameters: { q, page, filters, sorting },
  };
}
