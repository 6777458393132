import { withSearch } from '@elastic/react-search-ui';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parse } from 'qs';
import { useRef, useState } from 'react';
import { getLocalizedPath } from 'utils/localizedRoutes';
import { IconPlus, IconSearch } from 'components/graphical/Icons';
import {
  VisuallyHidden,
  VisuallyHiddenStyling,
} from 'components/helpers/VisuallyHidden';
import color from 'styles/base/variables/color';
import border from 'styles/base/variables/border';
import { fontSize } from 'styles/base/variables/typography';
import respValue from 'styles/base/helpers/respValue';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import inputTextual from 'styles/base/helpers/inputTextual';
import useIsSearchPage from 'hooks/useIsSearchPage';
import useApiData from 'hooks/useApiData';
import useAnalyticsContext from 'hooks/useAnalyticsContext';
import { getLocalizedValue } from 'utils/processors';
import getDbFromFilters, {
  getCurrentlyActiveDatabase,
} from 'utils/getDbFromFilters';
import AdvancedSearch from 'components/search/AdvancedSearch';
import useAdvancedSearchContext from 'hooks/useAdvancedSearchContext';

const StyledSearchBox = styled.form`
  border-bottom: ${border.dimmed};

  .input-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    ${respValue('padding-bottom', '1.2rem')};

    ${mq(0, bp.extraLarge)} {
      flex-wrap: wrap;
    }

    ${mq(bp.gridMax)} {
      padding-right: 28rem;
    }

    label {
      ${mq(0, bp.extraLarge)} {
        ${VisuallyHiddenStyling};
      }
    }

    ${inputTextual()} {
      color: ${color.boulder};
      ${respValue('font-size', '2.8rem', '1.8rem')};
      border: none;
      width: auto;
      flex: 1 1 auto;

      ${mq(0, bp.extraLarge)} {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .select-wrapper {
      ${mq(0, bp.large)} {
        width: 100%;
        order: -1;
        ${respValue('padding-bottom', '1.2rem')};
        border-bottom: ${border.dimmed};
        ${respValue('margin-bottom', '1.2rem')};
      }
    }

    select {
      border: none;

      ${respValue(
        'font-size',
        fontSize.text.larger.desktop,
        fontSize.text.larger.mobile
      )};

      ${mq(0, bp.extraLarge)} {
        padding: 0.5rem;
      }
    }

    button[type='submit'] {
      padding: 1em;
      margin-left: auto;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    .advanced-search-toggler {
      padding: ${15 / 14}em;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
`;

function SearchBox({
  searchTerm,
  setSearchTerm,
  filters,
  setFilter,
  advancedSearchOptions,
}) {
  const navigate = useNavigate();
  const isSearchPage = useIsSearchPage();
  const analytics = useAnalyticsContext();
  const advancedSearchStorage = useAdvancedSearchContext();
  const searchTermInputRef = useRef();
  const searchActiveDatabaseRef = useRef();
  const [advancedSearchIsOpen, setAdvancedSearchIsOpen] = useState(
    advancedSearchStorage.isOpen ?? false
  );

  const [searchParams] = useSearchParams();
  const defaultDatabase = getCurrentlyActiveDatabase(filters, searchParams);

  const [activeDatabase, setActiveDatabase] = useState(defaultDatabase);
  const [isDatabaseExplicitlyChosen, setIsDatabaseExplicitlyChosen] =
    useState(false);

  const handleInput = (event) => {
    setSearchTerm(event.target.value, {
      refresh: false,
      autocompleteSuggestions: false,
      autocompleteResults: false,
    });
  };

  const handleSelection = (event) => {
    setActiveDatabase(event.target.value);
    setIsDatabaseExplicitlyChosen(true);
    if (!availableSearches?.[event.target.value]?.hasOwnProperty('fields')) {
      setAdvancedSearchIsOpen(false);
      advancedSearchStorage.setIsOpen(false);
    }
  };

  const handleTracking = () => {
    analytics.addTrack('trackSearch', {
      dbs: activeDatabase,
      // Indicate 'automated search'.
      sa: !isDatabaseExplicitlyChosen,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleTracking();
    if (isSearchPage) {
      setSearchTerm(searchTerm);
      setFilter('db', activeDatabase);
    } else {
      // If we're not on the search page, go there.
      const localizedPath = getLocalizedPath('/search');
      const fieldFilter = 'filters%5B0%5D%5Bfield%5D=db';
      const fieldValue = `filters%5B0%5D%5Bvalues%5D%5B0%5D=${encodeURIComponent(
        activeDatabase
      )}`;
      navigate(
        `${localizedPath}?q=${encodeURIComponent(
          searchTerm
        )}&${fieldFilter}&${fieldValue}`
      );
    }
  };

  const handleOpenAdvancedSearch = (event) => {
    event.preventDefault();
    advancedSearchStorage.setIsOpen(!advancedSearchIsOpen);
    setAdvancedSearchIsOpen(!advancedSearchIsOpen);
  };

  const {
    isLoading,
    isError,
    data: [availableSearches],
  } = useApiData(['/available-searches'], [{}]);

  return (
    <StyledSearchBox className="search-box" onSubmit={handleSubmit}>
      <div className="input-wrapper">
        <label htmlFor="input--search-term">
          <VisuallyHidden>
            <Trans>Search term:</Trans>
          </VisuallyHidden>
          <IconSearch />
        </label>
        <input
          id="input--search-term"
          type="text"
          className="font-light"
          name="search-term"
          placeholder={t`What are you looking for?`}
          value={searchTerm}
          onChange={handleInput}
          ref={searchTermInputRef}
        />
        <div className="select-wrapper">
          <VisuallyHidden>
            <label htmlFor="select-databases">
              <Trans>Choose a database:</Trans>
            </label>
          </VisuallyHidden>
          <select
            name="databases"
            id="select-databases"
            onChange={handleSelection}
            value={activeDatabase}
            ref={searchActiveDatabaseRef}
          >
            {!isLoading &&
              !isError &&
              availableSearches &&
              Object.keys(availableSearches).map((database) => (
                <option key={database} value={database}>
                  {availableSearches[database]?.label?.constructor === Object
                    ? getLocalizedValue(availableSearches[database].label)
                    : availableSearches[database].label}
                </option>
              ))}
          </select>
        </div>
        <button type="submit" className="btn btn-yellow">
          <VisuallyHidden>
            <Trans>Search</Trans>
          </VisuallyHidden>
          <IconSearch />
        </button>
        {availableSearches?.[activeDatabase]?.['fields'] && (
          <button
            onClick={handleOpenAdvancedSearch}
            className={`btn btn-black advanced-search-toggler ${
              advancedSearchIsOpen || advancedSearchStorage.isOpen ? 'open' : ''
            }`}
            aria-expanded={
              advancedSearchIsOpen || advancedSearchStorage.isOpen
                ? 'true'
                : 'false'
            }
          >
            <VisuallyHidden>
              <Trans>Open advanced search</Trans>
            </VisuallyHidden>
            <IconPlus />
          </button>
        )}
      </div>
      <AdvancedSearch
        advancedSearchIsOpen={
          advancedSearchIsOpen || advancedSearchStorage.isOpen
        }
        searchTermFieldRef={searchTermInputRef}
        advancedSearchOptions={
          availableSearches?.[activeDatabase]?.['fields'] ??
          advancedSearchOptions
        }
        setSearchTerm={setSearchTerm}
        activeDatabase={activeDatabase}
      />
    </StyledSearchBox>
  );
}

export default withSearch(
  ({
    searchTerm,
    setSearchTerm,
    filters,
    setFilter,
    advancedSearchOptions,
  }) => ({
    searchTerm,
    setSearchTerm,
    filters,
    setFilter,
    advancedSearchOptions,
  })
)(SearchBox);
