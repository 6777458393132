import color from 'styles/base/variables/color';

const unstyledList = function () {
  return `
    margin: 0;
    padding: 0;

    &:not([type]) {
      // Removing list bullets with "list-style: none" removes <ul/ol> semantics in VoiceOver.
      list-style: none;

      > li {
        // Adding zero-width space fixes the "list-style: none" accessibility issue.
        &:before {
          content: "";
          position: absolute;
        }
      }
    }
  `;
};

const styledUl = function () {
  return `
    padding-left: 1.25em;

    &:not([type]) {
      list-style: disc;
    }

    > li {
      display: list-item;

      ::marker {
        color: ${color.yellow};
      }

      &:before {
        content: none;
      }
    }
  `;
};

const styledOl = function () {
  return `
    padding-left: 1.25em;

    &:not([type]) {
      list-style: decimal;
    }

    > li {
      display: list-item;

      ::marker {
        color: currentColor;
      }

      &:before {
        content: none;
      }
    }
  `;
};

export { unstyledList, styledUl, styledOl };
