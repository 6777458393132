import { useState } from 'react';
import styled from 'styled-components';
// import LocalizedLink from 'components/helpers/LocalizedLink';
import { Trans, t } from '@lingui/macro';
import { IconLogOut, IconUser } from 'components/graphical/Icons';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import border from 'styles/base/variables/border';
import borderRadius from 'styles/base/variables/borderRadius';
import { fontSize } from 'styles/base/variables/typography';
import { bp, bpAsNumber, mq } from 'styles/base/variables/mediaQueries';
import OutsideClick from 'components/helpers/OutsideClick';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import { focus } from 'styles/base/helpers/focus';
import useUserContext from 'hooks/useUserContext';
import { Media } from 'react-breakpoints';
import Loading from 'components/misc/Loading';
import LoginLink from 'components/misc/LoginLink';

const StyledUserMenu = styled.nav`
  display: block;

  ${mq(bp.mobileMenu)} {
    // To position sub-menu.
    position: relative;
  }

  .stripe--top-left {
    height: 6rem;
    min-width: 6rem;
    ${alignIconDefaults(`${13 / 16}em`)};
    justify-content: center;

    // To position "border-top".
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 6rem;
      height: 0.4rem;
      background-color: ${color.yellow};
      left: 0;
      top: 0;
      transition: all ${easing.normal};
      z-index: -1;
    }
  }

  button {
    &.stripe--top-left {
      &.open {
        &:before {
          top: calc(100% - 0.4rem);
        }
      }
    }
  }

  a {
    &.stripe--top-left {
      &:hover {
        &:before {
          width: 100%;
        }
      }
    }
  }

  .user--name {
    ${mq(0, bp.mobileMenu)} {
      ${alignIconDefaults()};
      margin-bottom: 1.6rem;
    }
  }

  // Sub-menu.
  ul {
    ${mq(0, bp.mobileMenu)} {
      border-top: ${border.dimmed};
      font-weight: 700;
    }

    ${mq(bp.mobileMenu)} {
      width: 100%;
      min-width: 13rem;
      position: absolute;
      left: 0;
      z-index: 1;
      background-color: ${color.paper};
      border-radius: 0 ${borderRadius.small} ${borderRadius.small}
        ${borderRadius.small};
      overflow: hidden;
      font-size: ${fontSize.text.smaller};
      font-weight: 400;

      &:not(.open) {
        display: none;
      }
    }

    li {
      display: block;

      a {
        ${alignIconDefaults()};
        width: 100%;
        transition: all ${easing.normal};

        ${mq(0, bp.mobileMenu)} {
          padding: 1.6rem 0;
        }

        ${mq(bp.mobileMenu)} {
          padding: 1.3rem 2rem;
          ${focus} {
            outline-offset: -0.5rem;
          }
        }

        &:hover {
          ${mq(bp.mobileMenu)} {
            background-color: ${color.anthracite};
            color: ${color.white};

            // Overlap next items "border-top".
            box-shadow: 0 1px 0 0 ${color.anthracite};
          }
        }
      }

      + li {
        ${mq(0, bp.mobileMenu)} {
          border-top: ${border.dimmed};
        }

        ${mq(bp.mobileMenu)} {
          // To position "border-top".
          position: relative;

          // "Border-top".
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: calc(100% - 4rem);
            left: 2rem;
            top: 0;
            height: 1px;
            background-color: currentColor;
          }
        }
      }
    }
  }
`;

function MenuUser() {
  // Toggle sub-menu.
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };
  // Close on OutsideClick.
  // Note that we don't need to close on esc-key,
  // since this is part of OutsideClick.
  const itemHandleOutsideClick = () => {
    setItemIsOpen(false);
  };

  const user = useUserContext();

  // Show the user menu after the userContext has been filled.
  return user.authenticated === null ? (
    <StyledUserMenu>
      <span className="stripe--top-left">
        <Loading spinnerSize="2rem" hiddenText={t`Loading...`} />
      </span>
    </StyledUserMenu>
  ) : (
    <StyledUserMenu>
      {!user.authenticated ? (
        // Not logged-in.
        <Media>
          {({ breakpoints, currentBreakpoint }) =>
            breakpoints[currentBreakpoint] < bpAsNumber.mobileMenu ? (
              // Mobile only.
              <ul>
                <li>
                  <LoginLink>
                    <IconUser />
                    <span className="text">
                      <Trans>Login</Trans>
                    </span>
                  </LoginLink>
                </li>
              </ul>
            ) : (
              // Desktop only.
              <LoginLink className="btn stripe--top-left">
                <IconUser />
                <span className="text font-normal">
                  <Trans>Login</Trans>
                </span>
              </LoginLink>
            )
          }
        </Media>
      ) : (
        // Logged-in.
        <OutsideClick onOutsideClick={itemHandleOutsideClick}>
          <Media>
            {({ breakpoints, currentBreakpoint }) =>
              breakpoints[currentBreakpoint] < bpAsNumber.mobileMenu ? (
                <p className="user--name">
                  <IconUser />
                  <span className="text font-normal">{user.display_name}</span>
                </p>
              ) : (
                // Desktop only.
                <button
                  className={`btn btn-reset stripe--top-left ${
                    itemIsOpen ? 'open' : ''
                  }`}
                  onClick={itemHandleClick}
                  aria-expanded={itemIsOpen ? 'true' : 'false'}
                >
                  <IconUser />
                  <VisuallyHidden>
                    <Trans>User menu</Trans>
                  </VisuallyHidden>
                  <span className="text font-normal">{user.display_name}</span>
                </button>
              )
            }
          </Media>
          <ul className={itemIsOpen ? 'open' : ''}>
            {/*
            <li>
              <LocalizedLink to="/">
                <Trans>My profile</Trans>
              </LocalizedLink>
            </li>
            */}
            <li>
              <a href="/api/logout">
                <IconLogOut />
                <span className="text">
                  <Trans>Log out</Trans>
                </span>
              </a>
            </li>
          </ul>
        </OutsideClick>
      )}
    </StyledUserMenu>
  );
}

export default MenuUser;
