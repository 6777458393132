import { Link } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';
import { useLingui } from '@lingui/react';

function LocalizedLink({ to, ...rest }) {
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;

  const localizedTo = getLocalizedPath(to, activeLocale);
  return <Link to={localizedTo} {...rest} />;
}

export default LocalizedLink;
