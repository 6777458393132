import styled from 'styled-components';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { t, Trans } from '@lingui/macro';
import color from 'styles/base/variables/color';
import { IconTrash } from 'components/graphical/Icons';
import CheckboxAsButton from 'components/graphical/CheckboxAsButton';
import Pipe from 'components/graphical/Pipe';
import borderRadius from 'styles/base/variables/borderRadius';
import TripleIfImage from 'components/misc/TripleIfImage';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import Loading from 'components/misc/Loading';
import useApiWithBodyContent from 'hooks/useApiWithBodyContent';

const StyledSelected = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .visual {
    width: 15rem;
    aspect-ratio: 1/1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    background-color: ${color.white};
    padding: 1rem 1.6rem;
    border-radius: ${borderRadius.full};
    transform: translateY(-50%);
    // Compensate half the height (40px) for -50% transform.
    margin-bottom: -2rem;

    .btn-select {
      .square {
        width: 2rem;
      }
    }
  }
`;

function Selected({
  isSelectedSnackBarItem,
  selectSnackBarItem,
  removeSnackBarItem,
  id,
}) {
  const isSelected = isSelectedSnackBarItem(id);
  let image = false;
  let title = '';

  const {
    isLoading,
    isError,
    data: [cards],
  } = useApiWithBodyContent(
    [
      {
        path: '/cards',
        body: [id],
      },
    ],
    [false]
  );

  if (!isLoading && !isError && cards) {
    image = cards[id].image;
    title = getLocalizedValueFromMls(cards[id].title);
  }

  return (
    <StyledSelected>
      <div className="visual">
        {isLoading && <Loading spinnerSize="3rem" hiddenText={t`Loading...`} />}
        {image && (
          <TripleIfImage
            baseUrl={image.src}
            alt={getLocalizedValueFromMls(image.alt)}
            width={320}
            height={320}
          />
        )}
      </div>
      <div className="actions">
        <CheckboxAsButton
          buttonOnClick={() => selectSnackBarItem(id)}
          buttonClassName={`btn-reset btn-select ${isSelected ? 'active' : ''}`}
        >
          <VisuallyHidden>
            {isSelected ? (
              <Trans>Deselect {title}</Trans>
            ) : (
              <Trans>Select {title}</Trans>
            )}
          </VisuallyHidden>
        </CheckboxAsButton>
        <Pipe />
        <button
          onClick={() => removeSnackBarItem(id)}
          className="btn-reset btn-remove"
        >
          <VisuallyHidden>
            <Trans>Remove {title} from selection</Trans>
          </VisuallyHidden>
          <IconTrash />
        </button>
      </div>
    </StyledSelected>
  );
}

export default Selected;
