import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { getLocalizedPath } from 'utils/localizedRoutes';
import BasicPage from 'components/layout/BasicPage';
import BodyText from 'components/misc/BodyText';

function ArtArchitectureThesaurus() {
  return (
    <>
      <Helmet>
        <title>{t`Art & Architecture Thesaurus (AAT)`}</title>
      </Helmet>
      <BasicPage>
        <BodyText>
          <h1>{t`Art & Architecture Thesaurus (AAT)`}</h1>
          <p>
            <Trans>
              The <a href="https://www.getty.edu/research/tools/vocabularies/aat/index.html" target="_blank" rel="noopener noreferrer">Art & Architecture Thesaurus®</a> (AAT) is a poly-hierarchically structured list of terms which is used all over the world to facilitate searching within architectural, art and historical collections. The AAT was originally created in English and is maintained by the Getty Research Institute. The AAT has since been partially translated into a number of other languages, including Dutch.
            </Trans>
          </p>
          <p>{t`The thesaurus is now used by many heritage institutions when digitising their collections. It is furthermore the standard tool to make collection data available in several languages for umbrella projects such as Europeana, and it is an important part of the semantic layer. The facets of the thesaurus provide classification descriptors for heritage objects, including materials used and physical condition. But it can also be employed to create subject indices for libraries and visual resource collections.`}</p>
          <h2>{t`Development of the Dutch AAT`}</h2>
          <p>{t`Starting in the 1970s, the AAT was developed in the United States to enhance cataloguing and indexing procedures in the art world. In 1994, on the initiative of RKD and in collaboration with various other organisations, work began on making a Dutch language version of the AAT. With the help of grants from the Netherlands as well as Flanders, a complete translation of the basic thesaurus was finished in 2007.`}</p>
          <p>{t`Because the AAT is constantly being updated and extended, the task of translation needed to continue. Until 2020 this work was managed by a team of Dutch and Flemish editors, and the RKD hired a professional translation agency to provide translations of AAT vocabularies. From 2021, this translation work will be undertaken mainly by the editors themselves, who will be collaborating with specialised working groups from the Dutch and Flemish heritage sector.`}</p>
          <p>{t`Besides translating, the editors can also propose new terms for addition to the thesaurus. In this way, hundreds of terms that are particularly important to the Dutch heritage sector have in recent years been added to the AAT.`}</p>
          <h2>{t`Terms of Use`}</h2>
          <p>
            <Trans>
              The AAT is part of the Getty Vocabularies. Getty Vocabulary data are available under an <a href="https://opendatacommons.org/licenses/by/1-0/" target="_blank" rel="noopener noreferrer">Open Data Commons Attribution License (ODC-By) 1.0</a>. <a href="https://www.getty.edu/research/tools/vocabularies/obtain/index.html" target="_blank" rel="noopener noreferrer">This page</a> contains more information about how to obtain and use the Getty Vocabulary datasets.
            </Trans>
          </p>
          {i18n.locale === 'nl' && (
            <>
              <p>
                <a href={getLocalizedPath('/search-the-aat')}>Zoeken in de AAT</a>
              </p>
              <p>
                <a href={getLocalizedPath('/contributions-to-the-aat')}>Bijdragen aan de AAT</a>
              </p>
            </>
          )}
        </BodyText>
      </BasicPage>
    </>
  );
}

export default ArtArchitectureThesaurus;
