import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Home from 'routes/Home';
import Page from './components/layout/Page';
import NotFound from './components/misc/NotFound';
import { useEffect } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { t } from '@lingui/macro';
import { locales, defaultLocale, dynamicActivateLocale } from 'utils/i18n';
import { localizedRoutes } from 'utils/localizedRoutes';
import RkdresearchSearchProvider from 'search-ui/RkdresearchSearchProvider';
import { Helmet } from 'react-helmet';
import { UserContextProvider } from './context/UserContext';
import { SnackBarContextProvider } from './context/SnackBarContext';
import { AnalyticsContextProvider } from './context/AnalyticsContext';
import { ConsentContextProvider } from './context/ConsentContext';
import { AdvancedSearchContextProvider } from './context/AdvancedSearchContext';

/**
 * Route every supported language to the LocaleRouter. Any path not starting
 * with a valid localeCode is handled by LocaleRedirect.
 */
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {Object.keys(locales).map((localeCode) => (
          <Route
            key={localeCode}
            path={`/${localeCode}/*`}
            element={<LocaleRouter localeCode={localeCode} />}
          />
        ))}
        <Route path="*" element={<LocaleRedirect />} />
      </Routes>
    </BrowserRouter>
  );
}

/**
 * Apply the i18n provider and register routes. A so-called "layout route" (see
 * https://reactrouter.com/docs/en/v6/getting-started/concepts#layout-routes) is
 * used to wrap the route output in a <Page> container.
 */
function LocaleRouter({ localeCode }) {
  useEffect(() => {
    document.documentElement.lang = localeCode;
    dynamicActivateLocale(localeCode);
  });

  return (
    <SnackBarContextProvider>
      <AdvancedSearchContextProvider>
        <I18nProvider i18n={i18n}>
          <Helmet
            defaultTitle={t`RKD Research`}
            titleTemplate={t`RKD Research | %s`}
          />
          <ConsentContextProvider>
            <UserContextProvider>
              <RkdresearchSearchProvider>
                <AnalyticsContextProvider>
                  <Routes>
                    <Route element={<Page />}>
                      <Route index element={<Home />} />
                      {localizedRoutes.map((route) => {
                        // Every <Route> needs a unique key per langCode/route combination,
                        // otherwise ReactRouter gets confused when the locale changes.
                        const Element = route.element;
                        return (
                          <Route
                            key={`${localeCode}:${route.path[localeCode]}`}
                            path={route.path[localeCode]}
                            element={<Element />}
                          />
                        );
                      })}
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  </Routes>
                </AnalyticsContextProvider>
              </RkdresearchSearchProvider>
            </UserContextProvider>
          </ConsentContextProvider>
        </I18nProvider>
      </AdvancedSearchContextProvider>
    </SnackBarContextProvider>
  );
}

/**
 * Redirect a language-less path to the same path under the default localeCode.
 */
function LocaleRedirect() {
  const navigate = useNavigate();
  const { '*': routePath } = useParams();

  const matchLocale = () => {
    const navigatorLanguage = navigator?.language?.substring(0, 2) ?? '';
    return navigatorLanguage in locales ? navigatorLanguage : defaultLocale;
  };

  useEffect(() => {
    const redirectPath = matchLocale() + (routePath ? `/${routePath}` : '');
    // Calling navigate() must be done in a useEffect hook. Use replace:true to
    // avoid visitors navigating back to the language-less path.
    navigate(redirectPath, { replace: true });
  });

  return null;
}

export default App;
