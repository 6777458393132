import styled from 'styled-components';
import slugify from 'slugify';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import color from 'styles/base/variables/color';
import border from 'styles/base/variables/border';
import borderRadius from 'styles/base/variables/borderRadius';

const StyledInPageMenu = styled.nav`
  ul {
    border-left: ${border.dimmed};

    li {
      padding: 1.5rem 2rem;
      display: block;

      &.active {
        font-weight: 700;

        // To position "dot" on the left.
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 1.1rem;
          height: 1.1rem;
          background-color: currentColor;
          border-radius: ${borderRadius.full};
          position: absolute;
          left: 0rem;
          transform: translate(-50%, 50%);
          box-shadow: 0 0 0 1.5rem ${color.white};
        }
      }
    }
  }
`;

function MenuInPage({ sectionLabels }) {
  return (
    <StyledInPageMenu>
      <ul>
        {sectionLabels.map((sectionLabel, i) => {
          return (
            <li
              key={i}
              className={
                window.location.hash ===
                `#${slugify(getLocalizedValueFromMls(sectionLabel))}`
                  ? 'active'
                  : 'no'
              }
            >
              <a href={`#${slugify(getLocalizedValueFromMls(sectionLabel))}`}>
                {getLocalizedValueFromMls(sectionLabel)}
              </a>
            </li>
          );
        })}
      </ul>
    </StyledInPageMenu>
  );
}

export default MenuInPage;
