import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  fontFamily,
  fontSize,
  lineHeight,
  letterSpacing,
} from 'styles/base/variables/typography';
import color from 'styles/base/variables/color';
import border from 'styles/base/variables/border';
import SkipLink from 'components/navigation/SkipLink';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { focus } from 'styles/base/helpers/focus';
import inputTextual from 'styles/base/helpers/inputTextual';
import respValue from 'styles/base/helpers/respValue';
import focusStyling from 'styles/base/helpers/focusStyling';
import {
  unstyledList,
  styledUl,
  styledOl,
} from 'styles/base/helpers/listStyling';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import borderRadius from 'styles/base/variables/borderRadius';
import easing from 'styles/base/variables/easing';
import animations from 'styles/base/variables/animations';
import PageHeader from 'components/landmarks/PageHeader';
import PageFooter from 'components/landmarks/PageFooter';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import size from 'styles/base/variables/size';
import SvgPointerDown from 'assets/svg/pointer-down.svg';
import SnackBar from 'components/misc/SnackBar';
import useSnackBarContext from 'hooks/useSnackBarContext';
import zIndex from 'styles/base/variables/zIndex';
import ConsentModal from 'components/misc/ConsentModal';
import LiblynxAnalytics from 'components/misc/LiblynxAnalytics';
import useConsentContext from 'hooks/useConsentContext';
import { isFullWidthRoute } from 'utils/fullWidthRoutes';

const GlobalStyle = createGlobalStyle`
  /* Box-sizing. */
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* Normalize addition. */
  body {
    margin: 0;
  }

  /* Basic typography. */
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  .text--body {
    font-family: ${fontFamily.roboto};
    ${respValue(
      'font-size',
      fontSize.text.default.desktop,
      fontSize.text.default.mobile
    )};
    line-height: ${lineHeight.default};
    font-weight: 300;
  }

  body {
    background-color: ${color.white};
    color: ${color.anthracite};
  }

  .text--content-inline {
    .thesaurus-explainer {
      display: inline-block;
      margin: 0 0.4em 0.4em 0;
    }
  }

  /* Layout. */
  html {
    scroll-behavior: smooth;
    height: 100%;
  }
  body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  #root {
    height: 100%;
  }

  .hidden {
    display: none;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    font-weight: 700;
    margin: 0;
    letter-spacing: ${letterSpacing.heading};
  }

  h1,
  .h1 {
    ${respValue(
      'font-size',
      fontSize.heading.h1.desktop,
      fontSize.heading.h1.mobile
    )};
    line-height: ${lineHeight.heading.h1};
  }

  h2,
  .h2 {
    ${respValue(
      'font-size',
      fontSize.heading.h2.desktop,
      fontSize.heading.h2.mobile
    )};
    line-height: ${lineHeight.heading.h2};
  }

  h3,
  .h3 {
    font-size: ${fontSize.heading.h3};
    line-height: ${lineHeight.heading.h3};
  }

  h4,
  .h4 {
    font-size: ${fontSize.heading.h4};
    line-height: ${lineHeight.heading.h4};
  }

  .font-smaller {
    font-size: ${fontSize.text.smaller};
  }

  .font-larger {
    ${respValue(
      'font-size',
      fontSize.text.larger.desktop,
      fontSize.text.larger.mobile
    )};
  }

  .font-light {
    font-weight: 300;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-thick {
    font-weight: 700;
  }

  p {
    margin: 0;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  nav {
    ul {
      li {
        display: inline-block;

        a {
          display: inline-block;
        }
      }
    }
  }

  ul,
  ol {
    ${unstyledList};
  }

  .unstyled-list {
    ${unstyledList};
  }

  .styled-ul {
    ${styledUl};
  }

  .styled-ol {
    ${styledOl};
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  img,
  video {
    display: block;
    max-width: 100%;
    height: auto;
    font-size: smaller;
  }

  svg {
    fill: currentColor;
    display: flex;
    height: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    caption {
      margin-bottom: 1.5rem;
      text-align: left;
      font-weight: 700;
    }
    th {
      border: ${border.dimmed};
      padding: 1rem 1.5rem;
      text-align: left;
      vertical-align: top;
    }
    td {
      border: ${border.dimmed};
      padding: 1rem 1.5rem;
      text-align: left;
      vertical-align: top;
    }
  }

  details {
    summary {
      > * {
        display: inline;
      }
    }
  }

  // Focus.
  a,
  button,
  input,
  select,
  textarea,
  [type="button"],
  [tabindex]:not([tabindex="-1"]),
  [contenteditable] {
    &:focus {
      &:focus-visible {
        ${focusStyling};
      }

      // This is probably not needed,
      // but explicitly override outline to make sure
      // there is no outline when using a mouse.
      &:not(:focus-visible) {
        outline: 0;
      }
    }
  }

  [tabindex='-1'] {
    &:focus {
      outline: 0;
    }
  }

  *:focus:not(:focus-visible) {
    outline: 0;
  }

  // Buttons.
  button {
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-align: left;
    white-space: break-spaces;

    &[disabled] {
      opacity: 0.6;
      cursor: default;
    }
  }

  .btn-reset {
    border: none;
    touch-action: manipulation;
    cursor: pointer;
    border-radius: 0px;
    user-select: none;
    background-color: transparent;
    color: currentColor;
    padding: 0;
  }

  .btn {
    ${alignIconDefaults()};
    padding: ${size.buttonPaddingBlock} ${size.buttonPaddingInline};
    border-radius: ${borderRadius.small};
    font-family: inherit;
    font-size: ${fontSize.text.smaller};
    font-weight: 500;
    transition: all ${easing.normal};

    .icon, .text {
      transition: all ${easing.normal};
    }
  }

  .btn-rounded {
    border-radius: ${borderRadius.full};
  }

  .btn-small {
    padding: 0.7em 1.6em 0.525em 1.6em;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .btn-disabled {
    border: 0.1rem solid transparent;
    background-color: ${color.anthraciteDimmed20};
    color: ${color.anthraciteDimmed60};
  }

  .btn-on-dark {
    ${focus} {
      outline-color: ${color.white};
    }
  }

  .btn-on-light {
    ${focus} {
      outline-color: ${color.anthracite};
    }
  }

  .btn-ghost-white {
    background-color: transparent;
    border: 0.1rem solid currentColor;
    color: ${color.white};

    &:hover {
      background-color: ${color.white};
      border-color: transparent;
      color: ${color.anthracite};
    }
  }

  .btn-ghost-black {
    background-color: transparent;
    border: 0.1rem solid currentColor;
    color: ${color.anthracite};

    &:hover {
      background-color: ${color.anthracite};
      border-color: transparent;
      color: ${color.white};
    }
  }

  .btn-yellow {
    border: 0.1rem solid transparent;
    background-color: ${color.yellow};
    color: ${color.anthracite};

    &:hover {
      background-color: ${color.white};
      border-color: currentColor;
    }
  }

  .btn-black {
    border: 0.1rem solid transparent;
    background-color: ${color.anthracite};
    color: ${color.white};

    &:hover {
      background-color: ${color.white};
      color: ${color.anthracite};
      border-color: currentColor;
    }
  }

  .btn-white {
    border: 0.1rem solid transparent;
    background-color: ${color.white};
    color: ${color.anthracite};

    &:hover {
      background-color: ${color.anthracite};
      color: ${color.white};
      border-color: currentColor;
    }
  }

  .actions--outer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  // Forms.
  ${inputTextual()},
  textarea,
  select {
    width: 100%;
    background-color: transparent;
    color: currentColor;
    font-family: inherit;
    font-size: inherit;
    appearance: none;
    border-radius: 0;
  }

  ${inputTextual()},
  textarea {
    &::-moz-placeholder {
      opacity: 1;
      color: ${color.boulderDimmed50};
    }
    &::placeholder {
      opacity: 1;
      color: ${color.boulderDimmed50};
    }
  }

  ${inputTextual()},
  select {
    border: ${border.default};
    border-radius: ${borderRadius.small};
    padding: 0.6em 1em;
  }

  textarea {
    border: ${border.default};
    border-radius: ${borderRadius.small};
    padding: 1em;
  }

  select {
    font-weight: 500;
    color: inherit;
    background-image: url(${SvgPointerDown});
    background-repeat: no-repeat;
    background-position: calc(100% - 1em) center;
    background-size: 0.7em;
    padding-right: 2.5em;
  }

  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    cursor: pointer;
  }

  label[for] {
    font-weight: 700;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.25em;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;

    legend {
      font-size: 2.6rem;
      letter-spacing: ${letterSpacing.heading};
      padding: 0;
    }
  }

  .form-item {
    + .form-item {
      margin-top: 2.4rem;
    }
  }

  .form-actions {
    margin-top: 3.2rem;
  }


  @media print {
    @page {
      margin: 2cm;
    }

    .page {
      display: unset;
    }

    // Landmarks.
    .page--header {
      padding-bottom: 1cm;

      .header--inner {
        > .aside {
          .branding {
            svg {
              width: 5.1cm;
              width: 2.88cm;
            }
          }
          .menu-toggler {
            display: none;
          }
        }

        > .main {
          display: none;
        }
      }
    }

    .page--main {
      // Search results page.
      .content--header {
        .actions {
          display: none;
        }
      }

      .content--main {
        display: block;

        // Search results page.
        > .aside {
          display: none;
        }

        // Also applies to visual-search's results.
        .search-results {
          ol {
            display: flex;
            flex-wrap: wrap;

            > li {
              width: 30%;
              break-inside: avoid;

              > button {
                display: none;
              }
            }
          }
        }

        // Artworks detail.
        > .aside--nav {
          display: none;
        }

        > .aside--content {
          .aside--content-inner {
            > nav {
              display: none;
            }
          }
        }

        > .main--visual {
          page-break-after: always;
          margin-top: 0.5cm;

          .snackbar-toggler {
            display: none;
          }

          .main-strip {
            img {
              width: unset;
              height: unset;
              object-fit: unset;
              margin: unset;
            }

            &.no-print {
              display: none;
            }
          }

          .selection-strip {
            display: none;
          }

          .actions {
            display: none;
          }
        }

        > .main--textual {
          background-color: transparent;
          box-shadow: unset;
          margin-top: 0.5cm;

          > * {
            break-inside: avoid;
            padding: 0.5cm;
            border-width: 1pt;

            + * {
              margin-top: 0.25cm;
            }

            > .heading {
              margin-bottom: 0.25cm;

              svg {
                display: none;
              }
            }

            > .content {
              display: unset;

              > * {
                > * {
                  + * {
                    margin-top: 0.25cm;
                  }

                  dd {
                    margin-top: 0.25cm;
                  }
                }
              }
            }
          }
        }
      }

      // My selections.
      .content--header {
        .content--header--textual--editable {
          margin-bottom: 1cm;
        }
      }

      .content--main {
        .content--main--inner {
          .actions {
            display: none;
          }

          .collection-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1cm;

            > * {
              width: 40%;

              // Hide "New selection".
              &:first-child {
                display: none;
              }

              .tiles {
                box-shadow: 0 0 0 15cm inset ${color.paper};
              }
            }
          }

          .collection-items {
            margin-top: 1cm;

            > ul {
              display: flex;
              flex-wrap: wrap;

              > li {
                width: 30%;
                break-inside: avoid;
                border-bottom: unset;
                padding-bottom: unset;

                .collection-item-menu {
                  display: none;
                }
              }
            }
          }
        }
      }

      .content--header {
        .content--header--nav {
          display: none;
        }
      }

      // Visual search.
      .results--header--visual-search {
        padding: 0.5cm;
        box-shadow: 0 0 0 15cm inset ${color.paper};
        margin-bottom: 1.5cm;

        .header--main {
          display: flex;
          align-items: center;
          gap: 0.5cm;

          .visual {
            width: 5cm;
          }

          .visual-search-file-upload {
            display: none;
          }
        }
      }
    }

    .page--footer {
      padding-top: 1cm;

      .footer--inner {
        > .visual {
          display: none;
        }

        > nav {
          display: none;
        }
      }
    }

    .page--snackbar {
      display: none;
    }

    // Homepage.
    .hero {
      margin-bottom: 2cm;

      .hero--inner {
        .visual {
          position: relative;
          height: 5cm;
        }

        .textual {
          color: unset;
          background-color: transparent;
          padding: unset;
          margin-top: 0.5cm;

          .heading {
            font-size: 36pt;
          }
        }
      }
    }

    .tiles-list {
      display: flex;

      .tile {
        max-width: 5cm;

        .visual {
          position: relative;
          margin-left: unset;
        }

        .textual {
          margin-top: 0.25cm;

          .icon {
            display: none;
          }
        }

        &.dark {
          .textual {
            color: unset;
          }
        }
      }
    }

    // Buttons.
    .btn-yellow {
      box-shadow: 0 0 0 15cm inset ${color.yellow};
    }

    .btn-black {
      box-shadow: 0 0 0 15cm inset ${color.anthracite};
    }

    .btn-ghost-black {
      border: unset;
      box-shadow: 0 0 0 1pt inset ${color.anthracite};
    }
  }
`;

const StyledPage = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }

  > main {
    flex-grow: 1;
  }

  > .page--snackbar {
    position: sticky;
    inset: 0;
    top: auto;
    z-index: ${zIndex.snackBar};

    // Visually position as last item of the page.
    order: 1;
  }

  &:not(.main-navigation--open) {
    .page--header .header--top {
      ${mq(0, bp.mobileMenu)} {
        display: none;
      }
    }
  }

  &.main-navigation--open {
    .page--header .header--search,
    .page--main,
    .page--snackbar,
    .page--footer {
      ${mq(0, bp.mobileMenu)} {
        display: none;
      }
    }
  }

  ${animations.rotate}
`;

function Page() {
  const snackBar = useSnackBarContext();
  const consent = useConsentContext();

  // Toggle main-navigation.
  const [mainNavIsOpen, setMainNavIsOpen] = useState(false);
  const mainNavHandleClick = () => {
    setMainNavIsOpen(!mainNavIsOpen);
  };
  // Close main-navigation when location changes.
  const location = useLocation();
  useEffect(() => {
    setMainNavIsOpen(false);
  }, [location]);
  // Close main-navigation on esc-key.
  const mainNavHandleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setMainNavIsOpen(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledPage
        className={`page ${mainNavIsOpen ? 'main-navigation--open' : ''} ${
          isFullWidthRoute(useLocation())
            ? 'page--full-width'
            : 'page--limited-width'
        }`}
      >
        <SkipLink />
        <PageHeader
          mainNavHandleKeyDown={mainNavHandleKeyDown}
          mainNavHandleClick={mainNavHandleClick}
          mainNavIsOpen={mainNavIsOpen}
        />
        <main id="main-content" className="page--main">
          <Outlet />
        </main>
        <ConsentModal />
        {consent.consentStatus.tracking === true && <LiblynxAnalytics />}
        {snackBar.hasItems() && (
          <div className="page--snackbar">
            <SnackBar />
          </div>
        )}
        <PageFooter />
      </StyledPage>
    </>
  );
}

export default Page;
