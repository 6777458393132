import styled from 'styled-components';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { t, plural } from '@lingui/macro';
import { IconCheck } from 'components/graphical/Icons';
import Disc from 'components/graphical/Disc';
import border from 'styles/base/variables/border';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import BodyText from 'components/misc/BodyText';
import { unstyledList } from 'styles/base/helpers/listStyling';

const StyledSubmissionMessage = styled.div`
  .message {
    ${alignIconDefaults()};

    .disc {
      flex-shrink: 0;
      width: 2rem;
      border: ${border.default};
      border-width: 0.2rem;
    }
  }

  .collections {
    ${unstyledList};

    li {
      &:not(:last-child) {
        &:after {
          content: ',';
          margin-right: 0.2em;
        }
      }
    }

    + * {
      margin-top: 1em;
    }
  }
`;

function SubmissionMessage({
  data: { errors, addedCount, hasDuplicates, collections },
}) {
  if (errors === false && addedCount > 0) {
    return (
      <StyledSubmissionMessage>
        <BodyText>
          <p className="message font-thick">
            <Disc className="disc">
              <IconCheck />
            </Disc>
            <span>
              {plural(addedCount, {
                one: '# item added to: ',
                other: '# items added to: ',
              })}
            </span>
          </p>
          <ul className="collections">
            {collections.map((collection) => (
              <li key={collection.id}>
                <LocalizedLink to={`/my-selections/${collection.id}`}>
                  {collection.name}
                </LocalizedLink>
              </li>
            ))}
          </ul>
          {hasDuplicates && (
            <p className="font-smaller">{t`Duplicates have been skipped.`}</p>
          )}
        </BodyText>
      </StyledSubmissionMessage>
    );
  } else if (errors === true) {
    return t`An error has occured.`;
  }

  return null;
}

export default SubmissionMessage;
