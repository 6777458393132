import styled from 'styled-components';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import size from 'styles/base/variables/size';

const gap = (props) => props.gap ?? `4rem ${size.gridGutter}`;

const ListCols = styled.div`
  display: grid;
  gap: ${gap};

  ${mq(bp.small, bp.medium)} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mq(bp.medium)} {
    grid-template-columns: repeat(${(props) => props.cols}, 1fr);
  }

  ${props => props.cols === '4' && `
    ${mq(bp.medium, bp.large)} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${mq(bp.large, bp.extraLarge)} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${mq(bp.extraLarge)} {
      grid-template-columns: repeat(4, 1fr);
    }
  `}
`;

export default ListCols;
