import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import FocusTrap from 'focus-trap-react';
import GridContainer from 'components/layout/GridContainer';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import SpanGridCols from 'components/layout/SpanGridCols';
import LocalizedLink from 'components/helpers/LocalizedLink';
import MenuLanguage from 'components/navigation/MenuLanguage';
import MenuHeader from 'components/navigation/MenuHeader';
import MenuUser from 'components/navigation/MenuUser';
import HeaderBanner from 'components/misc/HeaderBanner';
import { LogoRkdResearch } from 'components/graphical/LogoRKD';
import { IconBurger } from 'components/graphical/Icons';
import border from 'styles/base/variables/border';
import { bp, bpAsNumber, mq } from 'styles/base/variables/mediaQueries';
import { Media } from 'react-breakpoints';
import size from 'styles/base/variables/size';
import respValue from 'styles/base/helpers/respValue';
import { isFullWidthRoute } from 'utils/fullWidthRoutes';
import { useLocation } from 'react-router-dom';
import SearchBox from 'components/search/SearchBox';
import SearchActions from 'components/search/SearchActions';
import color from 'styles/base/variables/color';

const StyledPageHeader = styled.header`
  ${respValue('margin-bottom', '6rem')}

  .header--inner {
    ${mq(bp.mobileMenu)} {
      display: flex;
      justify-content: space-between;
    }

    .aside {
      ${mq(0, bp.mobileMenu)} {
        display: flex;
        justify-content: space-between;
      }

      ${mq(bp.mobileMenu)} {
        margin-right: ${size.gridGutter};
      }

      ${mq(bp.extraLarge)} {
        width: ${SpanGridCols(3)};
      }
    }

    .main {
      ${mq(0, bp.mobileMenu)} {
        margin-top: 4rem;
      }

      ${mq(bp.mobileMenu, bp.extraLarge)} {
        flex-grow: 1;
      }

      ${mq(bp.extraLarge)} {
        width: ${SpanGridCols(9)};
      }

      .page--full-width & {
        ${mq(bp.medium, bp.mobileMenu)} {
          padding-left: ${size.pagePadding};
        }
      }
    }

    .menu-toggler {
      ${mq(0, bp.mobileMenu)} {
        align-self: flex-start;
        margin-top: 2rem;
      }
      ${mq(bp.mobileMenu)} {
        display: none;
      }
    }

    .branding {
      ${mq(0, bp.small)} {
        margin-left: -${size.pagePadding};
      }

      a {
        display: inline-flex;
      }

      svg {
        height: auto;

        ${mq(0, bp.small)} {
          width: 15rem;
        }

        ${mq(bp.small, bp.medium)} {
          width: 20rem;
        }
      }
    }

    .header--top {
      ${mq(bp.mobileMenu)} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      > * {
        flex-shrink: 0;

        + * {
          ${mq(0, bp.mobileMenu)} {
            border-top: ${border.dimmed};
          }

          ${mq(bp.mobileMenu)} {
            margin-left: 2rem;
          }
        }
      }
    }
  }

  .header--suffix {
    background-color: ${color.paper};
    padding-block: 1.2rem;
    text-align: center;

    .page--full-width & {
      ${mq(bp.medium)} {
        padding-inline: ${size.gridFullPagePadding};
      }
    }
  }
`;

function PageHeader({
  mainNavHandleClick,
  mainNavIsOpen,
  mainNavHandleKeyDown,
}) {
  return (
    <StyledPageHeader
      className={`page--header ${mainNavIsOpen ? 'open' : ''}`}
      onKeyDown={mainNavHandleKeyDown}
    >
      <FocusTrap active={mainNavIsOpen}>
        <div className="header--outer">
          <div className="header--suffix">
            <GridContainer full={isFullWidthRoute(useLocation())} className={``}>
              <HeaderBanner />
            </GridContainer>
          </div>
          <GridContainer full={isFullWidthRoute(useLocation())} className={``}>
            <div className="header--inner">
              <div className="aside">
                <div className="branding">
                  <LocalizedLink to="/" rel="home" title={t`Go to home page`}>
                    <LogoRkdResearch />
                    <VisuallyHidden>RKD Research</VisuallyHidden>
                  </LocalizedLink>
                </div>
                <button
                  className="btn-reset menu-toggler"
                  onClick={mainNavHandleClick}
                  aria-expanded={mainNavIsOpen ? 'true' : 'false'}
                >
                  <IconBurger />
                  <VisuallyHidden>
                    <Trans>Open menu</Trans>
                  </VisuallyHidden>
                </button>
              </div>
              <div className="main">
                <div className="header--top">
                  <Media>
                    {({ breakpoints, currentBreakpoint }) =>
                      breakpoints[currentBreakpoint] < bpAsNumber.mobileMenu ? (
                        <>
                          <MenuUser />
                          <MenuHeader />
                          <MenuLanguage />
                        </>
                      ) : (
                        <>
                          <MenuLanguage />
                          <MenuUser />
                          <MenuHeader />
                        </>
                      )
                    }
                  </Media>
                </div>
                <div className="header--search">
                  <SearchBox />
                  <SearchActions />
                </div>
              </div>
            </div>
          </GridContainer>
        </div>
      </FocusTrap>
    </StyledPageHeader>
  );
}

export default PageHeader;
