import { withSearch } from '@elastic/react-search-ui';
import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import Mls from 'components/detail/Mls';
import styled from 'styled-components';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import { IconPointerDown } from 'components/graphical/Icons';
import easing from 'styles/base/variables/easing';

const StyledDateRangeFilter = styled.div`
  button {
    width: 100%;
    ${alignIconDefaults()};
    margin-bottom: 0;

    .text {
      flex: 0 0 auto;
    }

    .icon {
      transition: transform ${easing.normal};
      margin-left: auto;
    }

    &[aria-expanded='true'] {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  form {
    margin-top: 1em;

    &:not(.open) {
      display: none;
    }

    fieldset {
      .form-fields {
        display: flex;
        gap: 1rem;
      }

      .form-actions {
        display: inline-block;
        margin-top: 2rem;
      }
    }
  }
`;

function DateRangeFilters({
  dateRangeFilters,
  filters,
  addFilter,
  removeFilter,
}) {
  if (dateRangeFilters === undefined) {
    return null;
  }

  return (
    <div>
      {Object.keys(dateRangeFilters).map((key) => {
        const filter = dateRangeFilters[key];
        const current = filters.find((filter) => filter.field === key) ?? {
          values: [],
        };

        return (
          <DateRangeFilter
            key={key}
            name={key}
            label={filter.label}
            addFilter={addFilter}
            removeFilter={removeFilter}
            ranges={current.values}
          />
        );
      })}
    </div>
  );
}

export default withSearch(
  ({ dateRangeFilters, filters, addFilter, removeFilter }) => {
    return {
      dateRangeFilters,
      filters,
      addFilter,
      removeFilter,
    };
  }
)(DateRangeFilters);

function DateRangeFilter({ name, label, addFilter, ranges }) {
  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  function handleButton(e) {
    e.preventDefault();
    if (fromValue || toValue) {
      addFilter(name, {
        from: fromValue,
        to: toValue,
      });
      setFromValue('');
      setToValue('');
    }
  }
  // Toggle range.
  const [rangeIsOpen, setRangeIsOpen] = useState(false);
  const rangeHandleClick = () => {
    setRangeIsOpen(!rangeIsOpen);
  };

  return (
    <StyledDateRangeFilter className="date-range-filter">
      <button
        className="btn-reset h3"
        onClick={rangeHandleClick}
        aria-expanded={rangeIsOpen ? 'true' : 'false'}
      >
        <span className="text">
          <Mls strings={label} />
        </span>
        <IconPointerDown />
      </button>
      <form action="" className={rangeIsOpen ? 'open' : null}>
        <fieldset>
          <div className="form-fields">
            <VisuallyHidden>
              <label htmlFor={name + '-from'}>
                <Trans>Start year</Trans>
              </label>
            </VisuallyHidden>
            <input
              id={name + '-from'}
              type="text"
              placeholder={t`Start year`}
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
            />
            <VisuallyHidden>
              <label htmlFor={name + '-to'}>
                <Trans>End year</Trans>
              </label>
            </VisuallyHidden>
            <input
              id={name + '-to'}
              type="text"
              placeholder={t`End year`}
              value={toValue}
              onChange={(e) => setToValue(e.target.value)}
            />
          </div>

          <div className="form-actions">
            <button className="btn btn-yellow" onClick={handleButton}>
              <span className="text">
                <Trans>Add filter</Trans>
              </span>
            </button>
          </div>
        </fieldset>
      </form>
    </StyledDateRangeFilter>
  );
}
