// import { useState } from 'react';
import { Plural, Trans } from '@lingui/macro';
import { withSearch } from '@elastic/react-search-ui';
import styled from 'styled-components';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import respValue from 'styles/base/helpers/respValue';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import border from 'styles/base/variables/border';
import { fontSize } from 'styles/base/variables/typography';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import { IconPointerDown } from 'components/graphical/Icons';
import SearchActiveFilters from 'components/search/SearchActiveFilters';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { getLocalizedValue } from 'utils/processors';

const StyledResultsHeader = styled.div`
  .header--top {
    ${mq(bp.mobileMenu)} {
      ${alignIconDefaults('2rem')};
      display: flex;
      flex-wrap: wrap;
    }

    > * {
      + * {
        ${mq(0, bp.mobileMenu)} {
          margin-top: 1rem;
        }
      }
    }

    .actions {
      ${mq(0, bp.mobileMenu)} {
        display: flex;
      }

      ${mq(bp.mobileMenu)} {
        margin-left: auto;
      }

      button {
        ${mq(0, bp.mobileMenu)} {
          padding: 0.5rem;
          margin-right: 1rem;
        }

        ${respValue(
          'font-size',
          fontSize.text.larger.desktop,
          fontSize.text.larger.mobile
        )};

        .icon {
          transition: transform ${easing.normal};
        }

        // To position "border-bottom".
        position: relative;

        &.open {
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            left: 0;
            height: 0.4rem;
            background-color: ${color.yellow};
            ${respValue('bottom', '-2rem')};
          }

          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .header--main {
    .period-selector {
      border-top: ${border.dimmed};
      border-width: 0.2rem;
      background-color: ${color.paper};
      ${respValue('padding', '5rem')};
      ${respValue('margin-top', '2rem')};
    }

    .active-filters {
      &:not(:empty) {
        margin-top: 3rem;

        &:first-child {
          border-top: ${border.dimmed};
          border-width: 0.2rem;
          padding-top: 3rem;
        }
      }
    }
  }
`;

function ResultsHeader({
  activeSorting,
  setActiveSorting,
  totalResults,
  isLoading,
  totalResultsRelation,
  isOverview,
  sortOptions,
  setSort,
}) {
  // Toggle period.
  // const [periodIsOpen, setPeriodIsOpen] = useState(false);
  // const periodHandleClick = () => {
  //   setPeriodIsOpen(!periodIsOpen);
  // };

  let sortOptionsForCurrentLanguage = [];
  if (sortOptions && Object.entries(sortOptions).length > 0) {
    for (const [key, value] of Object.entries(sortOptions)) {
      sortOptionsForCurrentLanguage.push({
        name: getLocalizedValue(value.label),
        value: key,
        direction: value.direction,
      });
    }
  }

  const handleSelection = (e) => {
    const selectedValue = e.target.value;
    if (sortOptions && sortOptions.hasOwnProperty(selectedValue)) {
      const selectedOption = sortOptions[selectedValue];
      setActiveSorting(selectedValue);
      setSort(selectedOption.id, selectedOption.direction);
    }
  };

  return (
    <StyledResultsHeader className="results--header">
      <div className="header--top">
        <h2 className="heading h3">
          {totalResultsRelation === 'gte' && (
            <>
              <Trans>More than</Trans>{' '}
            </>
          )}
          {!isLoading && (
            <Plural
              value={totalResults}
              _0="No results"
              one={
                <Trans>
                  # <span className="font-light">result</span>
                </Trans>
              }
              other={
                <Trans>
                  # <span className="font-light">results</span>
                </Trans>
              }
            />
          )}
        </h2>
        <div className="actions">
          {/*
          <button
            type="button"
            className={`btn-reset btn ${periodIsOpen ? 'open' : ''}`}
            onClick={periodHandleClick}
            aria-expanded={periodIsOpen ? 'true' : 'false'}
          >
            <span className="text">
              <Trans>Period</Trans>
            </span>
            <IconPointerDown />
          </button>
          */}
          {!isOverview && sortOptionsForCurrentLanguage?.length > 0 ? (
            <div className="select-wrapper">
              <VisuallyHidden>
                <label htmlFor="select-databases">
                  <Trans>Choose a sorting option:</Trans>
                </label>
              </VisuallyHidden>

              <div className="select-wrapper">
                <VisuallyHidden>
                  <label htmlFor="sort-results">
                    <Trans>Choose sorting:</Trans>
                  </label>
                </VisuallyHidden>
                <select
                  name="sorting"
                  id="sort-results"
                  onChange={handleSelection}
                  value={activeSorting}
                >
                  {sortOptionsForCurrentLanguage &&
                    sortOptionsForCurrentLanguage.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          ) : (
            <button type="button" className="btn-reset btn">
              <span className="text">
                <Trans>Relevance</Trans>
              </span>
              <IconPointerDown />
            </button>
          )}
        </div>
      </div>
      <div className="header--main">
        {/*
        {periodIsOpen && (
          <div className="period-selector">Period selector lives here...</div>
        )}
        */}
        <SearchActiveFilters />
      </div>
    </StyledResultsHeader>
  );
}

export default withSearch(
  ({
    totalResults,
    isLoading,
    totalResultsRelation,
    isOverview,
    sortOptions,
    setSort,
  }) => ({
    totalResults,
    totalResultsRelation,
    isLoading,
    isOverview,
    sortOptions,
    setSort,
  })
)(ResultsHeader);
