import React, { createContext, useState } from 'react';
import useInterval from 'hooks/useInterval';
import { isEqual } from 'lodash';
import useConsentContext from 'hooks/useConsentContext';

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const consent = useConsentContext();

  const uri = '/api/heartbeat';

  const [userData, setUserData] = useState({
    authenticated: null,
    display_name: '',
    roles: [],
    id_token: '',
    csrf_token: '',
    uid: '',
    aname: '',
    aid: '',
    is_in_rkd_range: false,
  });

  useInterval(
    async (isCancelled) => {
      const response = await fetch(uri);

      if (isCancelled()) {
        return;
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      if (isCancelled()) {
        return;
      }

      // Only update context when the user data changes to prevent users of
      // the context rendering again. Since the backend started to use the
      // refresh token on every heartbeat, changing the delivered id_token
      // to use with images, this has no effect anymore. Because this may
      // change, we keep the optimization here. Ignore the csrf_token in
      // the comparison; this changes due to a random mask, the old token
      // remains valid.
      const csrfToken = data.csrf_token;
      data.csrf_token = '';
      userData.csrf_token = '';
      if (!isEqual(data, userData)) {
        data.csrf_token = csrfToken;
        // Update the GTM user id when tracking consent has been given, and the user
        // id changed.
        if (
          consent.consentStatus.tracking === true &&
          data.id_token !== userData.id_token
        ) {
          const dataLayer = window.dataLayer || [];
          dataLayer.push({
            user_id: data.uid,
            event: 'updateUserId',
          });
        }
        setUserData(data);
      }
    },
    150000,
    true
  );

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
