import ResultsHeaderVisualSearch from 'components/search/ResultsHeaderVisualSearch';
import GridContainer from 'components/layout/GridContainer';
import VisualSearchResults from 'components/search/VisualSearchResults';
import { useState } from 'react';
import useUserContext from 'hooks/useUserContext';
import { scaleImage } from 'utils/scaleImage';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import respValue from 'styles/base/helpers/respValue';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';

const StyledVisualSearch = styled.div`
  .content--main {
    ${respValue('margin-top', '8rem')};
  }
`;

const MAX_ACCEPTED_SIZE = 600;

const ALLOWED_FILE_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/webp',
];

function VisualSearch() {
  const user = useUserContext();
  const [file, setFile] = useState(null);
  const [fileDataUrl, setFileDataUrl] = useState(null);
  const [visualSearchResults, setVisualSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [invalidFileFormat, setInvalidFileFormat] = useState(false);

  /**
   * Act when a file is selected for visual search.
   */
  const onFileChange = (event) => {
    if (ALLOWED_FILE_FORMATS.indexOf(event.target.files[0].type) > -1) {
      setInvalidFileFormat(false);
      setFile(event.target.files[0]);
      setFileDataUrl(URL.createObjectURL(event.target.files[0]));
    } else {
      setInvalidFileFormat(true);
    }
  };

  async function fetchResultsForFile(file) {
    // Scale the image so its largest dimension is 600 pixels.
    // This also converts the image to jpeg.
    const scaledBlob = await scaleImage(file, MAX_ACCEPTED_SIZE);

    // Error when the file is not an image or cannot be scaled.
    if (!scaledBlob) {
      setError(true);
      return;
    }

    const scaledFile = new File([scaledBlob], 'scaled.jpg', {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });

    // Send the file to the combined feature extraction and search endpoint.
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': scaledFile.type,
        'Content-Length': scaledFile.size,
        Authorization: `Bearer ${user.id_token}`,
      }),
      body: scaledFile,
      redirect: 'follow',
    };

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_VISE_BASE_URL}/Search`,
        requestOptions
      );
      const result = await response.json();

      // We got the data we wanted! Hopefully the result size is not 0.
      if (result.RESULT_SIZE === 0) {
        setVisualSearchResults([]);
      } else {
        setVisualSearchResults(result.result);
      }
    } catch (e) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Act when the visual search is started.
   * Documentation:
   * https://gitlab.com/vgg/vise/-/blob/master/doc/HTTP-REST-API.md#search-query-using-external-image
   *
   */
  const onFormSubmit = (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }
    fetchResultsForFile(file).then();
  };

  /**
   * Handle the clearing of the current visual search.
   */
  const onClearVisualSearch = () => {
    setFile(null);
    setFileDataUrl(null);
    setVisualSearchResults(null);
    setLoading(false);
    setError(false);
  };

  return (
    <StyledVisualSearch>
      <GridContainer>
        <div className="indent--1-col">
          <VisuallyHidden>
            <h1>{t`Visual search`}</h1>
          </VisuallyHidden>
          <div className="content--header">
            <ResultsHeaderVisualSearch
              onFileChange={onFileChange}
              onFormSubmit={onFormSubmit}
              onClearVisualSearch={onClearVisualSearch}
              visualSearchResults={visualSearchResults}
              fileDataUrl={fileDataUrl}
              formatError={invalidFileFormat}
            />
          </div>
          <div className="content--main">
            <VisualSearchResults
              visualSearchResults={visualSearchResults}
              imgBaseUrl={`${process.env.REACT_APP_VISE_BASE_URL}${process.env.REACT_APP_VISE_PROJECT}/image_small/`}
              loading={loading}
              error={error}
            />
          </div>
        </div>
      </GridContainer>
    </StyledVisualSearch>
  );
}

export default VisualSearch;
