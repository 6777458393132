export const imageUriRegExp = new RegExp('^https://data.rkd.nl/images/[0-9]*$');
export const archiveUriRegExp = new RegExp(
  '^https://data.rkd.nl/archives/[0-9]*$'
);

export const imageUriType = 'artwork';

export const archiveUriType = 'archive';

export function getArchiveItem(tree, id) {
  for (const i in Object.keys(tree)) {
    if (tree[i] === undefined) {
      continue;
    }
    const part = tree[i];

    if (part.uri === id) {
      return part;
    } else if (part.children !== undefined) {
      const result = getArchiveItem(part.children, id);
      if (result !== undefined) {
        return result;
      }
    }
  }
}

export function openToTreeNode(tree, id) {
  let hasNotFound = true;
  hasNotFound = tree.every((item) => {
    if (item.uri === id) {
      return false;
    } else if (item.children !== undefined) {
      hasNotFound = openToTreeNode(item.children, id);
    }
    if (hasNotFound === false) {
      item.open = true;
      return false;
    }
    return true;
  });
  return hasNotFound;
}
