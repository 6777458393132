import RkdResearchConnector from 'search-ui/connector/RkdResearchConnector';
import { SearchProvider } from '@elastic/react-search-ui';
import useIsSearchPage from 'hooks/useIsSearchPage';

function RkdresearchSearchProvider({ children }) {
  const connector = new RkdResearchConnector();
  const isSearchPage = useIsSearchPage();

  const config = {
    apiConnector: connector,
    alwaysSearchOnInitialLoad: isSearchPage,
    hasA11yNotifications: true,
  };

  return (
    <SearchProvider
      key={isSearchPage ? 'isSearchPage' : 'notSearchPage'}
      config={config}
    >
      {children}
    </SearchProvider>
  );
}

export default RkdresearchSearchProvider;
