export default function adaptResponse(response, state) {
  const results = response.results;
  const totalResults = response.count;
  const totalResultsRelation = response.countRelation;
  const facets = response.facets;
  const dateRangeFilters = response.dateRangeFilters;
  const sortOptions = response.sortOptions;

  const current = response.paging.current;
  const resultsPerPage = response.paging.resultsPerPage;
  const totalPages = response.paging.totalPages;

  return {
    isOverview: response.isOverview ?? false,
    results,
    totalResults,
    totalResultsRelation,
    current,
    resultsPerPage,
    totalPages,
    sortOptions: sortOptions,
    advancedSearchOptions: response.advancedSearchOptions,
    ...(Object.keys(facets).length > 0 && { facets }),
    ...(Object.keys(dateRangeFilters).length > 0 && { dateRangeFilters }),
  };
}
