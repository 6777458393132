import styled from 'styled-components';
import Mls from 'components/detail/Mls';

const StyledSubjectWithText = styled.div`
  &:not(:first-child) {
    margin-top: 1em;
  }
`;

function SubjectWithText({ label, text }) {
  return (
    <StyledSubjectWithText>
      <p className="font-thick">
        <Mls strings={label} />
      </p>
      <Mls strings={text} />
    </StyledSubjectWithText>
  );
}

export default SubjectWithText;
