import { Link } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';
import createSearchUrlQuery from 'utils/createSearchUrlQuery';
import Mls from './Mls'

const NewSearchQuery = ({ label, searchTerm, filters }) => {
  const path = getLocalizedPath('/search');

  const url = `${path}${createSearchUrlQuery(searchTerm, filters)}`;

  return <Link to={url}><Mls strings={label} /></Link>;
};

export default NewSearchQuery;
