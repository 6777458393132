import { useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import {
  IconAddToFolder,
  // IconDownload,
} from 'components/graphical/Icons';
import color from 'styles/base/variables/color';
import SubMenu from 'components/navigation/SubMenu';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import useSnackBarContext from 'hooks/useSnackBarContext';
import useUserContext from 'hooks/useUserContext';
// import MyWorkspaceLink from 'components/misc/MyWorkspaceLink';

const StyledSnackBarMenu = styled.nav`
  .btn {
    &.open {
      color: ${color.yellow};

      ${mq(bp.large)} {
        position: relative;

        &:after {
          content: '';
          width: calc(100% - 2rem);
          height: 0.4rem;

          position: absolute;
          bottom: -2rem;
          left: 50%;
          transform: translateX(-50%);
          background-color: ${color.yellow};
        }
      }
    }

    // To make button's take the same height.
    .icon {
      margin-top: -2em;
      margin-bottom: -2em;
    }

    .icon-monitor {
      svg {
        width: 2.2rem;
        height: auto;
      }
    }
  }
`;

function MenuSnackBar() {
  const snackBar = useSnackBarContext();

  // Toggle side-navigation.
  const [sideNavIsOpen, setSideNavIsOpen] = useState(false);
  const sideNavHandleClick = () => {
    setSideNavIsOpen(!sideNavIsOpen);
  };

  // Check if user has correct role.
  const user = useUserContext();
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_REGISTERED_USER') > -1 ||
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  return (
    <StyledSnackBarMenu>
      <ul>
        {isAuthorized && (
          <li>
            <button
              className={`btn btn-reset ${sideNavIsOpen ? 'open' : ''}`}
              onClick={sideNavHandleClick}
              aria-expanded={sideNavIsOpen ? 'true' : 'false'}
              disabled={!snackBar.hasSelectedItems()}
            >
              <span className="text">
                <Trans>Save selection ({snackBar.selectedItemCount})</Trans>
              </span>
              <IconAddToFolder />
            </button>
          </li>
        )}
        {/*
        <li>
          <button
            className="btn btn-reset"
            disabled={!snackBar.hasSelectedItems()}
          >
            <span className="text">
              <Trans>Download ({snackBar.selectedItemCount})</Trans>
            </span>
            <IconDownload />
          </button>
        </li>
        <li>
          <MyWorkspaceLink
            url={`${process.env.REACT_APP_DD_BASE_URL}`}
            label={
              <Trans>My workspace ({snackBar.selectedItemCount})</Trans>
            }
          ></MyWorkspaceLink>
        </li>
*/}
      </ul>
      {sideNavIsOpen && <SubMenu buttonClick={sideNavHandleClick} />}
    </StyledSnackBarMenu>
  );
}

export default MenuSnackBar;
