import styled from 'styled-components';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import FullClick from '../helpers/FullClick';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Loading from '../misc/Loading';
import { t } from '@lingui/macro';
import easing from 'styles/base/variables/easing';

const StyledBanner = styled.div`
  &:hover {
    figure {
      opacity: 0.8;
    }
  }

  figure {
    margin: 0;
    transition: opacity ${easing.normal};

    img {
      width: 100%;
    }
  }
`;

function Banner({ label, imgSrc, imgAlt, link }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  return (
    <StyledBanner>
      <FullClick>
        <VisuallyHidden>
          <span>{getLocalizedValueFromMls(label)}</span>
        </VisuallyHidden>
        <Link
          to={getLocalizedValueFromMls(link.href)}
          target={link.options.target}
          className="full-click__trigger"
        />
        {isLoading && <Loading spinnerSize="3rem" hiddenText={t`Loading...`} />}
        <figure>
          <img
            src={getLocalizedValueFromMls(imgSrc)}
            alt={getLocalizedValueFromMls(imgAlt)}
            onLoad={handleOnLoad}
          />
        </figure>
      </FullClick>
    </StyledBanner>
  );
}

export default Banner;
