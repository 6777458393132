import roundTwo from 'styles/base/helpers/roundTwo';
import splitValueAndUnit from 'styles/base/helpers/splitValueAndUnit';
import size from 'styles/base/variables/size';

const gridGutterSplitted = splitValueAndUnit(size.gridGutter);

const SpanGridCols = function (spannedCols, totalCols) {
  const totalColsWithFallback = totalCols ?? size.gridColumns;
  const ratio = spannedCols / totalColsWithFallback;
  return `calc(${roundTwo(ratio * 100)}% - ${
    gridGutterSplitted.value * roundTwo(1 - ratio)
  }${gridGutterSplitted.unit})`;
};

export default SpanGridCols;
