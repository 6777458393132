// Returns given value rounded with two decimals.
const inputTextual = function () {
  return `
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="date"],
    input[type="time"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="textfield"],
    input[type="search_api_autocomplete"],
    input[type="url"]
  `;
};

export default inputTextual;
