import { useContext } from 'react';
import { AnalyticsContext } from 'context/AnalyticsContext';

/**
 * Gets the analytics object.
 *
 * This hooks aids in DX by throwing an exception when the context cannot be used.
 *
 * @return
 *   The user context.
 */
const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error(
      'useAnalyticsContext was used outside of the AnalyticsContextProvider.'
    );
  }

  return context;
};

export default useAnalyticsContext;
