import styled from 'styled-components';

const Pipe = styled.span`
  display: block;
  align-self: stretch;
  margin-left: 1rem;
  border-left: 1px solid;
  margin-right: 1rem;
`;

export default Pipe;
