import styled from 'styled-components';
import FullClick from 'components/helpers/FullClick';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import easing from 'styles/base/variables/easing';
import LocalizedLink from 'components/helpers/LocalizedLink';
import { Trans } from '@lingui/macro';
import color from 'styles/base/variables/color';
import AdjustOrder from 'components/helpers/AdjustOrder';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import TripleIfImage from 'components/misc/TripleIfImage';

const StyledFolder = styled.div`
  .full-click__trigger {
    &:hover {
      ~ .tiles {
        box-shadow: 0 0 0 0.2rem currentColor;
      }
    }
  }

  .tiles {
    background-color: ${color.paper};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
    padding: 1.6rem;
    transition: box-shadow ${easing.normal};
  }

  .tile {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
  }

  .tile--visual {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tile--textual {
    grid-column-start: 2;
    grid-row-start: 2;

    background-color: ${color.white};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      line-height: ${18.75 / 16};
    }
  }

  .textual {
    margin-top: 2rem;
  }
`;

function Folder({ title, href, images, item_count }) {
  // TODO: set correct dimensions.
  return (
    <StyledFolder>
      <AdjustOrder>
        <div className="textual">
          <h2 className="heading font-light">{title}</h2>
        </div>
        <div className="order-min">
          <FullClick>
            <LocalizedLink to={href} className="full-click__trigger">
              <VisuallyHidden>
                <Trans>Read more about {title}</Trans>
              </VisuallyHidden>
            </LocalizedLink>
            <div className="tiles">
              {images &&
                images.map((image, i) => (
                  <div key={i} className="tile tile--visual">
                    <TripleIfImage
                      baseUrl={image.src}
                      alt={getLocalizedValueFromMls(image.alt)}
                      width={200}
                      height={200}
                    />
                  </div>
                ))}
              <div className="tile tile--textual">
                <p>
                  <span className="font-thick">{item_count}</span>
                  <br />
                  {item_count > 1 ? <Trans>items</Trans> : <Trans>item</Trans>}
                </p>
              </div>
            </div>
          </FullClick>
        </div>
      </AdjustOrder>
    </StyledFolder>
  );
}

export default Folder;
