import { useRef, useState } from 'react';
import styled from 'styled-components';
import GridContainer from 'components/layout/GridContainer';
import { IconFolder } from 'components/graphical/Icons';
import { Trans } from '@lingui/macro';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import useApiData from 'hooks/useApiData';
// import MenuUserTabs from 'components/navigation/MenuUserTabs';
import respValue from 'styles/base/helpers/respValue';
import ListCols from 'components/layout/ListCols';
import Folder from 'components/viewmodes/Folder';
import { t } from '@lingui/macro';
import Loading from 'components/misc/Loading';
import MyWorkspaceLink from 'components/misc/MyWorkspaceLink';
import Error from 'components/misc/Error';
import { IconAddFolder } from 'components/graphical/Icons';
import color from 'styles/base/variables/color';
import useUserContext from 'hooks/useUserContext';

const StyledCollectionsPage = styled.div`
  .content--header {
    .content--header--inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;
      ${respValue('margin-top', '6rem')};
    }

    h1 {
      ${alignIconDefaults()};

      svg {
        width: 3.3rem;
      }
    }
  }

  .content--main {
    ${respValue('margin-top', '4rem')};

    .as-tile {
      width: 100%;
      aspect-ratio: 1/1;
      overflow: hidden;
      background-color: ${color.paper};
      padding: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ${respValue('gap', '4rem')};

      &:not(.open) {
        form {
          display: none;
        }
      }

      &.open {
        .form-toggler {
          display: none;
        }
      }

      .form-toggler {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5em;
      }

      form {
        width: 100%;
        max-width: 25rem;

        .form-actions {
          margin-top: 2rem;
          display: flex;
          gap: 0.8rem;

          button {
            flex-grow: 1;
            justify-content: center;
          }
        }
      }
    }
  }
`;

function CollectionsPage() {
  const [refresh, setRefresh] = useState(false);
  const {
    isLoading,
    isError,
    data: [collections],
  } = useApiData(
    [{ path: '/user/collections', params: [`u=${refresh}`] }],
    [[]]
  );

  function doRefresh() {
    setRefresh((prevState) => {
      return !prevState;
    });
  }

  // Check if user has correct role.
  const user = useUserContext();
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_REGISTERED_USER') > -1 ||
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  return (
    user.authenticated !== null &&
    (!isAuthorized ? (
      <StyledCollectionsPage>
        <GridContainer>
          <div className="indent--1-col">
            <Error title={t`You need to login to use selections.`} />
          </div>
        </GridContainer>
      </StyledCollectionsPage>
    ) : (
      <StyledCollectionsPage>
        <div className="content--header">
          {/*<MenuUserTabs />*/}
          <GridContainer>
            <div className="content--header--inner indent--1-col">
              <h1 className="font-light">
                <IconFolder />
                <span className="text">
                  <Trans>
                    My selections{' '}
                    {!isLoading && !isError ? `(${collections.length})` : ''}
                  </Trans>
                </span>
              </h1>
              <div className="actions">
                <MyWorkspaceLink
                  url={`${process.env.REACT_APP_DD_BASE_URL}`}
                ></MyWorkspaceLink>
              </div>
            </div>
          </GridContainer>
        </div>
        <GridContainer>
          <div className="content--main indent--1-col">
            {isError ? (
              <Error title={t`The requested selections could not be loaded.`} />
            ) : isLoading ? (
              <Loading layoutStyle="full" />
            ) : (
              collections && (
                <ListCols
                  cols="3"
                  as="ul"
                  gap="4rem"
                  className="collection-list"
                >
                  <li>
                    <AddNewCollection onRefresh={doRefresh} />
                  </li>
                  {collections.map((collection, i) => {
                    return (
                      <li key={i}>
                        <Folder
                          title={collection.title}
                          href={`/my-selections/${collection.id}`}
                          images={collection.images}
                          item_count={collection.item_count}
                        />
                      </li>
                    );
                  })}
                </ListCols>
              )
            )}
          </div>
        </GridContainer>
      </StyledCollectionsPage>
    ))
  );
}

export default CollectionsPage;

/**
 * Internal component for the new collection map.
 * @param onRefresh
 *   Callback to inform the parent a collection has been created.
 */
function AddNewCollection({ onRefresh }) {
  const user = useUserContext();
  // Toggle "Add collection".
  const newCollectionInputRef = useRef(null);
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const [hasSubmissionError, setHasSubmissionError] = useState(false);
  const itemHandleFormToggle = () => {
    setItemIsOpen((prevState) => !prevState);
    window.requestAnimationFrame(function () {
      newCollectionInputRef.current.focus();
    });
  };

  // Show "Succes message" (assuming that collection is added).
  const [itemIsAdded, setItemIsAdded] = useState(false);

  const itemHandleSucces = async () => {
    const collectionName = newCollectionInputRef.current.value;

    if (collectionName) {
      const res = await fetch(`/api/user/collections/collection/add`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: newCollectionInputRef.current.value,
          csrf_token: user.csrf_token,
        }),
      });

      const data = await res.json();

      if (!data.error) {
        setItemIsAdded(!itemIsAdded);
        setItemIsOpen((prevState) => !prevState);
        newCollectionInputRef.current.value = '';
        // Inform the parent component.
        onRefresh();
      } else {
        setHasSubmissionError(true);
      }
    }
  };

  /**
   * Main form handler.
   *
   * @param e
   *   The form event.
   */
  const handleOnSubmit = (e) => {
    // Handling happens in the various button specific handlers.
    e.preventDefault();
  };

  return (
    <div className={`as-tile ${itemIsOpen ? 'open' : ''}`}>
      <button
        className="btn-reset form-toggler"
        onClick={itemHandleFormToggle}
        aria-expanded={itemIsOpen ? 'true' : 'false'}
      >
        <IconAddFolder />
        <span className="text">
          <Trans>New selection</Trans>
        </span>
      </button>
      <form onSubmit={handleOnSubmit}>
        <div className="form-item">
          <input type="text" ref={newCollectionInputRef} />
        </div>
        <div className="form-actions">
          <button className="btn btn-yellow" onClick={itemHandleSucces}>
            <Trans>Add</Trans>
          </button>
          <button
            className="btn btn btn-ghost-black"
            onClick={itemHandleFormToggle}
          >
            <Trans>Cancel</Trans>
          </button>
        </div>
      </form>
      {itemIsAdded && <p>Success message</p>}
      {hasSubmissionError && <Trans>An error has occurred</Trans>}
    </div>
  );
}
