const alignIconDefaults = function (spacing, alignment) {
  return `
    display: inline-flex;
    align-items: ${alignment ?? 'center'};

    gap: ${spacing ?? '0.5em'};

    .text {
      flex: 1 1 auto;
    }
  `;
};

export default alignIconDefaults;
