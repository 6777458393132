/**
 * Renders children in an <ul>-wrapper.
 */
function UnorderedList({ style, children }) {
  const attributes = {};

  if (style === 'bullet') {
    attributes.type = 'disc';
  } else {
    attributes.type = 'none';
  }

  return <ul {...attributes}>{children}</ul>;
}

export default UnorderedList;
