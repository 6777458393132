function LoginLink({ children, className }) {
  const getLoginLink = () => {
    const currentPath =
      window.location.pathname + window.location.search + window.location.hash;

    return `/api/login?destination=${encodeURIComponent(currentPath)}`;
  };

  return (
    <a href={getLoginLink()} className={className}>
      {children}
    </a>
  );
}

export default LoginLink;
