import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import GridContainer from 'components/layout/GridContainer';
import MenuFooter from 'components/navigation/MenuFooter';
import {
  LogoRkdShape,
  LogoRkdResearchTextual,
} from 'components/graphical/LogoRKD';
import color from 'styles/base/variables/color';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import respValue from 'styles/base/helpers/respValue';
import { isFullWidthRoute } from 'utils/fullWidthRoutes';
import { useLocation } from 'react-router-dom';
const currentYear = new Date().getFullYear();

const StyledPageFooter = styled.footer`
  background-color: ${color.paper};
  ${respValue('margin-top', '10rem')};

  // To position LogoRkdShape.
  overflow: hidden;
  position: relative;
  z-index: 0;

  .footer--inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -1rem;

    ${mq(0, bp.medium)} {
      padding: 4.4rem 3rem 8.8rem 3rem;
    }

    ${mq(bp.medium)} {
      padding: 4.4rem 0 4.4rem 5rem;
    }

    > * {
      margin: 1rem;
    }

    .visual {
      display: flex;
      align-items: center;

      ${mq(bp.medium)} {
        // Minor adjustment to match design.
        position: relative;
        top: -0.5rem;
      }

      .logo-rkd--shape {
        color: ${color.white};

        ${mq(0, bp.medium)} {
          position: absolute;
          bottom: -10%;
          right: -10%;
        }

        ${mq(bp.medium)} {
          // Take no room.
          width: 0px;
          height: 0px;
        }

        svg {
          position: relative;
          z-index: -1;

          ${mq(0, bp.medium)} {
            width: 17rem;
            height: auto;
          }

          ${mq(bp.medium)} {
            left: -5rem;
            transform: translateY(-47%);
          }
        }
      }

      .logo-rkd-research--textual {
        margin-right: 5rem;
      }
    }

    .copyright {
      margin-right: 5rem;
    }

    nav {
      ul {
        margin: -0.5rem;
      }

      li {
        margin: 0.5rem;

        ${mq(0, bp.medium)} {
          display: block;
        }

        ${mq(bp.medium)} {
          &:not(:last-child) {
            margin-right: 4rem;
          }
        }
      }
    }
  }
`;

function PageFooter() {
  return (
    <StyledPageFooter className="page--footer">
      <GridContainer full={isFullWidthRoute(useLocation())}>
        <div className="footer--inner">
          <div className="visual">
            <LogoRkdShape />
            <LogoRkdResearchTextual />
          </div>
          <strong className="copyright">
            &copy; {currentYear}{' '}
            <Trans>RKD &mdash; Netherlands Institute for Art History</Trans>
          </strong>
          <MenuFooter />
        </div>
      </GridContainer>
    </StyledPageFooter>
  );
}

export default PageFooter;
