import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import useUserContext from 'hooks/useUserContext';
import BodyText from 'components/misc/BodyText';
import LoginLink from 'components/misc/LoginLink';

function SubscriptionRequired() {
  const user = useUserContext();
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;
  return (
    <BodyText>
      {!user.authenticated && <h1>Log in</h1>}
      <p>
        {user.authenticated ? (
          <Trans>You do not have a valid RKD Research subscription.</Trans>
        ) : (
          <Trans>
            To use the visual search/my workspace, you need a RKD Research
            subscription. Log in with subscription. You can purchase a
            subscription via the{' '}
            <a
              href={`https://webshop.rkd.nl/rkd-research.html?___store=${activeLocale.toUpperCase()}`}
            >
              webshop
            </a>
            .
          </Trans>
        )}
      </p>
      <p className="actions--outer">
        {!user.authenticated && (
          <LoginLink className="btn btn-yellow">Log in</LoginLink>
        )}
        <a
          href={`https://webshop.rkd.nl/rkd-research.html?___store=${activeLocale.toUpperCase()}`}
          className="btn btn-yellow"
        >
          <Trans>Purchase a RKD Research subscription</Trans>
        </a>
      </p>
      <h2>
        <Trans>Benefits of an RKD Research subscription</Trans>
      </h2>
      <p>
        <Trans>
          RKD Research, the digital entry point for art historical research.
          Collect, compare, connect millions of research data and images.
        </Trans>
      </p>
      <p>
        <Trans>
          The place to do endless online research in over 6 million documents,
          data and images of and about visual art from the Netherlands. Open to
          everyone free of charge. If you take out a subscription, you can use
          the innovative visual search to search the RKD's databases for images
          and you get access to my workspace, a handy tool for keeping track of
          things. A must for the art historical researcher.
        </Trans>
      </p>
    </BodyText>
  );
}

export default SubscriptionRequired;
