import BasicPage from 'components/layout/BasicPage';
import { t } from '@lingui/macro';
import { Helmet } from 'react-helmet';
import SubscriptionRequired from 'components/misc/SubscriptionRequired';

function LinkToMyWorkspace() {
  return (
    <>
      <Helmet>
        <title>{t`My workspace`}</title>
      </Helmet>
      <BasicPage>
        <SubscriptionRequired />
      </BasicPage>
    </>
  );
}

export default LinkToMyWorkspace;
