import { Link } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';
import { useLingui } from '@lingui/react';
import Mls from 'components/detail/Mls';

function InternalLink({ label, href, options }) {
  const { i18n } = useLingui();
  const activeLocale = i18n.locale;
  const localizedTo = getLocalizedPath(href, activeLocale);

  if (options?.target === '_blank') {
    return (
      <Link to={localizedTo} target={'_blank'} rel={'noopener noreferrer'}>
        <Mls strings={label} />
      </Link>
    );
  }

  return (
    <Link to={localizedTo}>
      <Mls strings={label} />
    </Link>
  );
}

export default InternalLink;
