import { withSearch } from '@elastic/react-search-ui';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import { t, Trans } from '@lingui/macro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { stringify } from 'qs';
import { getLocalizedLangAndValue } from 'utils/processors';
import Loading from 'components/misc/Loading';
import Checkbox from 'components/graphical/Checkbox';
import { DebounceInput } from 'react-debounce-input';
import { i18n } from '@lingui/core';

const StyledSearchFilterSelector = styled.div`
  form {
    background-color: ${color.paper};
    padding: 2rem;

    fieldset {
      margin-left: 0;

      legend {
        margin-bottom: 1rem;
      }
    }

    .actions {
      margin-top: 1rem;
    }
  }
`;

/**
 * The search for search filters component.
 *
 * @param searchTerm
 * @param filters
 * @param addFilter
 * @param removeFilter
 * @param facetParentId
 * @returns {JSX.Element}
 * @constructor
 */
function SearchForSearchFilters({
  searchTerm,
  filters,
  addFilter,
  removeFilter,
  facetParentId,
}) {
  const searchForFiltersInput = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchFilterData, setSearchFilterData] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [facetOptionSearchTerm, setFacetOptionSearchTerm] = useState('');

  const currentFacetFieldValues = useMemo(() => {
    for (const filter of filters) {
      if (filter.field === facetParentId) {
        return filter.values;
      }
    }
  }, [filters, facetParentId]);

  useEffect(() => {
    // Ensures to reset the current facet option search if a filter is
    // removed using the active facet removal.
    setFacetOptionSearchTerm('');
    setSearchFilterData({});
    setSelectedFilters({});
  }, [filters, currentFacetFieldValues]);

  /**
   * Handle facet option search input event.
   * @param e
   */
  const handleOnChange = (e) => {
    setFacetOptionSearchTerm(e.target.value);
    setIsProcessing(true);

    searchFilter(e.target.value)
      .then(() => {
        setIsProcessing(false);
      })
      .catch(() => {
        // Handle failure
        setIsProcessing(false);
      });
  };

  /**
   * Add the selected facet options to the filters.
   */
  const handleOnAddFilter = (e) => {
    e.preventDefault();
    Object.keys(selectedFilters).forEach((filter) => {
      if (selectedFilters[filter] === true) {
        addFilter(facetParentId, filter);
      } else if (selectedFilters[filter] === false) {
        removeFilter(facetParentId, filter);

        // Delete the item on uncheck state.
        const itemsLeft = { ...selectedFilters };
        delete itemsLeft[filter];
        setSelectedFilters(itemsLeft);
      }
    });

    // Reset the filter search.
    setFacetOptionSearchTerm('');
    setSearchFilterData({});
  };

  /**
   * Handle checkbox change event.
   * @param e
   */
  const handleFilterCheckboxChange = (e) => {
    const filterId = e.target.id;
    setSelectedFilters((prevState) => ({
      ...prevState,
      [filterId]: e.target.checked,
    }));
  };

  /**
   * Checks if the filter is already selected.
   *
   * @param filterId
   * @returns {boolean}
   */
  const isSelectedFilter = (filterId) => {
    if (currentFacetFieldValues) {
      return (
        currentFacetFieldValues.find((id) => id === filterId) !== undefined
      );
    }

    return false;
  };

  /**
   * Search facet option by fetching search backend.
   *
   * @param facetOptionSearchTerm
   * @returns {Promise<void>}
   */
  const searchFilter = async (facetOptionSearchTerm) => {
    if ('' === facetOptionSearchTerm) {
      return;
    }

    const query = stringify(
      {
        q: searchTerm ?? '',
        facetOptionSearchTerm: facetOptionSearchTerm,
        lang: i18n.locale,
        filters: filters,
        facetId: facetParentId,
      },
      {
        arrayFormat: 'indices',
        addQueryPrefix: true,
      }
    );

    const res = await fetch(`/api/filter-search${query}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    const data = await res.json();
    // Display already checked facet options as checked.
    data.forEach((item) => (item.isChecked = isSelectedFilter(item.id)));
    setSearchFilterData(data);
  };

  return (
    <StyledSearchFilterSelector>
      <DebounceInput
        id={facetParentId}
        type="text"
        inputRef={searchForFiltersInput}
        value={facetOptionSearchTerm}
        debounceTimeout={500}
        placeholder={t`Search filter`}
        onChange={(e) => {
          handleOnChange(e);
        }}
      />
      {!isProcessing &&
        searchFilterData.length > 0 &&
        facetOptionSearchTerm !== '' && (
          <form action="">
            <fieldset className="no-indent">
              <legend className="h3 font-light">{t`Choose filters`}</legend>
              {searchFilterData.map((filter) => {
                const label = getLocalizedLangAndValue(filter.label);
                return (
                  <div key={filter.id}>
                    <input
                      type="checkbox"
                      id={filter.id}
                      onChange={handleFilterCheckboxChange}
                      defaultChecked={filter.isChecked}
                    />
                    <label htmlFor={filter.id}>
                      <Checkbox />
                      <span className="text" lang={label.langCode}>
                        {label.string}
                      </span>
                      {filter.count && (
                        <span className="count font-smaller font-light">
                          {filter.count}
                        </span>
                      )}
                    </label>
                  </div>
                );
              })}
            </fieldset>
            <div className="actions">
              <button className="btn btn-yellow" onClick={handleOnAddFilter}>
                <span className="text">
                  <Trans>Add filter</Trans>
                </span>
              </button>
            </div>
          </form>
        )}
      {isProcessing && (
        <div>
          <Loading spinnerSize="3rem" hiddenText={t`Loading...`} />
        </div>
      )}
      {!isProcessing &&
        searchFilterData.length <= 0 &&
        facetOptionSearchTerm !== '' && (
          <span className="text font-smaller">
            <Trans>No filters found.</Trans>
          </span>
        )}
    </StyledSearchFilterSelector>
  );
}

export default withSearch(
  ({ searchTerm, filters, addFilter, removeFilter }) => ({
    searchTerm,
    filters,
    addFilter,
    removeFilter,
  })
)(SearchForSearchFilters);
