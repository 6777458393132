import styled from 'styled-components';
import color from 'styles/base/variables/color';
import borderRadius from 'styles/base/variables/borderRadius';
import zIndex from 'styles/base/variables/zIndex';
import { IconClose, IconDownload } from 'components/graphical/Icons';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { t, Trans } from '@lingui/macro';
import { lazy, Suspense, useState } from 'react';

const StyledAssetDownload = styled.div`
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  .image-info {
    position: absolute;
    right: 0;
    bottom: calc(100% + 2rem + 2rem);
    width: 47rem;
    max-width: 100%;
    background-color: ${color.white};
    color: ${color.anthracite};
    padding: 2.4rem 2rem 2rem 2rem;
    border-radius: ${borderRadius.small};
    z-index: ${zIndex.asideNav};

    > .heading {
      margin-bottom: 2rem;
    }

    > .actions {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;

      button {
        width: 4.8rem;
        height: 4.8rem;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        margin: -1.2rem;
      }
    }
  }
`;

const DownloadImageInfo = lazy(() =>
  import('components/misc/AssetDownloadInfo')
);

function AssetDownload({ asset }) {
  const [imageInfoIsOpen, setImageInfoIsOpen] = useState(false);
  const downloadHandleClick = () => {
    setImageInfoIsOpen(!imageInfoIsOpen);
  };

  return (
    <StyledAssetDownload className="asset-download">
      <div className="actions">
        <button className={`btn btn-reset`} onClick={downloadHandleClick}>
          <VisuallyHidden>
            <Trans>Open/Close download information</Trans>
          </VisuallyHidden>
          <IconDownload />
          <span className="text">
            <Trans>Image quality and download</Trans>
          </span>
        </button>
      </div>
      {imageInfoIsOpen && (
        <div className="image-info">
          <h2 className="heading">
            <Trans>Image quality and download</Trans>
          </h2>
          <Suspense
            fallback={
              <p className="font-normal font-smaller">{t`Loading...`}</p>
            }
          >
            <DownloadImageInfo asset={asset} />
          </Suspense>
          <div className="actions">
            <button className={`btn btn-reset`} onClick={downloadHandleClick}>
              <VisuallyHidden>
                <Trans>Close download information</Trans>
              </VisuallyHidden>
              <IconClose />
            </button>
          </div>
        </div>
      )}
    </StyledAssetDownload>
  );
}

export default AssetDownload;
