import { useEffect, useRef } from 'react';

/**
 *
 * @param {function} callback
 *   The function to call each interval.
 * @param {int} interval
 *   The interval between calls in milliseconds.
 * @param {boolean} immediate
 *   Set to true to execute the callback once immediately.
 */
const useInterval = (callback, interval, immediate) => {
  const ref = useRef();

  // Keep a reference to callback without restarting the interval.
  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    // When this flag is set, the function is cancelled.
    let cancelled = false;

    // Wrap callback to pass isCancelled getter as an argument
    const fn = () => {
      ref.current(() => cancelled);
    };

    // Set interval and run immediately if requested.
    const id = setInterval(fn, interval);
    if (immediate) {
      fn();
    }

    // Clear and cancel the interval upon component unmount.
    return () => {
      cancelled = true;
      clearInterval(id);
    };
  }, [interval, immediate]);
};

export default useInterval;
