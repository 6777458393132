import styled from 'styled-components';
import color from 'styles/base/variables/color';
import easing from 'styles/base/variables/easing';
import { styledUl, styledOl } from 'styles/base/helpers/listStyling';

const BodyText = styled.div`
  p,
  ul,
  ol {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.35em 0;

    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  blockquote,
  table,
  details {
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  ul {
    ${styledUl};
  }

  ol {
    ${styledOl};
  }

  a {
    &:not([class]) {
      transition: all ${easing.normal};
      font-weight: 400;
      text-decoration: underline;

      &:not(:hover) {
        color: ${color.tealGreen};
      }
    }
  }


  .actions--outer {
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  img {
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  li {
    img {
      margin-bottom: 2em;
    }
  }

  .images-outer {
    &:not(:first-child) {
      margin-top: 2em;
    }

    img {
      &:not(:first-child) {
        margin-top: unset;
      }
    }
  }
`;

export default BodyText;
