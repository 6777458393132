import styled from 'styled-components';
import color from 'styles/base/variables/color';
import respValue from 'styles/base/helpers/respValue';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import { IconClose } from 'components/graphical/Icons';
import { getLocalizedValue } from 'utils/processors';
import { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import useAdvancedSearchContext from 'hooks/useAdvancedSearchContext';

const StyledAdvancedSearch = styled.div`
  > .heading {
    margin-bottom: 1rem;
  }

  > .content {
    background-color: ${color.paper};
    ${respValue('padding', '3rem', '2rem')};

    > ul {
      display: grid;
      gap: 1rem 2rem;

      ${mq(0, bp.small)} {
        grid-template-columns: repeat(1, 1fr);
      }

      ${mq(bp.small, bp.extraLarge)} {
        grid-template-columns: repeat(2, 1fr);
      }

      ${mq(bp.extraLarge, bp.gridMax)} {
        grid-template-columns: repeat(3, 1fr);
      }

      ${mq(bp.gridMax)} {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    > .form-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 2rem;
    }
  }

  &:not(.open) {
    display: none;
  }
`;

function AdvancedSearch({
  advancedSearchIsOpen,
  searchTermFieldRef,
  advancedSearchOptions,
  setSearchTerm,
  activeDatabase,
}) {
  const advancedSearchStorage = useAdvancedSearchContext();
  let advancedFields = [];
  const [originalSearchTerm, setOriginalSearchTerm] = useState(
    searchTermFieldRef?.current?.value ?? ''
  );

  useEffect(() => {
    const regex = new RegExp('([a-z0-9_]*:\\(.*\\))');
    const match = regex.exec(searchTermFieldRef?.current?.value ?? '');
    let searchTerm = searchTermFieldRef?.current?.value ?? '';
    if (match) {
      searchTerm = searchTerm.substring(0, match.index);
      searchTerm = searchTerm.trimEnd();
    }

    setOriginalSearchTerm(searchTerm);
  }, [searchTermFieldRef, searchTermFieldRef?.current?.value]);

  const addAdvancedField = (element) => {
    if (!advancedFields.includes(element)) {
      advancedFields.push(element);
    }
  };
  const handleAdvancedSearch = (event) => {
    event.preventDefault();
    let stringToAppend = '';
    let fields = [];
    for (let i = 0; i < Object.keys(advancedFields).length; i++) {
      const field = advancedFields[i];
      if (field.value !== '') {
        const search = `${field.dataset.fieldId}:(${field.value})`;
        stringToAppend +=
          originalSearchTerm === '' && i <= 0 ? search : ` ${search}`;
        fields[field.id] = field.value;
      }
    }

    if (stringToAppend !== '') {
      searchTermFieldRef.current.value = originalSearchTerm + stringToAppend;
      setSearchTerm(originalSearchTerm + stringToAppend, {
        refresh: false,
        autocompleteSuggestions: false,
        autocompleteResults: false,
      });
    }

    advancedSearchStorage.setFields(fields);
  };

  const clearAll = (event) => {
    event.preventDefault();
    // Empty the field.
    for (let i = 0; i < Object.keys(advancedFields).length; i++) {
      const field = advancedFields[i];
      field.value = '';
    }

    // Empty storage.
    advancedSearchStorage.clearFields();
  };

  const hasAdvancedSearchOptions = (options) => {
    return options && Object.keys(options).length > 0;
  };

  if (!hasAdvancedSearchOptions(advancedSearchOptions)) {
    return null;
  }

  return (
    <StyledAdvancedSearch
      className={advancedSearchIsOpen ? 'open' : ''}
      aria-expanded={advancedSearchIsOpen ? 'true' : 'false'}
    >
      <p className="heading h3">
        <Trans>Advanced Search</Trans>
      </p>
      <div className="content">
        {hasAdvancedSearchOptions(advancedSearchOptions) && (
          <ul>
            {Object.keys(advancedSearchOptions).map((idx) => {
              const option = advancedSearchOptions[idx];
              return (
                <li key={option.id}>
                  <label htmlFor={option.id}>
                    {getLocalizedValue(option.label)}
                  </label>
                  <input
                    id={`advanced-search-${activeDatabase}-${option.id}`}
                    data-field-id={option.id}
                    type="text"
                    ref={addAdvancedField}
                    defaultValue={
                      advancedSearchStorage.getField(
                        `advanced-search-${activeDatabase}-${option.id}`
                      ) ?? ''
                    }
                  />
                </li>
              );
            })}
          </ul>
        )}
        <div className="form-actions">
          <button className="btn btn-yellow" onClick={handleAdvancedSearch}>
            <Trans>Apply advanced fields</Trans>
          </button>
          <button className="btn btn-black" onClick={clearAll}>
            <IconClose />
            <Trans>Clear all fields</Trans>
          </button>
        </div>
      </div>
    </StyledAdvancedSearch>
  );
}

export default AdvancedSearch;
