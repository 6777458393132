import { useRef } from 'react';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import { Trans, t } from '@lingui/macro';
import easing from 'styles/base/variables/easing';
import size from 'styles/base/variables/size';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import respValue from 'styles/base/helpers/respValue';
import {
  IconAddToFolder,
  IconClose,
  IconPointerDown,
} from 'components/graphical/Icons';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import FocusTrap from 'focus-trap-react';
import roundTwo from 'styles/base/helpers/roundTwo';
import useApiData from 'hooks/useApiData';
import useUserContext from 'hooks/useUserContext';
import useSnackBarContext from 'hooks/useSnackBarContext';
import SubMenuListItem from 'components/detail/SubMenuListItem';
import { useEffect, useState } from 'react';
import Loading from 'components/misc/Loading';
import SubmissionMessage from 'components/misc/SubmissionMessage';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';
import OutsideClick from 'components/helpers/OutsideClick';

const StyledSubMenu = styled.div`
  .sub-menu {
    position: fixed;
    background-color: ${color.paper};
    inset: 0;
    height: 100vh;
    overflow: auto;

    ${mq(0, bp.medium)} {
      width: 80vw;
      left: auto;
      box-shadow: -100vh 0 0rem 100vh ${color.anthraciteDimmed50};
    }

    ${mq(bp.medium)} {
      width: 100vw;
      max-width: ${size.gridFullAsideContentWidth};
      left: auto;
      box-shadow: -100vh 0 0rem 100vh ${color.anthraciteDimmed50};
    }

    .sub-menu--inner {
      padding-top: 7rem;
      padding-bottom: 7rem;
      ${respValue('padding-right', '9rem')};
      ${respValue('padding-left', '9rem')};

      // To position close button.
      position: relative;

      .close-sub-menu {
        bottom: 0;
        justify-content: center;
        border-radius: 0;
        position: absolute;
        top: 0;
        left: 0;

        ${mq(0, bp.medium)} {
          width: 4rem;
          height: 4rem;

          .icon {
            transform: scale(${roundTwo(40 / 48)});
          }
        }

        ${mq(bp.medium)} {
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }

    .content--visual {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 5.4rem;
      }
    }

    .content--textual {
      ${respValue('margin-top', '6rem')};

      .heading {
        ${respValue('margin-bottom', '4rem')};
      }

      form {
        ${respValue('margin-top', '3rem')};

        fieldset {
          margin-bottom: 1rem;
        }

        .add-collection {
          ${respValue('margin-top', '3rem')};

          .add-collection-toggler {
            ${alignIconDefaults()};

            .icon {
              transition: ${easing.normal};
            }

            &.open {
              .icon {
                transform: rotate(180deg);
              }
            }
          }

          .add-collection-input {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;

            button {
              align-self: flex-start;
            }

            &:not(.open) {
              display: none;
            }
          }
        }

        .form-actions {
          ${respValue('margin-top', '6rem')};
          display: flex;
          gap: 0.8rem;

          button {
            flex-grow: 1;
            justify-content: center;
          }
        }
      }

      .submission {
        ${respValue('margin-top', '4rem')};
      }
    }
  }
`;

function SubMenu({ buttonClick }) {
  const user = useUserContext();
  const snackBar = useSnackBarContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState({});
  const [submissionReady, setSubmissionReady] = useState(
    Object.keys(selectedCollections).length > 0
  );
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [collectionUpdated, setCollectionUpdated] = useState(0);

  const {
    isLoading,
    isError,
    data: [collections],
  } = useApiData(
    [{ path: '/user/collections', params: [`u=${collectionUpdated}`] }],
    [[]]
  );
  const [collectionList, setCollectionList] = useState(collections);

  useEffect(() => {
    // Only enable submission when at least one collection is selected.
    // This could be a new or existing collection.
    setSubmissionReady(Object.keys(selectedCollections).length > 0);
  }, [selectedCollections]);

  useEffect(() => {
    // Update modifiable list of collections when there is a change.
    setCollectionList(collections);
  }, [collections]);

  /**
   * Function to add a collection to the selection
   *
   * @param id
   *   The collection id.
   * @param title
   *   The collection title (display name).
   */
  const addToCollectionSelection = (id, title) => {
    if (!selectedCollections.hasOwnProperty(id)) {
      setSubmissionReady(false);

      // Add selected (checked) collection.
      setSelectedCollections((prevState) => ({ ...prevState, [id]: title }));
    }
  };

  /**
   * Function to remove a collection from the selection.
   *
   * @param id
   *   The collection id.
   */
  const removeFromCollectionSelection = (id) => {
    if (selectedCollections.hasOwnProperty(id)) {
      setSubmissionReady(false);

      // Remove deselected (unchecked) collection.
      const collectionsCopy = { ...selectedCollections };
      delete collectionsCopy[id];
      setSelectedCollections(collectionsCopy);
    }
  };

  /**
   * Handle the selection of a collection from the collections list.
   *
   * @param e
   *   The checkbox onClick event.
   */
  const handleCollectionSelect = (e) => {
    const targetCheckBox = e.target;
    if (targetCheckBox.checked) {
      addToCollectionSelection(targetCheckBox.id, targetCheckBox.name);
    } else {
      removeFromCollectionSelection(targetCheckBox.id);
    }
  };

  /**
   * Function to create a new empty collection.
   *
   * @returns {Promise<void>}
   */
  const createNewCollection = async () => {
    if (ref.current.value) {
      setIsSubmitting(true);

      const res = await fetch(`/api/user/collections/collection/add`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: ref.current.value,
          csrf_token: user.csrf_token,
        }),
      });

      const data = await res.json();
      setSubmissionMessage(data);

      ref.current.value = '';
      setAddCollectionIsOpen(false);

      submissionReset();
    }
  };

  /**
   * Asynchronous function to update collections with items
   * from the selection bar (SnackBar).
   *
   * @returns {Promise<void>}
   */
  const addToCollection = async () => {
    setIsSubmitting(true);

    const res = await fetch(`/api/user/collections/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        csrf_token: user.csrf_token,
        selected_collections: selectedCollections,
        selected_items: snackBar.items,
      }),
    });

    // Prepare response message.
    const data = await res.json();
    setSubmissionMessage(data);

    submissionReset();
  };

  /**
   * Function to reset submission states.
   */
  const submissionReset = () => {
    // Reset
    setSubmissionReady(false);
    setSelectedCollections([]);
    setIsSubmitting(false);
  };

  /**
   * Function that executes on a successful fetch.
   */
  const submitFinish = () => {
    setIsSubmitting(false);
    // Ensure a new updated list of collections is requested.
    setCollectionUpdated(collectionUpdated + 1);
  };

  /**
   * Function that executes on a failed fetch.
   *
   * @param event
   */
  const submitError = () => {
    setSubmissionMessage({
      errors: true,
    });
  };

  const displayEmptyMessage = () => {
    setSubmissionMessage({ errors: true });
  };

  const isSubmissionDisabled = () => {
    return isSubmitting || !submissionReady || isLoading;
  };

  /**
   * Main form handler.
   *
   * @param e
   *   The form event.
   */
  const handleOnSubmit = (e) => {
    e.preventDefault();
    let submitAction = e.nativeEvent.submitter.name;

    setSubmissionMessage({});

    if (submitAction === 'cancel_collection_action') {
      submissionReset();
      buttonClick();
      return;
    }

    const newCollectionTitle = ref.current.value;

    switch (submitAction) {
      case 'new_collection_display_name':
        // Empty input is not allowed. Also checked in backend.
        // See UserCollectionController.php
        if (newCollectionTitle.length < 3) {
          //setSubmissionReady(false);
          displayEmptyMessage();
          return;
        }

        createNewCollection()
          .then(() => {
            submitFinish();
          })
          .catch(() => {
            submitError(submitAction);
          });
        break;

      case 'update_collection':
        addToCollection()
          .then(() => {
            submitFinish();
          })
          .catch(() => {
            submitError(submitAction);
          });
        break;

      default:
    }
  };

  // Toggle "Add collection" input.
  const ref = useRef(null);
  const [addCollectionIsOpen, setAddCollectionIsOpen] = useState(false);
  const itemHandleFormToggle = (e) => {
    e.preventDefault();
    setAddCollectionIsOpen(!addCollectionIsOpen);
    window.requestAnimationFrame(function () {
      ref.current.focus();
    });
  };

  return (
    user.authenticated !== null && (
      <StyledSubMenu>
        <OutsideClick onOutsideClick={buttonClick}>
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
            }}
          >
            <div className="sub-menu">
              <div className="sub-menu--inner">
                <button
                  className="btn btn-black close-sub-menu btn-on-light"
                  onClick={buttonClick}
                >
                  <VisuallyHidden>
                    <Trans>Close menu</Trans>
                  </VisuallyHidden>
                  <IconClose />
                </button>
                <div className="content">
                  <div className="content--visual">
                    <IconAddToFolder />
                  </div>
                  <div className="content--textual">
                    <h2 className="h1 heading">{t`Add to`}</h2>
                    {!user.authenticated ? (
                      <p>{t`You need to login to use selections.`}</p>
                    ) : (
                      <>
                        <p>{t`Choose one or more selections or add a new one`}</p>
                        <form onSubmit={handleOnSubmit}>
                          <fieldset>
                            <legend className="font-thick">
                              {t`Choose a selection`}
                            </legend>
                          </fieldset>
                          <div className="collection-list">
                            {isLoading && collectionList.length < 1 && (
                              <Trans>Loading selections...</Trans>
                            )}
                            {!isLoading && collectionList.length < 1 && (
                              <Trans>No selections available.</Trans>
                            )}
                            {!isError &&
                              collectionList &&
                              collectionList.map((collection) => {
                                const isChecked =
                                  selectedCollections.hasOwnProperty(
                                    collection.id
                                  );
                                return (
                                  <SubMenuListItem
                                    key={collection.id}
                                    collection={collection}
                                    handleCollectionSelect={
                                      handleCollectionSelect
                                    }
                                    isDisabled={isSubmitting}
                                    isChecked={isChecked}
                                  />
                                );
                              })}
                          </div>
                          <div className="add-collection">
                            <button
                              className={`btn-reset add-collection-toggler font-thick ${
                                addCollectionIsOpen ? 'open' : ''
                              }`}
                              onClick={itemHandleFormToggle}
                              aria-expanded={
                                addCollectionIsOpen ? 'true' : 'false'
                              }
                            >
                              <Trans>New selection</Trans>
                              <IconPointerDown />
                            </button>
                            <div
                              className={`add-collection-input ${
                                addCollectionIsOpen ? 'open' : ''
                              }`}
                            >
                              <input
                                type="text"
                                ref={ref}
                                name="new_collection_display_name_input"
                                disabled={isSubmitting || isLoading}
                              />
                              <button
                                className="btn btn-yellow btn-on-light"
                                name="new_collection_display_name"
                              >
                                <Trans>Add selection</Trans>
                              </button>
                            </div>
                          </div>
                          <div className="form-actions">
                            <button
                              name="cancel_collection_action"
                              className="btn btn-ghost-black btn-on-light"
                            >
                              <Trans>Cancel</Trans>
                            </button>
                            <button
                              name="update_collection"
                              className={`btn ${
                                isSubmissionDisabled()
                                  ? 'btn-disabled'
                                  : 'btn-yellow'
                              }`}
                              disabled={isSubmissionDisabled()}
                            >
                              <Trans>Add</Trans>
                            </button>
                          </div>
                        </form>
                        <div className="submission">
                          {isSubmitting && (
                            <Loading
                              spinnerSize="3rem"
                              hiddenText={t`Saving...`}
                            />
                          )}
                          <SubmissionMessage data={submissionMessage} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FocusTrap>
        </OutsideClick>
      </StyledSubMenu>
    )
  );
}

export default SubMenu;
