import { useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import {
  IconAlert,
  // IconDownload,
  // IconShare
} from 'components/graphical/Icons';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import roundTwo from 'styles/base/helpers/roundTwo';
import { mq, bp } from 'styles/base/variables/mediaQueries';
import Modal from 'components/misc/Modal';
import MentionForm from 'components/misc/MentionForm';

const StyledArtworkMenu = styled.nav`
  > ul {
    > li {
      > button {
        padding: 0;
        justify-content: center;

        ${mq(0, bp.medium)} {
          width: 4rem;
          height: 4rem;

          .icon {
            transform: scale(${roundTwo(40 / 48)});
          }
        }

        ${mq(bp.medium)} {
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }
  }
`;

function MenuArtwork() {
  // Toggle Mention.
  const [mentionModalIsOpen, setMentionModalIsOpen] = useState(false);
  const mentionModalHandleClick = () => {
    setMentionModalIsOpen(!mentionModalIsOpen);
  };

  return (
    <StyledArtworkMenu>
      <ul>
        {/*
        <li>
          <button className="btn btn-yellow btn-rounded btn-on-dark">
            <VisuallyHidden>
              <Trans>Download/Export</Trans>
            </VisuallyHidden>
            <IconDownload />
          </button>
        </li>
        */}
        <li>
          <button
            className="btn btn-black btn-rounded btn-on-dark"
            onClick={mentionModalHandleClick}
            aria-expanded={mentionModalIsOpen ? 'true' : 'false'}
          >
            <VisuallyHidden>
              <Trans>Report</Trans>
            </VisuallyHidden>
            <IconAlert />
          </button>
          {mentionModalIsOpen && (
            <Modal closeTrigger={mentionModalHandleClick}>
              <MentionForm cancelTrigger={mentionModalHandleClick} />
            </Modal>
          )}
        </li>
        {/*
        <li>
          <button className="btn btn-black btn-rounded btn-on-dark">
            <VisuallyHidden>
              <Trans>Share</Trans>
            </VisuallyHidden>
            <IconShare />
          </button>
        </li>
        */}
      </ul>
    </StyledArtworkMenu>
  );
}

export default MenuArtwork;
