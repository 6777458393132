import { t, Trans } from '@lingui/macro';
import { withSearch } from '@elastic/react-search-ui';
import ListCols from 'components/layout/ListCols';
import Card from 'components/viewmodes/Card';
import { getLocalizedPath } from 'utils/localizedRoutes';
import Loading from 'components/misc/Loading';
import Error from 'components/misc/Error';
import useSnackBarContext from 'hooks/useSnackBarContext';
import CheckboxAsButton from 'components/graphical/CheckboxAsButton';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import size from 'styles/base/variables/size';
import Tile from 'components/viewmodes/Tile';
import { IconPointerRight } from 'components/graphical/Icons';
import Disc from 'components/graphical/Disc';
import createSearchUrlQuery from 'utils/createSearchUrlQuery';
import db_archives from 'assets/images/databases/archives.jpg';
import db_artists from 'assets/images/databases/artists.jpg';
import db_collections from 'assets/images/databases/collections.jpg';
import db_excerpts from 'assets/images/databases/excerpts.jpg';
import db_marks from 'assets/images/databases/marks.jpg';
import man_reading from 'assets/images/1_A042_03.jpg';
import borssom_london from 'assets/images/1-Borssom-Londen-BM.jpg';
import db_library from 'assets/images/databases/library.jpg';
import db_technical from 'assets/images/databases/technical.jpg';
import styled from 'styled-components';
import color from 'styles/base/variables/color';
import zIndex from 'styles/base/variables/zIndex';
import { useMemo } from 'react';
import { stringify } from 'qs';

const StyledSearchResults = styled.div`
  > ol {
    > li {
      // To position button.
      position: relative;

      > button {
        position: absolute;
        padding: 1rem;
        top: 0rem;
        right: 0rem;
        z-index: ${zIndex.fullClick + 1};

        .square {
          border-width: 0.2rem;
        }

        &:not(.active) {
          .square {
            background-color: ${color.blackDimmed20};
            border-color: ${color.white};
          }

          &:hover {
            .square {
              background-color: ${color.blackDimmed60};
            }
          }
        }
      }
    }
  }
`;

function Results({
  results,
  isLoading,
  error,
  isOverview,
  searchTerm,
  filters,
  current,
  resultsPerPage,
  totalPages,
}) {
  const snackBar = useSnackBarContext();

  const currentSeachQuery = useMemo(
    () =>
      encodeURIComponent(
        createSearchUrlQuery(
          searchTerm,
          filters,
          false
        )
      ),
    [searchTerm, filters]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error} />;
  }

  const DataTile = {
    rkdarchives: {
      title: t`RKDarchives`,
      image: {
        src: db_archives,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdarchives'],
        },
      ])}`,
    },
    rkdartists: {
      title: t`RKDartists`,
      image: {
        src: db_artists,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdartists'],
        },
      ])}`,
    },
    rkdcollections: {
      title: t`RKDcollections`,
      image: {
        src: db_collections,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdcollections'],
        },
      ])}`,
    },
    rkdexcerpts: {
      title: t`RKDexcerpts`,
      image: {
        src: db_excerpts,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdexcerpts'],
        },
      ])}`,
    },
    rkdimages: {
      title: t`RKDimages`,
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      image: {
        src: man_reading,
        alt: '',
      },
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdimages'],
        },
      ])}`,
    },
    rkdimages_lite: {
      title: t`RKDimages Lite`,
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      image: {
        src: borssom_london,
        alt: '',
      },
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdimages_lite'],
        },
      ])}`,
    },
    rkdlibrary: {
      title: t`RKDlibrary`,
      image: {
        src: db_library,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdlibrary'],
        },
      ])}`,
    },
    rkdtechnical: {
      title: t`RKDtechnical`,
      image: {
        src: db_technical,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['rkdtechnical'],
        },
      ])}`,
    },
    marks: {
      title: t`Marks on Art`,
      image: {
        src: db_marks,
        alt: '',
      },
      icon: (
        <Disc className="disc">
          <IconPointerRight />
        </Disc>
      ),
      theme: 'dark',
      href: `${getLocalizedPath('/search')}${createSearchUrlQuery(searchTerm, [
        {
          field: 'db',
          values: ['marks'],
        },
      ])}`,
    },
  };

  function handleSnackBarItem(r) {
    snackBar.toggleItem({
      id: r.id,
      card: r,
    });
  }

  return (
    <StyledSearchResults className="search-results">
      {results.length > 0 ? (
        isOverview ? (
          <ListCols
            cols="3"
            gap={size.gridGutter}
            className="results-list--as-tiles"
          >
            {results.map((item, i) => {
              if (DataTile[item.name]) {
                const itemData = DataTile[item.name];
                return (
                  <Tile
                    key={i}
                    theme={itemData.theme}
                    title={itemData.title}
                    subtitle={itemData.subtitle}
                    icon={itemData.icon}
                    image={itemData.image}
                    href={itemData.href}
                    localized={false}
                    count={item.count}
                  />
                );
              }
              return null;
            })}
          </ListCols>
        ) : (
          <ListCols cols="3" as="ol" className="results-list--as-cards">
            {results.map((item, i) => {
              const searchData = {
                i: i,
                p: current,
                rpp: resultsPerPage,
                tp: totalPages,
              };
              return (
                <li key={item.id}>
                  <Card
                    title={getLocalizedValueFromMls(item.title)}
                    meta={getLocalizedValueFromMls(item.meta)}
                    subtitle={getLocalizedValueFromMls(item.subtitle)}
                    image={item.image}
                    href={
                      getLocalizedPath(
                        `/detail/${encodeURIComponent(item.id)}`
                      ) +
                      `?c=${currentSeachQuery}&n=${encodeURIComponent(
                        stringify(searchData)
                      )}`
                    }
                  />
                  <CheckboxAsButton
                    buttonOnClick={() => handleSnackBarItem(item)}
                    buttonClassName={`btn-reset ${
                      snackBar.hasItem(item.id) ? 'active' : ''
                    }`}
                  >
                    <VisuallyHidden>
                      {snackBar.hasItem(item.id) ? (
                        <Trans>
                          Remove {getLocalizedValueFromMls(item.title)} from
                          selection
                        </Trans>
                      ) : (
                        <Trans>
                          Add {getLocalizedValueFromMls(item.title)} to
                          selection
                        </Trans>
                      )}
                    </VisuallyHidden>
                  </CheckboxAsButton>
                </li>
              );
            })}
          </ListCols>
        )
      ) : (
        <p>
          <Trans>Your search did not return any results.</Trans>
        </p>
      )}
    </StyledSearchResults>
  );
}

export default withSearch(
  ({
    results,
    isLoading,
    error,
    isOverview,
    searchTerm,
    filters,
    current,
    resultsPerPage,
    totalPages,
  }) => ({
    results,
    isLoading,
    error,
    isOverview,
    searchTerm,
    filters,
    current,
    resultsPerPage,
    totalPages,
  })
)(Results);
