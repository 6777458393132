import useScript from 'hooks/useScript';

/**
 * Implements Liblynx analytics.
 */
const LiblynxAnalytics = () => {
  window._ll = window._ll || [];
  useScript('https://connect.liblynx.com/log/js/counter5.min.js');

  return null;
};

export default LiblynxAnalytics;
