import roundTwo from 'styles/base/helpers/roundTwo';

const fontFamily = {
  roboto:
    "'Roboto',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
};

const fontSize = {
  text: {
    smaller: '1.4rem',
    default: {
      mobile: '1.4rem',
      desktop: '1.6rem',
    },
    larger: {
      mobile: '1.6rem',
      desktop: '1.8rem',
    },
  },
  heading: {
    h1: {
      mobile: '3rem',
      desktop: '3.2rem',
    },
    h1Extreme: {
      mobile: '4.8rem',
      desktop: '12rem',
    },
    h2: {
      mobile: '1.8rem',
      desktop: '2.2rem',
    },
    h3: '1.8rem',
    h4: '1.6rem',
  },
};

const lineHeight = {
  default: roundTwo(26 / 16),
  heading: {
    h1Extreme: roundTwo((90 / 120) * 1.1), // Adjust slightly, because given values don't match design.
    h1: roundTwo(42 / 32),
    h2: roundTwo(26 / 22),
    h3: roundTwo(24 / 18),
    h4: roundTwo(22 / 16),
  },
};

const letterSpacing = {
  heading: '-0.04em',
};

export { fontFamily, fontSize, lineHeight, letterSpacing };
