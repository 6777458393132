import Mls from 'components/detail/Mls';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';

function ExternalLink({ label, href, options }) {
  const linkTo = getLocalizedValueFromMls(href);

  if (options?.target === '_blank') {
    return (
      <a href={linkTo} target={'_blank'} rel={'noopener noreferrer'}>
        <Mls strings={label} />
      </a>
    );
  }

  return (
    <a href={linkTo}>
      <Mls strings={label} />
    </a>
  );
}

export default ExternalLink;
