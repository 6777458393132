import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Mls from 'components/detail/Mls';
import slugify from 'slugify';
import getLocalizedValueFromMls from 'utils/getLocalizedValueFromMls';
import border from 'styles/base/variables/border';
import borderRadius from 'styles/base/variables/borderRadius';
import respValue from 'styles/base/helpers/respValue';
import color from 'styles/base/variables/color';

const StyledTabGroup = styled.div`
  border: ${border.dimmed};
  border-radius: ${borderRadius.small};

  .react-tabs__tab-list {
    display: flex;
    font-weight: 700;
    border-bottom: ${border.dimmed};

    // Merge with tabGroup border.
    margin: -${border.dimmedWidth};

    .react-tabs__tab {
      border: ${border.dimmed};
      border-bottom-color: ${color.white};
      border-top-left-radius: ${borderRadius.small};
      border-top-right-radius: ${borderRadius.small};
      ${respValue('padding-top', '1.7rem')};
      ${respValue('padding-right', '3rem')};
      ${respValue('padding-bottom', '1.5rem')};
      ${respValue('padding-left', '3rem')};
      cursor: pointer;

      // Merge with tabGroup border.
      margin-bottom: calc(-${border.dimmedWidth});

      &:not(:last-child) {
        margin-right: calc(-${border.dimmedWidth});
      }

      &:not(.react-tabs__tab--selected) {
        border-color: transparent;
      }
    }
  }

  .react-tabs__tab-panel--selected {
    ${respValue('padding', '3rem')};
  }
`;

/**
 * We don't make use of "children" entirely,
 * because the elements inside children need to be
 * rendered the way "react-tabs" requires.
 * "children" is rendered by "Tab", but is not used.
 */
function TabGroup({ children }) {
  return (
    <StyledTabGroup>
      <Tabs>
        <TabList>
          {children.map((r) => {
            return (
              <Tab key={slugify(getLocalizedValueFromMls(r.props.label))}>
                <Mls strings={r.props.label} />
              </Tab>
            );
          })}
        </TabList>

        {children.map((r) => {
          return (
            <TabPanel key={slugify(getLocalizedValueFromMls(r.props.label))}>
              {r.props.children}
            </TabPanel>
          );
        })}
      </Tabs>
    </StyledTabGroup>
  );
}

export default TabGroup;
