import adaptRequest from 'search-ui/connector/requestAdapter';
import adaptResponse from 'search-ui/connector/responseAdapter';
import request from 'search-ui/connector/request';

class APIConnector {
  constructor() {
    this.request = request.bind(this);
  }

  onResultClick() {
    console.error('not implemented');
  }

  onAutocompleteResultClick() {
    console.error('not implemented');
  }

  async onSearch(state, queryConfig) {
    const options = adaptRequest(state, queryConfig);

    return this.request(options).then((response) =>
      adaptResponse(response, state)
    );
  }

  async onAutocomplete(state, queryConfig) {
    console.error('not implemented');
  }
}

export default APIConnector;
