import { i18n } from '@lingui/core';
import { en, nl } from 'make-plural/plurals';

// This constant must match the `locales` property exported in lingui.config.js.
export const locales = {
  nl: { label: 'Nederlands', abbreviation: 'Ned' },
  en: { label: 'English', abbreviation: 'Eng' },
};

export const defaultLocale = 'en';

export async function dynamicActivateLocale(locale) {
  const { messages } = await import(`../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

i18n.loadLocaleData({
  en: { plurals: en },
  nl: { plurals: nl },
});

// Initialize default locale with empty messages object to avoid warning. See
// https://github.com/lingui/js-lingui/issues/1194#issuecomment-1068488619.
i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);
