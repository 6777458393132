import { useContext } from 'react';
import { ConsentContext } from 'context/ConsentContext';

/**
 * Gets the consent object.
 *
 * This hooks aids in DX by throwing an exception when the context cannot be used.
 *
 * @return
 *   The consent context.
 */
const useConsentContext = () => {
  const context = useContext(ConsentContext);

  if (context === undefined) {
    throw new Error(
      'useConsentContext was used outside of the ConsentContextProvider.'
    );
  }

  return context;
};

export default useConsentContext;
