import styled from 'styled-components';
import easing from 'styles/base/variables/easing';

const Disc = styled.span`
  width: 4rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ${easing.normal};
`;

export default Disc;
