import styled from 'styled-components';
import slugify from 'slugify';
import { IconFolder } from 'components/graphical/Icons';
import { useState } from 'react';
import Checkbox from 'components/graphical/Checkbox';
import { VisuallyHiddenStyling } from 'components/helpers/VisuallyHidden';
import alignIconDefaults from 'styles/base/helpers/alignIconDefaults';

const StyledSubMenuListItem = styled.div`
  &:first-child {
    border-top: 0.1rem solid currentColor;
  }

  input[type='checkbox'] {
    ${VisuallyHiddenStyling};
  }

  label[for] {
    ${alignIconDefaults(`${11 / 16}em`, 'baseline')};
    width: 100%;
    border-bottom: 0.1rem solid currentColor;
    padding: 1rem 0;

    .icon-folder {
      position: relative;
      top: ${2 / 16}em;
    }

    .text {
      flex: unset;
    }

    .count {
      margin-left: 0.3em;
    }

    .check {
      margin-left: auto;
      position: relative;
      top: ${4 / 16}em;
    }
  }
`;

function SubMenuListItem({
  collection,
  isDisabled = false,
  handleCollectionSelect,
  isChecked = false,
}) {
  const [checkedState, setCheckedState] = useState(false);

  const handleOnCheckBoxClick = (e) => {
    setCheckedState(!checkedState);
    handleCollectionSelect(e);
  };

  return (
    <StyledSubMenuListItem>
      <input
        type="checkbox"
        name={collection.title}
        id={slugify(collection.id)}
        onChange={handleOnCheckBoxClick}
        disabled={isDisabled}
        checked={isChecked}
      />
      <label htmlFor={slugify(collection.id)}>
        <IconFolder />
        <span className="text font-normal">
          {collection.title}
          <span className="count font-smaller">({collection.item_count})</span>
        </span>
        <Checkbox ownClassName="check" />
      </label>
    </StyledSubMenuListItem>
  );
}

export default SubMenuListItem;
