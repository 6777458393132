import React, { createContext, useState } from 'react';

const AdvancedSearchContext = createContext();

const AdvancedSearchContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fields, setFields] = useState([]);

  /**
   * Function to remove ALL fields and values from the array.
   * Note: Permanent. Cannot be restored.
   */
  const clearFields = () => {
    setFields([]);
  };

  /**
   * Function to check if the field exists.
   *
   * @param field
   *   The field.
   * @returns {boolean}
   *   True if item exists, false if not.
   */
  const hasField = (field) => {
    return fields.hasOwnProperty(field);
  };

  const getField = (field) => {
    if (hasField) {
      return fields[field];
    }
  };

  /**
   * Function to check if the advanced search array has fields.
   *
   * @returns {boolean}
   *   True if it has items, false if not.
   */
  const hasItems = () => {
    return Object.keys(fields).length > 0;
  };

  return (
    <AdvancedSearchContext.Provider
      value={{
        isOpen,
        setIsOpen,
        fields,
        setFields,
        clearFields,
        getField,
        hasItems,
      }}
    >
      {children}
    </AdvancedSearchContext.Provider>
  );
};

export { AdvancedSearchContext, AdvancedSearchContextProvider };
