import { getLocalizedValue } from 'utils/processors';
import { t } from '@lingui/macro';
import IconLicenseCopyrightInc from 'assets/svg/_license/copyright-inc--white.svg';
import IconLicenseCopyrightUnd from 'assets/svg/_license/copyright-und--white.svg';
import IconLicenseBy from 'assets/svg/_license/cc-by.svg';
import IconLicenseBySa from 'assets/svg/_license/cc-by-sa.svg';
import IconLicenseByNc from 'assets/svg/_license/cc-by-nc.svg';
import IconLicenseByNcSa from 'assets/svg/_license/cc-by-nc-sa.svg';
import IconLicenseByNd from 'assets/svg/_license/cc-by-nd.svg';
import IconLicenseByNcNd from 'assets/svg/_license/cc-by-nc-nd.svg';
import IconLicenseByZero from 'assets/svg/_license/cc-by-zero.svg';
import styled from 'styled-components';
import { bp, mq } from 'styles/base/variables/mediaQueries';

const StyledLicenseInformation = styled.div`
  display: flex;
  gap: 1.5rem 3rem;

  ${mq(0, bp.large)} {
    flex-wrap: wrap;
  }

  ${mq(bp.gridMax)} {
    align-items: center;
  }

  dl {
    display: flex;
    gap: 0 0.5em;
    margin: 0;

    ${mq(0, bp.large)} {
      flex-wrap: wrap;
    }

    dt {
      flex-shrink: 0;
      font-weight: 700;

      &:not(:first-child) {
        margin-top: 0.5em;
      }
    }

    dd {
      margin: 0;
    }
  }

  a {
    flex-shrink: 0;
  }
`;

function LicenseInformation({ type, url, version, copyrightHolder }) {
  let text = textHelper(type);

  if (version) {
    text = `${text} ${version}`;
  }

  const icon = iconHelper(type, text);

  return (
    <StyledLicenseInformation>
      {copyrightHolder ? (
        <dl>
          <dt>{t`Copyright:`}</dt>
          <dd>{copyrightHolder}</dd>
        </dl>
      ) : null}
      <a href={url} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    </StyledLicenseInformation>
  );
}

export default LicenseInformation;

function iconHelper(type, altText) {
  switch (type) {
    case 'no-information':
    // Intentional fallthrough.
    case 'undetermined':
      return (
        <img
          src={IconLicenseCopyrightUnd}
          alt={altText}
          width="107.28"
          height="22"
        />
      );

    case 'in-copyright':
      return (
        <img
          src={IconLicenseCopyrightInc}
          alt={altText}
          width="85.83"
          height="22"
        />
      );

    case 'cc-0':
      return (
        <img src={IconLicenseByZero} alt={altText} width="88.1" height="30.8" />
      );

    case 'cc-by':
      return <img src={IconLicenseBy} alt={altText} width="88" height="31" />;

    case 'cc-by-sa':
      return (
        <img src={IconLicenseBySa} alt={altText} width="88.1" height="31" />
      );

    case 'cc-by-nc':
      return (
        <img src={IconLicenseByNc} alt={altText} width="88.57" height="31" />
      );

    case 'cc-by-nc-sa':
      return (
        <img src={IconLicenseByNcSa} alt={altText} width="88.57" height="31" />
      );

    case 'cc-by-nd':
      return (
        <img src={IconLicenseByNd} alt={altText} width="88.57" height="31" />
      );

    case 'cc-by-nc-nd':
      return (
        <img src={IconLicenseByNcNd} alt={altText} width="88.57" height="31" />
      );

    default:
      return null;
  }
}

function textHelper(type) {
  switch (type) {
    case 'no-information':
    // Intentional fallthrough.
    case 'undetermined':
      return getLocalizedValue({
        en: 'Copyright and license undetermined',
        nl: 'Copyright en licentie onbekend',
      });

    case 'in-copyright':
      return getLocalizedValue({
        en: 'In copyright',
        nl: 'Onder copyright',
      });

    case 'cc-0':
      return getLocalizedValue({
        en: 'Public domain',
        nl: 'Publiek',
      });

    case 'cc-by':
      return getLocalizedValue({
        en: 'CC BY',
        nl: 'CC BY',
      });

    case 'cc-by-sa':
      return getLocalizedValue({
        en: 'CC BY SA',
        nl: 'CC BY SA',
      });

    case 'cc-by-nc':
      return getLocalizedValue({
        en: 'CC BY NC',
        nl: 'CC BY NC',
      });

    case 'cc-by-nc-sa':
      return getLocalizedValue({
        en: 'CC BY NC SA',
        nl: 'CC BY NC SA',
      });

    case 'cc-by-nd':
      return getLocalizedValue({
        en: 'CC BY ND',
        nl: 'CC BY ND',
      });

    case 'cc-by-nc-nd':
      return getLocalizedValue({
        en: 'CC BY NC ND',
        nl: 'CC BY NC ND',
      });

    default:
      return '';
  }
}
