import styled from 'styled-components';

const TagsList = styled.div`
  margin-bottom: -1em;

  > * {
    margin-right: 1em;
    margin-bottom: 1em;
  }
`;

export default TagsList;
