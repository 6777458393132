import React, { createContext, useEffect, useState } from 'react';
const ConsentContext = createContext();

/**
 * Provides status and associated functions related to user consent.
 *
 * Currently only 'tracking' is supported as a consent category.
 */
const ConsentContextProvider = ({ children }) => {
  const [consentStatus, setConsentStatus] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem('consent', JSON.stringify(consentStatus));
  }, [consentStatus]);

  /**
   * Returns true when a consent category has an unknown status.
   *
   * @returns {boolean}
   *   TRUE when the user needs to consent or deny a category.
   */
  const hasPendingAction = () => {
    return consentStatus.tracking === null;
  };

  /**
   * Marks all categories as denied.
   */
  const denyAll = () => {
    setConsentStatus({
      tracking: false,
    });
  };

  /**
   * Marks all categories as accepted.
   */
  const acceptAll = () => {
    setConsentStatus({
      tracking: true,
    });
  };

  /**
   * Checks whether the user has given consent for a specific category.
   *
   * @param {string} category
   *   The consent category to check.
   *
   * @returns {boolean}
   *   False when denied or unknown, true when consent has been provided.
   */
  const hasConsented = (category) => {
    if (!consentStatus.hasOwnProperty(category)) {
      return false;
    }
    return consentStatus[category];
  };

  /**
   * Reset the consent status tracking to the initial state.
   */
  const resetConsent = () => {
    setConsentStatus({ tracking: null });
  };

  return (
    <ConsentContext.Provider
      value={{
        consentStatus,
        hasConsented,
        hasPendingAction,
        denyAll,
        acceptAll,
        resetConsent,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export { ConsentContext, ConsentContextProvider };

/**
 * Internal function to fetch current consent state from localstorage or default.
 *
 * @returns {tracking: null}
 */
function getInitialState() {
  const consent = localStorage.getItem('consent');
  return consent ? JSON.parse(consent) : { tracking: null };
}
