import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import { getLocalizedPath } from 'utils/localizedRoutes';

const StyledFooterMenu = styled.nav`
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

function MenuFooter() {
  return (
    <StyledFooterMenu>
      <ul>
        <li>
          <a href={getLocalizedPath('/about-rkd-research')}>
            <Trans>About RKD Research</Trans>
          </a>
        </li>
        <li>
          <a href={getLocalizedPath('/manual-rkd-research')}>
            <Trans>Manual RKD Research</Trans>
          </a>
        </li>
        <li>
          <a href={getLocalizedPath('/privacy-policy')}>
            <Trans>Privacy Policy</Trans>
          </a>
        </li>
        <li>
          <a
            href={t`https://rkd.nl/en/about-the-rkd/information/disclaimer-en`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Disclaimer</Trans>
          </a>
        </li>
        <li>
          <a
            href={t`https://rkd.nl/en/products-and-services/general-terms-of-delivery`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Terms of delivery</Trans>
          </a>
        </li>
        <li>
          <a
            href={t`https://rkd.nl/en/privacy-policy-rkdartists`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Privacy policy RKDartists</Trans>
          </a>
        </li>
        <li>
          <a
            href={t`https://www.rkd.nl/en`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Website RKD</Trans>
          </a>
        </li>
      </ul>
    </StyledFooterMenu>
  );
}

export default MenuFooter;
