import styled from 'styled-components';

const VisuallyHiddenStyling = function () {
  return `
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
  `;
};

const VisuallyHidden = styled.span`
  ${VisuallyHiddenStyling}
`;

export { VisuallyHiddenStyling, VisuallyHidden };
