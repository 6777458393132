import { useState } from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import Mls from 'components/detail/Mls';
import { IconInfo } from 'components/graphical/Icons';
import color from 'styles/base/variables/color';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import borderRadius from 'styles/base/variables/borderRadius';
import OutsideClick from 'components/helpers/OutsideClick';

const StyledPopup = styled.span`
  > button {
    width: 1.4rem;
    aspect-ratio: 1/1;
    align-self: baseline;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    margin-right: 0.5em;

    .icon {
      background-color: ${color.anthracite};
      color: ${color.white};
      width: 1.4rem;
      aspect-ratio: 1/1;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > .info-text {
    background-color: ${color.mineshaftDimmed90};
    color: ${color.white};
    border-radius: ${borderRadius.small};
    position: absolute;
    width: 22rem;
    padding: 1.2rem 1.8rem;
  }

  &:not(.open) {
    > .info-text {
      display: none;
    }
  }
`;

function Popup({ information }) {
  // Toggle information popup.
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };
  // Close on OutsideClick.
  // Note that we don't need to close on esc-key,
  // since this is part of OutsideClick.
  const itemHandleOutsideClick = () => {
    setItemIsOpen(false);
  };

  return (
    <OutsideClick onOutsideClick={itemHandleOutsideClick} elementTag="span">
      <StyledPopup className={itemIsOpen ? 'open' : ''}>
        <button
          className="btn-reset"
          onClick={itemHandleClick}
          aria-expanded={itemIsOpen ? 'true' : 'false'}
        >
          <VisuallyHidden>
            <Trans>More information</Trans>
          </VisuallyHidden>
          <IconInfo />
        </button>
        <span className="info-text font-normal font-smaller">
          <Mls strings={information} />
        </span>
      </StyledPopup>
    </OutsideClick>
  );
}

export default Popup;
