import { useContext } from 'react';
import { AdvancedSearchContext } from 'context/AdvancedSearchContext';

/**
 * Gets the user object.
 *
 * This hooks aids in DX by throwing an exception when the context cannot be used.
 *
 * @return
 *   The user context.
 */
const useAdvancedSearchContext = () => {
  const context = useContext(AdvancedSearchContext);

  if (context === undefined) {
    throw new Error(
      'useAdvancedSearchContext was used outside of the AdvancedSearchContextProvider.'
    );
  }

  return context;
};

export default useAdvancedSearchContext;
