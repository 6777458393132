import BodyText from 'components/misc/BodyText';

function TableData({ children }) {
  return (
    <td>
      <BodyText className="text--content-inline">
        {children}
      </BodyText>
    </td>
  );
}

export default TableData;
