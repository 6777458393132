import color from 'styles/base/variables/color';
import styled from 'styled-components';
import zIndex from 'styles/base/variables/zIndex';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { Trans } from '@lingui/macro';

const width = (props) => props.spinnerSize ?? '4.8rem';

const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.layoutStyle === 'full' &&
    `
      position: fixed;
      inset: 0;
      z-index: ${zIndex.modal};
  `}

  .spinner {
    width: ${width};
    aspect-ratio: 1/1;
    color: ${color.anthraciteDimmed20};
    position: relative;
    display: inline-block;
    border: 3px solid;
    border-radius: 50%;
    border-right-color: ${color.anthracite};
    animation: rotate 1s linear infinite;
  }
`;

function Loading({ spinnerSize, layoutStyle, hiddenText }) {
  return (
    <StyledLoading spinnerSize={spinnerSize} layoutStyle={layoutStyle}>
      <div className="spinner"></div>
      <VisuallyHidden>{hiddenText || <Trans>Loading...</Trans>}</VisuallyHidden>
    </StyledLoading>
  );
}

export default Loading;
