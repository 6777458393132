/**
 * Wraps children in a <dl>. Children should all be items of type Row.
 */
import styled from 'styled-components';
import respValue from 'styles/base/helpers/respValue';

const StyledRowList = styled.dl`
  margin: 0;

  > * {
    + * {
      ${respValue('margin-top', '2rem')};
    }
  }
`;

function RowList({ children }) {
  return <StyledRowList>{children}</StyledRowList>;
}

export default RowList;
