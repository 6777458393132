import styled from 'styled-components';
import border from 'styles/base/variables/border';
import slugify from 'slugify';
import { useState } from 'react';
import { IconImage, IconPointerDown } from 'components/graphical/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalizedPath } from 'utils/localizedRoutes';

const StyledArchivesTreeList = styled.ol`
  &:not(.open) {
    > li {
      > .content {
        display: none;
      }
    }
  }

  &.open {
    > li {
      > .tree-nav-item {
        > .toggler {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  > li {
    li {
      margin-left: 2.7rem;
    }
  }

  li {
    border-top: ${border.dimmed};
  }

  .tree-nav-item {
    display: flex;
    padding: 1.25rem 0;
    gap: 1.5rem;

    .toggler {
      width: 4.4rem;
      height: 4.4rem;
      margin: -1.5rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 0.15rem;
    }

    .btn-label {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;

      .icon {
        flex: 0 0 auto;
        display: flex;
      }
    }
  }
`;

function ArchiveTree({
  uri,
  label,
  identifier,
  children,
  levelId,
  open,
  hasImages,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const defaultOpenLevel = 1;
  const [itemIsOpen, setItemIsOpen] = useState(
    levelId < defaultOpenLevel ? true : open === true ?? false
  );
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  const handleDoubleClick = (to) => {
    navigate(to);
  };

  const labelButtonClassNames = [
    'btn-reset',
    'btn-label',
    uri === id ? 'font-thick' : '',
  ];

  return (
    <StyledArchivesTreeList
      id={slugify(uri)}
      className={itemIsOpen ? 'open' : ''}
    >
      <li key={uri}>
        <div className="tree-nav-item">
          {children.length > 0 && (
            <button
              className="btn-reset toggler"
              onClick={itemHandleClick}
              aria-expanded={itemIsOpen ? 'true' : 'false'}
            >
              <IconPointerDown />
            </button>
          )}
          <button
            className={labelButtonClassNames.join(' ')}
            onDoubleClick={() =>
              handleDoubleClick(
                getLocalizedPath(`/detail/${encodeURIComponent(uri)}`)
              )
            }
          >
            <span className="label">{label.value}{' '}
              <span className="identifier font-smaller">
                ({!(children.length > 0) && 'inv.nr. '}
                {identifier.value})
              </span>
            </span>
            {hasImages && <span className="icon"> <IconImage /></span>}
          </button>
        </div>
        <div className="content">{children}</div>
      </li>
    </StyledArchivesTreeList>
  );
}

export default ArchiveTree;
