import { useState } from 'react';
import TripleIfImage from 'components/misc/TripleIfImage';
import styled from 'styled-components';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import color from 'styles/base/variables/color';
import size from 'styles/base/variables/size';
import easing from 'styles/base/variables/easing';
import respValue from 'styles/base/helpers/respValue';
import {
  IconPointerFilledLeft,
  IconPointerFilledRight,
} from '../graphical/Icons';
import { getLocalizedPath } from 'utils/localizedRoutes';
import LicenseInformation from 'components/misc/LicenseInformation';
import useAnalyticsContext from 'hooks/useAnalyticsContext';
import FullClick from 'components/helpers/FullClick';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { VisuallyHidden } from 'components/helpers/VisuallyHidden';
import { Trans, t } from '@lingui/macro';
import useUserContext from 'hooks/useUserContext';
import {
  getAllowedPixelsToDisplay,
  flags,
} from 'utils/getAllowedPixelsToDisplay';
import AssetDownload from 'components/misc/AssetDownload';
import { archiveUriRegExp } from 'utils/archiveTreeUtils';

const StyledImagePager = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1rem;
`;

const StyledImageMainStrip = styled.div`
  > ul {
    > li {
      display: flex;

      &.hidden {
        display: none;
      }

      > * {
        width: 100%;

        ${mq(bp.extraLarge)} {
          min-height: ${(props) => props.activeHeight}px;
        }
      }

      a {
        &:hover {
          ~ figure {
            opacity: 0.7;
          }
        }
      }

      figure {
        height: 100%;
        display: flex;
        margin: 0;
        transition: opacity ${easing.normal};

        > img {
          margin: auto;
          max-height: ${(props) => props.activeHeight}px;
        }
      }
    }
  }
`;

const StyledImageSelectionStrip = styled.div`
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${respValue('padding', '3rem')};
    ${respValue('gap', '2rem')};

    > li {
      // Take correct own height.
      display: flex;

      &.active {
        .dimmer {
          display: none;
        }
      }

      > button {
        aspect-ratio: 1/1;
        overflow: hidden;

        // Explicitely set width, because respValue is not easy to use here.
        ${mq(0, bp.large)} {
          width: ${(props) => props.thumbnailWidth * 0.1 * 0.75}rem;
        }
        ${mq(bp.large)} {
          width: ${(props) => props.thumbnailWidth * 0.1}rem;
        }

        &:hover {
          .dimmer {
            opacity: 0.5;
          }
        }

        // To position dimmer.
        position: relative;

        .dimmer {
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          background-color: ${color.paperDimmed};
          transition: opacity ${easing.normal};
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

const StyledLicenseAndActions = styled.div`
  background-color: ${color.anthracite};
  color: ${color.white};
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${mq(0, bp.medium)} {
    padding-left: ${size.pagePadding};
    padding-right: ${size.pagePadding};
  }

  ${mq(bp.medium)} {
    ${respValue('padding-left', '5rem', '1rem')};
    padding-right: ${size.gridFullPagePadding};
  }

  .license-and-actions--inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem 4rem;

    // To position image-info.
    position: relative;

    ${mq(0, bp.gridMax)} {
      flex-wrap: wrap;
    }

    > .actions {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 2rem;
      margin-left: auto;

      ${mq(bp.gridMax)} {
        flex-shrink: 0;
        justify-content: flex-end;
      }
    }
  }
`;

/**
 *
 * Renders an array of imageUrls with an image picker strip.
 *
 * @param {Array} images
 *   An array of IIIF base urls.
 * @param int activeWidth
 *   Maximum width of the active image.
 * @param int activeHeight
 *   Maximum height of the active image.
 * @param int thumbnailWidth
 *   Maximum width of the thumbnails.
 * @param int thumbnailHeight
 *   Maximum height of the thumbnails.
 * @returns {JSX.Element|null}
 */
const DetailImageViewer = ({
  images,
  activeWidth,
  activeHeight,
  thumbnailWidth,
  thumbnailHeight,
}) => {
  const analytics = useAnalyticsContext();
  const user = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeImageIdx, setActiveImageIdx] = useState(
    searchParams.get('image') ? parseInt(searchParams.get('image')) : 0
  );
  const { id } = useParams();
  const maxImagesPerPage = 10;
  let imagePage = parseInt(searchParams.get('imagePage'));
  const [currentImagesPage, setCurrentImagesPage] = useState(
    isNaN(imagePage) ||
      imagePage < 0 ||
      imagePage >= Math.ceil(images.length / maxImagesPerPage)
      ? 0
      : imagePage
  );

  if (!images || images.length === 0) {
    return null;
  }

  const availableImageCount = images.length;
  const availableImagePages = Math.ceil(images.length / maxImagesPerPage);

  const getImagesToDisplay = (currentPage = currentImagesPage) => {
    let imagesToDisplay = images;
    const imagePageBeginRaw = currentPage * maxImagesPerPage;
    const imagePageEndRaw = imagePageBeginRaw + maxImagesPerPage;
    const imagePageStart =
      imagePageBeginRaw <= images.length
        ? imagePageBeginRaw
        : images.length - (images.length % maxImagesPerPage);
    const imagePageEnd =
      imagePageEndRaw <= availableImageCount
        ? imagePageEndRaw
        : availableImageCount;
    if (archiveUriRegExp.test(id)) {
      imagesToDisplay = images.slice(imagePageStart, imagePageEnd);
    }

    return imagesToDisplay;
  };
  const imagesToDisplay = getImagesToDisplay();

  const getActiveImageIdx = (currentImages = imagesToDisplay) => {
    if (
      !currentImages ||
      !(currentImages.length >= 0) ||
      activeImageIdx > currentImages.length - 1
    ) {
      return 0;
    }

    return activeImageIdx;
  };

  const getActiveImage = (
    preferredId = null,
    currentImages = imagesToDisplay
  ) => {
    return encodeURIComponent(
      currentImages[preferredId ?? getActiveImageIdx()].id
    );
  };

  if (
    getAllowedPixelsToDisplay(user, images[getActiveImageIdx()]) ===
    flags.ALLOW_FULL_IMAGE
  ) {
    // Track images without access limit.
    analytics.addTrack('trackInvestigation', {
      dbname: 'RKDimages',
      im: {
        id: images[getActiveImageIdx()].src,
      },
      actionType: 'image_view',
    });
  } else {
    // No sufficient access.
    analytics.addTrack('trackDenial', {
      deny: 'No_License',
      im: {
        id: images[getActiveImageIdx()].src,
      },
      actionType: 'image_view',
    });
  }

  const hasPreviousImagePage = () => {
    return currentImagesPage - 1 >= 0;
  };
  const handlePreviousImagesPage = () => {
    const previousPage = currentImagesPage - 1;
    if (hasPreviousImagePage()) {
      searchParams.set('imagePage', previousPage.toString());
      searchParams.set(
        'imageId',
        getActiveImage(0, getImagesToDisplay(previousPage))
      );
      setSearchParams(searchParams);
      setCurrentImagesPage(previousPage);
    } else {
      searchParams.set('imagePage', '0');
      searchParams.set('imageId', getActiveImage(0));
      setCurrentImagesPage(0);
    }
    searchParams.set('image', '0');
    setSearchParams(searchParams);
  };
  const hasNextImagePage = () => {
    return currentImagesPage + 1 < availableImagePages;
  };
  const handleNextImagesPage = () => {
    const nextPage = currentImagesPage + 1;
    if (hasNextImagePage()) {
      searchParams.set('imagePage', nextPage.toString());
      searchParams.set('image', '0');
      searchParams.set(
        'imageId',
        getActiveImage(0, getImagesToDisplay(nextPage))
      );
      setSearchParams(searchParams);
      setActiveImageIdx(0);
      setCurrentImagesPage(nextPage);
    }
  };
  const imagePageCounter = () => {
    return `${currentImagesPage + 1} / ${availableImagePages}`;
  };

  const setActiveImage = (imageId) => {
    setActiveImageIdx(imageId);
    searchParams.set('image', imageId);
    searchParams.set('imageId', getActiveImage(imageId));
    setSearchParams(searchParams);
  };

  const printing_allowed = () => {
    const printable_accessStatuses = ['NOLIM', 'SUBONLY'];

    return printable_accessStatuses.includes(
      images[getActiveImageIdx()].accessStatus
    );
  };

  return (
    <StyledImageMainStrip activeWidth={activeWidth} activeHeight={activeHeight}>
      {imagesToDisplay.length && (
        <ul className={`main-strip ${printing_allowed() ? '' : 'no-print'}`}>
          {imagesToDisplay.map((image, idx) => {
            return (
              <li
                key={idx}
                className={`${idx === getActiveImageIdx() ? '' : 'hidden'}`}
              >
                <FullClick>
                  {getAllowedPixelsToDisplay(user, image) ===
                    flags.ALLOW_FULL_IMAGE && (
                    <Link
                      to={getLocalizedPath(
                        `/full-iiif-image/${encodeURIComponent(image.src)}`
                      )}
                      target={'_blank'}
                      className="full-click__trigger"
                    >
                      <VisuallyHidden>
                        <Trans>Open full size image.</Trans>
                      </VisuallyHidden>
                    </Link>
                  )}
                  <figure>
                    <TripleIfImage
                      baseUrl={image.src}
                      alt={''}
                      width={activeWidth}
                      height={activeHeight}
                    />
                  </figure>
                </FullClick>
              </li>
            );
          })}
        </ul>
      )}

      {imagesToDisplay.length > (archiveUriRegExp.test(id) ? 0 : 1) && (
        <ImageSelectionStrip
          images={imagesToDisplay}
          thumbnailWidth={thumbnailWidth}
          thumbnailHeight={thumbnailHeight}
          activeImage={getActiveImageIdx()}
          setActiveImage={setActiveImage}
        />
      )}

      {archiveUriRegExp.test(id) && (
        <StyledImagePager>
          <button
            disabled={!hasPreviousImagePage()}
            onClick={() => handlePreviousImagesPage()}
            className="btn-reset"
          >
            <IconPointerFilledLeft />
            <VisuallyHidden>{t`Previous`}</VisuallyHidden>
          </button>
          {imagePageCounter()}
          <button
            disabled={!hasNextImagePage()}
            onClick={() => handleNextImagesPage()}
            className="btn-reset"
          >
            <IconPointerFilledRight />
            <VisuallyHidden>{t`Next`}</VisuallyHidden>
          </button>
        </StyledImagePager>
      )}

      {imagesToDisplay.length && (
        <LicenseAndActions
          images={imagesToDisplay}
          activeImage={getActiveImageIdx()}
        />
      )}
    </StyledImageMainStrip>
  );
};

export default DetailImageViewer;

function ImageSelectionStrip({
  images,
  thumbnailWidth,
  thumbnailHeight,
  activeImage,
  setActiveImage,
}) {
  const handleClick = (e) => {
    setActiveImage(parseInt(e.currentTarget.getAttribute('data-index')));
  };

  return (
    <StyledImageSelectionStrip
      thumbnailWidth={thumbnailWidth}
      thumbnailHeight={thumbnailHeight}
    >
      {images.length && (
        <ul className="selection-strip">
          {images.map((image, idx) => {
            return (
              <li
                key={idx}
                className={`${idx === activeImage ? 'active' : ''}`}
              >
                <button
                  data-index={idx}
                  className={`btn-reset`}
                  onClick={handleClick}
                >
                  <span className="dimmer"></span>
                  <TripleIfImage
                    baseUrl={image.src}
                    width={thumbnailWidth}
                    height={thumbnailHeight}
                    alt={''}
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </StyledImageSelectionStrip>
  );
}

function LicenseAndActions({ images, activeImage }) {
  return (
    <StyledLicenseAndActions>
      {images.length && (
        <div className="license-and-actions--inner">
          <div className="license">
            <ul>
              {images.map((image, idx) => {
                return (
                  <li
                    key={idx}
                    className={`${idx === activeImage ? '' : 'hidden'}`}
                  >
                    {image?.license ? (
                      <LicenseInformation {...image.license} />
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="actions">
            <div className="download">
              <ul>
                {images.map((image, idx) => {
                  return (
                    <li
                      key={`download-${idx}`}
                      className={`${idx === activeImage ? '' : 'hidden'}`}
                    >
                      <AssetDownload asset={image} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </StyledLicenseAndActions>
  );
}
