import GridContainer from 'components/layout/GridContainer';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import useUserContext from 'hooks/useUserContext';
import Loading from 'components/misc/Loading';
import useAnalyticsContext from 'hooks/useAnalyticsContext';

function FullTripleIfImage() {
  const { src } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const maxFetchCount = 20;
  const user = useUserContext();
  const analytics = useAnalyticsContext();

  useEffect(() => {
    let hasFullImage = false;
    const fetchData = async (resolve, reject) => {
      if (fetchCount >= maxFetchCount) {
        reject();
        return;
      }
      try {
        const headers = new Headers();
        if (user.id_token) {
          headers.append('Authorization', `Bearer ${user.id_token}`);
        }
        const fullImageRequestUrl = `${src}/full/max/0/default.jpg`;
        const result = await fetch(fullImageRequestUrl, {
          mode: 'cors',
          headers,
        });
        // The response does not always serve the max size image (the first time), even if it does exist.
        // Trigger, with a limit of maxFetchCount, until the response contains the requested max image url.
        if (
          result.url === fullImageRequestUrl ||
          user.authenticated === false
        ) {
          hasFullImage = true;
          return await result.blob();
        } else {
          setFetchCount(fetchCount + 1);
        }
      } catch (error) {
        reject();
      }
    };
    fetchData()
      .then((r) => {
        if (hasFullImage) {
          window.open(URL.createObjectURL(r), '_self');
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
    // Update flag to prevent overwrite.
    return () => {
      hasFullImage = false;
    };
    // Ignore user.id_token as it changes every heartbeat.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, user.authenticated, fetchCount]);

  // Check user role.
  let isAuthorized = false;
  if (
    user.roles.indexOf('ROLE_SUBSCRIBER') > -1 ||
    user.roles.indexOf('ROLE_EMPLOYEE') > -1
  ) {
    isAuthorized = true;
  }

  if (isAuthorized) {
    // Track authorized full image request.
    analytics.addTrack('trackRequest', {
      im: {
        id: src,
      },
      actionType: 'full_image_view',
    });
  } else if (!isAuthorized) {
    // No sufficient access.
    analytics.addTrack('trackDenial', {
      deny: 'No_License',
      im: {
        id: src,
      },
      actionType: 'full_image_view',
    });
  }

  return (
    <>
      <Helmet>
        <title>{t`Loading full image size of ${src}`}</title>
      </Helmet>
      <GridContainer>
        {isError && (
          <p>{t`Could not load the full image size of image with source ${src}`}</p>
        )}
        {isLoading && (
          <>
            <p>
              <Trans>Loading full IIIF image...</Trans>
            </p>
            <Loading layoutStyle="full" />
          </>
        )}
      </GridContainer>
    </>
  );
}

export default FullTripleIfImage;
