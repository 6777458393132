import React, { createContext, useState } from 'react';

const SnackBarContext = createContext();

const SnackBarContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState({});
  const [itemCount, setItemCount] = useState(0);
  const [selectedItemCount, setSelectedItemCount] = useState(0);

  /**
   * Function to add an item to the snackbar and update the counters.
   *
   * @param { id, card }
   *   Array containing required item data.
   */
  const addItem = ({ id, card }) => {
    // Add the item
    const item = {
      id: id,
      card: card,
      isSelected: true,
    };
    setItems((prevState) => ({ ...prevState, [id]: item }));

    // Update counters
    setItemCount(itemCount + 1);
    setSelectedItemCount(selectedItemCount + 1);
  };

  /**
   * Function to remove an item from the snackbar and update all counters.
   *
   * @param id
   *   The item id.
   */
  const removeItem = (id) => {
    if (items[id].isSelected) {
      // Only items marked as selected should decrease the
      // selected item count on removal.
      setSelectedItemCount(selectedItemCount - 1);
    }

    const itemsLeft = { ...items };
    delete itemsLeft[id];
    setItems(itemsLeft);

    setItemCount(Object.keys(itemsLeft).length);
  };

  /**
   * Function to add or remove an item to or from the snackbar
   * using the same e.g. button.
   *
   * @param item
   */
  const toggleItem = (item) => {
    if (items.hasOwnProperty(item.id)) {
      // Item exists and will be removed from the snackbar.
      removeItem(item.id);
    } else {
      // Item does not exist and will be added to the snackBar.
      // Also prevents adding the same item multiple times.
      addItem(item);
    }
  };

  /**
   * Function to mark or unmark an item as selected.
   *
   * @param id
   *   The item id.
   */
  const toggleSelectItem = (id) => {
    const isSelected = !items[id].isSelected;

    if (isSelected) {
      setSelectedItemCount(selectedItemCount + 1);
    } else {
      setSelectedItemCount(selectedItemCount - 1);
    }

    items[id].isSelected = isSelected;
    setItems(items);
  };

  /**
   * Function to select or deselect all snackbar items.
   *
   * @param isSelected
   *   The selected state (true or false) to which all items should be marked.
   */
  const setSelectAll = (isSelected) => {
    Object.keys(items).map((id) => (items[id].isSelected = isSelected));

    if (isSelected) {
      setSelectedItemCount(Object.keys(items).length);
    } else {
      setSelectedItemCount(0);
    }

    setItems(items);
  };

  /**
   * Function to remove ALL items from the snackbar.
   * Note: This action is permanent and the items cannot be restored.
   */
  const clearItems = () => {
    setItems({});
    setItemCount(0);
    setSelectedItemCount(0);
  };

  /**
   * Function to remove ALL SELECTED items from the snackbar.
   * Note: This action is permanent and the items cannot be restored.
   */
  const clearSelectedItems = () => {
    const itemsLeft = getItemsBySelectionState(false);
    setItems(itemsLeft);
    setItemCount(Object.keys(itemsLeft).length);
    setSelectedItemCount(0);
  };

  /**
   * Function to get object of items based on their selection state.
   *
   * @param state
   *   True for selected, false for not selected.
   * @returns {{}}
   */
  const getItemsBySelectionState = (state) => {
    let selectedItems = {};
    for (const [key, value] of Object.entries(items)) {
      if (value.isSelected === state) {
        selectedItems[key] = value;
      }
    }
    return selectedItems;
  };

  /**
   * Function to get the selected state of an item.
   *
   * @param id
   *   The item id.
   * @returns {boolean}
   *   True if item is marked as selected, false if not.
   */
  const isSelectedSnackBarItem = (id) => {
    return items[id].isSelected;
  };

  /**
   * Function to check if the item in the snackbar exists.
   *
   * @param id
   *   The item id.
   * @returns {boolean}
   *   True if item exists, false if not.
   */
  const hasItem = (id) => {
    return items.hasOwnProperty(id);
  };

  /**
   * Function to check if the snackbar has any item.
   *
   * @returns {boolean}
   *   True if snackbar has items, false if not.
   */
  const hasItems = () => {
    return itemCount > 0;
  };

  /**
   * Function to check if the snackbar has any item marked as selected.
   *
   * @returns {boolean}
   *   True if snackbar has selected items, false if not.
   */
  const hasSelectedItems = () => {
    return selectedItemCount > 0;
  };

  return (
    <SnackBarContext.Provider
      value={{
        isOpen,
        items,
        itemCount,
        selectedItemCount,
        setIsOpen,
        addItem,
        removeItem,
        toggleItem,
        clearItems,
        clearSelectedItems,
        hasItem,
        hasItems,
        hasSelectedItems,
        toggleSelectItem,
        setSelectAll,
        isSelectedSnackBarItem,
        getItemsBySelectionState,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};

export { SnackBarContext, SnackBarContextProvider };
