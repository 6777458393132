import Mls from 'components/detail/Mls';

function TableHeader({ text }) {
  return (
    <th>
      <Mls strings={text.strings} />
    </th>
  );
}

export default TableHeader;
