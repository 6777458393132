import styled from 'styled-components';
import size from 'styles/base/variables/size';
import { bp, mq } from 'styles/base/variables/mediaQueries';
import Popup from 'components/detail/Popup';
import { IconPointerDown } from 'components/graphical/Icons';
import { useState } from 'react';
import easing from 'styles/base/variables/easing';
import Mls from 'components/detail/Mls';
import { Trans } from '@lingui/macro';
import BodyText from 'components/misc/BodyText';

const StyledRow = styled.div`
  ${mq(bp.small)} {
    display: flex;
    flex-wrap: wrap;
  }

  > dt {
    ${mq(0, bp.small)} {
      margin-bottom: 0.35em;
    }

    ${mq(bp.small)} {
      flex: 0 0 auto;
      width: 30%;
    }
  }

  > dd {
    margin: 0;

    ${mq(bp.small)} {
      flex: 1 1 auto;
      width: 70%;
      padding-left: ${size.gridGutter};
    }
  }

  .more-toggler {
    padding: 0;
    float: right;
    position: relative;
    top: 0.35em;

    .icon {
      transition: transform ${easing.normal};
    }
  }

  .more-row-items {
    margin-top: 1em;
  }

  &.open {
    .more-toggler {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &:not(.open) {
    .more-row-items {
      display: none;
    }
  }
`;

function Row({ label, information, initiallyVisibleChildren, children }) {
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const itemHandleClick = () => {
    setItemIsOpen(!itemIsOpen);
  };

  let visibleChildren = children;
  let moreChildren = [];
  let hasMoreChildren = false;
  if (initiallyVisibleChildren && initiallyVisibleChildren < children.length) {
    visibleChildren = children.slice(0, initiallyVisibleChildren);
    moreChildren = children.slice(initiallyVisibleChildren);
    hasMoreChildren = true;
  }

  return (
    <StyledRow className={itemIsOpen ? 'open' : ''}>
      <dt>
        <span className="font-thick">
          <Mls strings={label} />
        </span>
        {information && <Popup information={information}></Popup>}
      </dt>
      <dd>
        <BodyText className="text--content-inline">
          {visibleChildren}
          {hasMoreChildren && (
            <>
              <button
                className="btn btn-reset more-toggler"
                onClick={itemHandleClick}
                aria-expanded={itemIsOpen ? 'true' : 'false'}
              >
                <span className="text">
                  <Trans>More</Trans>
                </span>
                <IconPointerDown />
              </button>
              <div className="more-row-items">{moreChildren}</div>
            </>
          )}
        </BodyText>
      </dd>
    </StyledRow>
  );
}

export default Row;
